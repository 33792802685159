import { requestAjax } from '@/utils/http'

/**
 * @description 党员突击队-新增/编辑
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserPartyPartyCommandoAddOrUpdate (data, options) {
  return requestAjax({
    url: '/api-user/party/partyCommando/addOrUpdate',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 添加阅示人员
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2SysApproveFlowAddReadNode (data, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/addReadNode',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 超级管理员-新增角色模板
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserRolesAdminRoleSaveOrUpdate (data, options) {
  return requestAjax({
    url: '/api-user/roles/adminRole/saveOrUpdate',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 超级管理员查看公司已选角色
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserRolesAdminRoleCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/roles/adminRole/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取JS-SDK-wx-agentConfig加密
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ThirdPartyAuthAgentConfig (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyAuth/agentConfig',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 待申请列表数量
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsercompanyApplyCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/usercompany/apply/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 申请状态（同意或拒绝）
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerApplyStateIdState (data, options) {
  const { id, state } = options.params
  return requestAjax({
    url: `/api-user/sysworker/applyState/${id}/${state}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 4_我的申请
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerApplyUserList (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/applyUserList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6.工人信息_进退场文件保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV2WorkerApproachFilesSave (data, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/approachFilesSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 工人信息_进场文件保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysWorkerArchivesApproachFilesSave (data, options) {
  return requestAjax({
    url: '/api-user/sysWorkerArchives/approachFilesSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3-4-4-1-任务详情-完成-列表-从代办进去，传入taskInfoId
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiTaskApprovalProjectTaskCompleteList (params, options) {
  return requestAjax({
    url: '/api-user/api/task/approvalProjectTaskCompleteList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3-4-3-任务详情-延期-列表-从代办进去，传入taskInfoId
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiTaskApprovalProjectTaskDelayList (params, options) {
  return requestAjax({
    url: '/api-user/api/task/approvalProjectTaskDelayList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3-4-4-1-任务详情-审批-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiTaskApproveTaskInfoSave (data, options) {
  return requestAjax({
    url: '/api-user/api/task/approveTaskInfoSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.5任务详情-审批-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysBranchTaskApproveTaskInfoSave (data, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/approveTaskInfoSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.5任务详情-审批-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysCompanyTaskApproveTaskInfoSave (data, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/approveTaskInfoSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.5任务详情-审批-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysProjectTaskApproveTaskInfoSave (data, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/approveTaskInfoSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 修改工人班组授权状态
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerAuthStateIdState (data, options) {
  const { id, state } = options.params
  return requestAjax({
    url: `/api-user/sysworker/authState/${id}/${state}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 总后台角色库-查看
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysCompanyLibraryBackstageFindById (data, options) {
  return requestAjax({
    url: '/api-user/sys/company/library/backstageFindById',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 总后台角色库-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyLibraryBackstageList (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/library/backstageList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总后台角色库-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysCompanyLibraryBackstageSaveSysCompanyLibrary (data, options) {
  return requestAjax({
    url: '/api-user/sys/company/library/backstageSaveSysCompanyLibrary',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 银行卡识别
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageBankCardOCR (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/bankCardOCR',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 银行卡识别
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerBankCardOCR (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/bankCardOCR',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4.2企业后台-成员管理-批量删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserApiWorkbenchBatchDeleteOrgUser (params, options) {
  return requestAjax({
    url: '/api-user/api/workbench/batchDeleteOrgUser',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 3.0企业后台-成员管理-批量删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserOrganizationBatchDeleteOrgUser (params, options) {
  return requestAjax({
    url: '/api-user/organization/batchDeleteOrgUser',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 3.0企业后台-成员管理-批量停用
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserOrganizationBatchDisableOrgUser (data, options) {
  return requestAjax({
    url: '/api-user/organization/batchDisableOrgUser',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 4.2企业后台-成员管理-批量启用、停用
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkbenchBatchEnableOrgUserAndDisableOrgUser (data, options) {
  return requestAjax({
    url: '/api-user/api/workbench/batchEnableOrgUserAndDisableOrgUser',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3.0企业后台-成员管理-批量启用
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserOrganizationBatchEnableOrgUser (data, options) {
  return requestAjax({
    url: '/api-user/organization/batchEnableOrgUser',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 批量进场
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ManageBatchInTime (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/batchInTime',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 批量进场
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1WorkerBatchInTime (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/worker/batchInTime',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6_1项目部-批量进场
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerBatchInTime (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/batchInTime',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 云筑批量工人进场
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserYzwBatchInTime (data, options) {
  return requestAjax({
    url: '/api-user/yzw/batchInTime',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 批量退场
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ManageBatchOutTime (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/batchOutTime',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2_4风险预警-批量退场
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkerBatchOutTime (data, options) {
  return requestAjax({
    url: '/api-user/api/worker/batchOutTime',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 批量退场
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1WorkerBatchOutTime (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/worker/batchOutTime',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6_1项目部-批量退场
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerBatchOutTime (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/batchOutTime',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 云筑创建工人退场
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserYzwBatchOutTime (data, options) {
  return requestAjax({
    url: '/api-user/yzw/batchOutTime',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 批量休息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ManageBatchRest (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/batchRest',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 批量保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyApiDetailsBatchSave (data, options) {
  return requestAjax({
    url: '/api-user/company/api/details/batchSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2_2_4-新成员-02同意03拒绝-批量同意或拒绝）
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiManageBatchapplyStateState (data, options) {
  const { state } = options.params
  return requestAjax({
    url: `/api-user/api/manage/batchapplyState/${state}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2_1_2工人班组待审核批量同意或拒绝/工人-在场工人列表-批量同意或拒绝）
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkerBatchapplyStateState (data, options) {
  const { state } = options.params
  return requestAjax({
    url: `/api-user/api/worker/batchapplyState/${state}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.12批量移动员工项目角色-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrganizationBathSaveProjectRole (data, options) {
  return requestAjax({
    url: '/api-user/api/organization/bathSaveProjectRole',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.11批量移动员工部门-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrganizationBathSaveUserDept (data, options) {
  return requestAjax({
    url: '/api-user/api/organization/bathSaveUserDept',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6.3批量移动员工部门-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationBathSaveUserDept (data, options) {
  return requestAjax({
    url: '/api-user/v2/organization/bathSaveUserDept',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.12批量移动员工公司角色-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrganizationBathSaveUserProjectRole (data, options) {
  return requestAjax({
    url: '/api-user/api/organization/bathSaveUserProjectRole',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3_4_2分公司考勤-1日,2周，3月
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageBranchCompanyAttendanceCompanyIdBranchId (params, options) {
  const { companyId, branchId } = params
  return requestAjax({
    url: `/api-user/api/manage/branchCompanyAttendance/${companyId}/${branchId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description A_2_1_2_质量惩罚单-分公司质量惩罚单数据
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiQualityBranchCompanyInspectionPunish (params, options) {
  return requestAjax({
    url: '/api-user/api/quality/branchCompanyInspectionPunish',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description A_2_1_2_质量惩罚单-分公司质量惩罚单数据
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1QualityBranchCompanyInspectionPunish (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/quality/branchCompanyInspectionPunish',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description A_2_1_1_质量巡检-分公司质量巡检数据
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiQualityBranchCompanyInspection (params, options) {
  return requestAjax({
    url: '/api-user/api/quality/branchCompanyInspection',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description A_2_1_1_质量巡检-分公司质量巡检数据
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1QualityBranchCompanyInspection (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/quality/branchCompanyInspection',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 登陆后根据project_type =3调用该接口返回-分公司列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageBranchCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/api/manage/branch/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 内部调用-根据companyId来获取分公司,根据userId来获取当前用户所在分公司
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationBranchCompanyList (params, options) {
  return requestAjax({
    url: '/api-user/organization/branchCompanyList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 0.3总部 - 分公司列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyTaskBranchCompanyList (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/branchCompanyList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1-公司切换-分公司列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiHomePageBranchCompany (params, options) {
  return requestAjax({
    url: '/api-user/api/home/page/branchCompany',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1-公司切换-分公司列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1HomePageBranchCompany (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/home/page/branchCompany',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_总部_选择弹框-分公司列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageBranchCompanyCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/api/manage/branchCompany/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 党员突击队-分公司汇总列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserPartyPartyCommandoBranchPartyCommandoList (params, options) {
  return requestAjax({
    url: '/api-user/party/partyCommando/branchPartyCommandoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询项目列表-直接查分公司下面的项目
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectBranchProjectlist (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/branchProjectlist',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 组织架构-选择人员-弹框-选择人员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationBranchUserList (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/branchUserList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 组织架构-选择人员-弹框-选择人员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationBranchUserList (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/branchUserList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 分公司-安全周检查-查看汇总
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1SecurityBranchWeekInspectionSummary (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/branchWeekInspectionSummary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据companyId来获取分公司
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationBranchcompanyCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/organization/branchcompany/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_1分公司_列表数据，类别筛选，状态筛选 ，考勤率筛选
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageBranchofficeProjectCompanyIdBranchId (params, options) {
  const { branchId, companyId } = params
  return requestAjax({
    url: `/api-user/api/manage/branchofficeProject/${companyId}/${branchId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_1分公司_(8/21新增) 列表数据，类别筛选，状态筛选 ，考勤率筛选
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerBranchofficeProjectCompanyIdBranchId (params, options) {
  const { branchId, companyId } = params
  return requestAjax({
    url: `/api-user/api/worker/branchofficeProject/${companyId}/${branchId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_1分公司_头部
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageBranchofficeCompanyIdBranchId (params, options) {
  const { branchId, companyId } = params
  return requestAjax({
    url: `/api-user/api/manage/branchoffice/${companyId}/${branchId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_1分公司_头部
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerBranchofficeCompanyIdBranchId (params, options) {
  const { branchId, companyId } = params
  return requestAjax({
    url: `/api-user/api/worker/branchoffice/${companyId}/${branchId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 日历-标记
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyProjectDiaryCalendarList (params, options) {
  return requestAjax({
    url: '/api-user/company/project/diary/calendarList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 订单回调
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysOrderCallback (data, options) {
  return requestAjax({
    url: '/api-user/sys/order/callback',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 修改审批人
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2SysApproveFlowChangeApproveUser (data, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/changeApproveUser',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 更换版本
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ThirdPartyUserChangeCompanyEditionPost (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyUser/changeCompanyEditionPost',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 获取可购买渠道
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsChannelslist (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/channels_list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取可购买渠道
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsTestChannelslist (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/test/channels_list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询订单是否支付成功
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrderCheckOrderId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/api/order/checkOrder/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询订单是否支付成功
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysOrderCheckOrderId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/order/checkOrder/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询用户是否拥有某个角色
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserRolesCheckUserRoleExistByRoleName (params, options) {
  return requestAjax({
    url: '/api-user/roles/checkUserRoleExistByRoleName',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 机构层级-菜单管理-高级设置-下属机构列表(0机构，-1项目)
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationCompanyChildOrganizationList (params, options) {
  return requestAjax({
    url: '/api-user/organization/company/childOrganizationList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 下订单-版本增购名额
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrderComboNumberOrder (params, options) {
  return requestAjax({
    url: '/api-user/api/order/comboNumberOrder',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 下订单-版本增购名额
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysOrderComboNumberOrder (params, options) {
  return requestAjax({
    url: '/api-user/sys/order/comboNumberOrder',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 下订单-版本续费V2
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrderComboRenewalOrderV2 (params, options) {
  return requestAjax({
    url: '/api-user/api/order/comboRenewalOrderV2',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 下订单-版本续费V2
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysOrderComboRenewalOrderV2 (params, options) {
  return requestAjax({
    url: '/api-user/sys/order/comboRenewalOrderV2',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 下订单-版本升级
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrderComboUpgradeOrder (params, options) {
  return requestAjax({
    url: '/api-user/api/order/comboUpgradeOrder',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 下订单-版本升级
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysOrderComboUpgradeOrder (params, options) {
  return requestAjax({
    url: '/api-user/sys/order/comboUpgradeOrder',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3_4_1总部考勤统计-1日,2周，3月
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageCompanyAttendanceCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/api/manage/companyAttendance/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总公司-5-1施工安全日志
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityCompanyBranchDiary (params, options) {
  return requestAjax({
    url: '/api-user/api/security/companyBranchDiary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总公司-5-1施工安全日志
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1SecurityCompanyBranchDiary (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/companyBranchDiary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总公司-3-1班前教育记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityCompanyBranchEducation (params, options) {
  return requestAjax({
    url: '/api-user/api/security/companyBranchEducation',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总公司-3-1班前教育记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1SecurityCompanyBranchEducation (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/companyBranchEducation',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总公司-2-1违章档案
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityCompanyBranchInspection (params, options) {
  return requestAjax({
    url: '/api-user/api/security/companyBranchInspection',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总公司-2-1违章档案
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1SecurityCompanyBranchInspection (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/companyBranchInspection',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总公司-6-1安全惩罚单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityCompanyBranchPunishBill (params, options) {
  return requestAjax({
    url: '/api-user/api/security/companyBranchPunishBill',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总公司-6-1安全惩罚单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1SecurityCompanyBranchPunishBill (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/companyBranchPunishBill',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取机构角色列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationCompanyBranchRolesList (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/companyBranchRolesList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取机构角色列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationCompanyBranchRolesList (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/companyBranchRolesList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.5设置部门(项目)角色-3.1公司(项目)角色
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationCompanyBranchRoles (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/companyBranchRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.0公司系统设置-公司职位权限列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationCompanyBranchRoles (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/companyBranchRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.1角色权限管理-公司角色列表*20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationCompanyBranchRoles (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/companyBranchRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总公司-4-2周检查问题
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityCompanyBranchWeekInspection (params, options) {
  return requestAjax({
    url: '/api-user/api/security/companyBranchWeekInspection',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总公司-4-2周检查问题
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1SecurityCompanyBranchWeekInspection (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/companyBranchWeekInspection',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总公司-4-1周检查记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityCompanyBranchWeek (params, options) {
  return requestAjax({
    url: '/api-user/api/security/companyBranchWeek',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总公司-4-1周检查记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1SecurityCompanyBranchWeek (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/companyBranchWeek',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总后台-查询统计公司列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyCompanyCountList (params, options) {
  return requestAjax({
    url: '/api-user/company/companyCountList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总后台-查询统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyCompanyCount (params, options) {
  return requestAjax({
    url: '/api-user/company/companyCount',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司-首页-头部
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiHomePageCompanyInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/home/page/companyInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司-首页-头部
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1HomePageCompanyInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/home/page/companyInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 安全隐患-查看汇总-数据统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1SecurityCompanyInspectionSummary (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/companyInspectionSummary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.2安全隐患-查看汇总-数据统计
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1SecurityProjectCompanyInspectionSummary (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/project/companyInspectionSummary',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 12.3机构层级设置-菜单模板-角色库列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyLibraryCompanyList (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/library/companyList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 每天定时生成昨天统计公司平台统计数据
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserJobCompanyOperateData (params, options) {
  return requestAjax({
    url: '/api-user/job/companyOperateData',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 党员突击队-总部汇总列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserPartyPartyCommandoCompanyPartyCommandoList (params, options) {
  return requestAjax({
    url: '/api-user/party/partyCommando/companyPartyCommandoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 分公司-5-班施工安全日志
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityCompanyPrjoectDiaryDto (params, options) {
  return requestAjax({
    url: '/api-user/api/security/companyPrjoectDiaryDto',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 分公司-5-班施工安全日志
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1SecurityCompanyPrjoectDiaryDto (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/companyPrjoectDiaryDto',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.2施工安全日志-查看汇总-列表
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1SecurityProjectCompanyPrjoectDiaryDto (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/project/companyPrjoectDiaryDto',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 查询项目、下面的安全员-分页
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectCompanyPrjoectDiary (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/companyPrjoectDiary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 分公司-5-安全惩罚单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityCompanyPrjoectPunishBill (params, options) {
  return requestAjax({
    url: '/api-user/api/security/companyPrjoectPunishBill',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 分公司-5-安全惩罚单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1SecurityCompanyPrjoectPunishBill (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/companyPrjoectPunishBill',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.1安全惩罚单-查看汇总-列表
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1SecurityProjectCompanyPrjoectPunishBill (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/project/companyPrjoectPunishBill',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 查询项目、下面的安全员-分页
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectCompanyProjectDiaryList (data, options) {
  return requestAjax({
    url: '/api-user/companyproject/companyProjectDiaryList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 查询项目、下面的安全员-分页
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectCompanyProjectDiaryV1List (data, options) {
  return requestAjax({
    url: '/api-user/companyproject/companyProjectDiaryV1List',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 分公司-4-班前教育记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityCompanyProjectEducation (params, options) {
  return requestAjax({
    url: '/api-user/api/security/companyProjectEducation',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 分公司-4-班前教育记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1SecurityCompanyProjectEducation (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/companyProjectEducation',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4.2班前教育记录-查看汇总-列表
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1SecurityProjectCompanyProjectEducation (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/project/companyProjectEducation',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 公司-首页-项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiHomePageCompanyProjectInfoList (params, options) {
  return requestAjax({
    url: '/api-user/api/home/page/companyProjectInfoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 0.2项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysBranchTaskCompanyProjectInfoList (params, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/companyProjectInfoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 0.2项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyTaskCompanyProjectInfoList (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/companyProjectInfoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 0.2项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskCompanyProjectInfoList (params, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/companyProjectInfoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description B_2_1_2_质量惩罚单-项目质量惩罚单数据-汇总统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1QualityCompanyProjectInspectionPunishSummary (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/quality/companyProjectInspectionPunishSummary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 安全惩罚单-查看汇总-汇总统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1SecurityCompanyProjectInspectionPunishSummary (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/companyProjectInspectionPunishSummary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.1质量惩罚单-按项目汇总-汇总
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1QualityProjectCompanyProjectInspectionPunishSummary (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/quality/project/companyProjectInspectionPunishSummary',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2.1安全惩罚单-查看汇总-汇总统计
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1SecurityProjectCompanyProjectInspectionPunishSummary (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/project/companyProjectInspectionPunishSummary',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description B_2_1_2_质量惩罚单-项目质量惩罚单数据
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiQualityCompanyProjectInspectionPunish (params, options) {
  return requestAjax({
    url: '/api-user/api/quality/companyProjectInspectionPunish',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description B_2_1_2_质量惩罚单-项目质量惩罚单数据
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1QualityCompanyProjectInspectionPunish (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/quality/companyProjectInspectionPunish',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.1质量惩罚单-按项目汇总-列表
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1QualityProjectCompanyProjectInspectionPunish (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/quality/project/companyProjectInspectionPunish',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description B_2_1_1_质量巡检-项目质量巡检数据-汇总统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1QualityCompanyProjectInspectionSummary (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/quality/companyProjectInspectionSummary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1质量隐患-按项目汇总-汇总统计
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1QualityProjectCompanyProjectInspectionSummary (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/quality/project/companyProjectInspectionSummary',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description B_2_1_1_质量巡检-项目质量巡检数据
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiQualityCompanyProjectInspection (params, options) {
  return requestAjax({
    url: '/api-user/api/quality/companyProjectInspection',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description B_2_1_1_质量巡检-项目质量巡检数据
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1QualityCompanyProjectInspection (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/quality/companyProjectInspection',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 分公司-3-违章档案
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityCompanyProjectInspection (params, options) {
  return requestAjax({
    url: '/api-user/api/security/companyProjectInspection',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 分公司-3-违章档案
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1SecurityCompanyProjectInspection (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/companyProjectInspection',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1质量隐患-按项目汇总-列表
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1QualityProjectCompanyProjectInspection (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/quality/project/companyProjectInspection',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.2安全隐患-查看汇总-列表
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1SecurityProjectCompanyProjectInspection (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/project/companyProjectInspection',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1-2公司切换-项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiHomePageCompanyProjectList (params, options) {
  return requestAjax({
    url: '/api-user/api/home/page/companyProjectList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1-2公司切换-项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1HomePageCompanyProjectList (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/home/page/companyProjectList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司-劳务看板-在建项目-点击列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageCompanyProjectList (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/companyProjectList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4.1项目成员-项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationCompanyProjectList (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/companyProjectList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4.0公司系统设置-项目人员配置-项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationCompanyProjectList (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/companyProjectList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.1项目人员配置-项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationCompanyProjectList (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/companyProjectList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 设置使用项目-项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageCompanyProjectListV2 (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/companyProjectListV2',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取项目角色列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationCompanyProjectRolesList (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/companyProjectRolesList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取项目角色列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationCompanyProjectRolesList (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/companyProjectRolesList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.0公司系统设置-项目职位权限列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationCompanyProjectRoles (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/companyProjectRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.1角色权限管理(3.3添加人员选择项目角色)-项目角色列表*20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationCompanyProjectRoles (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/companyProjectRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目管理员查询项目职位角色
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserRolesCompanyProjectRolesCompanyIdBranchIdProjectId (params, options) {
  const { branchId, companyId, projectId } = params
  return requestAjax({
    url: `/api-user/roles/companyProjectRoles/${companyId}/${branchId}/${projectId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1-5-创建项目-项目保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiSecurityCompanyProjectSave (data, options) {
  return requestAjax({
    url: '/api-user/api/security/companyProjectSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 公司-劳务看板-在建项目-统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageCompanyProjectStatistics (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/companyProjectStatistics',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目列表-统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ProjectCompanyProjectStatistics (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/project/companyProjectStatistics',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4.0公司系统设置-项目人员配置-项目列表-数据统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationCompanyProjectStatistics (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/companyProjectStatistics',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1_1项目管理人员
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectCompanyProjectUserProjectId (params, options) {
  const { projectId } = params
  return requestAjax({
    url: `/api-user/companyproject/companyProjectUser/${projectId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 分公司-4-1周检查记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityCompanyProjectWeekInspection (params, options) {
  return requestAjax({
    url: '/api-user/api/security/companyProjectWeekInspection',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 分公司-4-1周检查记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1SecurityCompanyProjectWeekInspection (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/security/companyProjectWeekInspection',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.4公司系统设置-组织架构管理-编辑人员信息-选择部门及角色-部门角色列表(项目角色列表)
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationCompanyRoleList (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/companyRoleList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.2编辑人员信息-部门角色列表(项目角色列表)20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationCompanyRoleList (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/companyRoleList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司管理员查询公司职位角色
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserRolesCompanyRolesAllCompanyIdBranchId (params, options) {
  const { branchId, companyId } = params
  return requestAjax({
    url: `/api-user/roles/companyRolesAll/${companyId}/${branchId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 总后台-查询公司出勤明细
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyTodayCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/company/today/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1_1账号申请-管理端账号申请绑定
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiManageCompanyUserAccount (data, options) {
  return requestAjax({
    url: '/api-user/api/manage/companyUserAccount',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description V1账号申请-管理端账号申请绑定
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ManageCompanyUserAccount (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/companyUserAccount',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 层级切换-20220726新版本通过token查询用户相关信息V1
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageCompanyUserInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/companyUserInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 20220726新版本通过token查询用户相关信息V1
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsersCompanyUserInfo (params, options) {
  return requestAjax({
    url: '/api-user/users/companyUserInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1添加成员列表、1.9批量移动员工列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationCompanyUserList (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/companyUserList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司（新增/编辑）
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsCompany (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/company',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司（新增/编辑）
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsTestCompany (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/test/company',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4_消息_已处理列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerCompleteList (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/completeList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取JS-SDK-wx-config加密
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ThirdPartyAuthConfig (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyAuth/config',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通讯录单个搜索
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ThirdPartyAuthContactSearch (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyAuth/contactSearch',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 4_消息_抄送我
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerCopiedList (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/copiedList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据机构id获取下层分公司数量
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationCountBranchIdByNum (params, options) {
  return requestAjax({
    url: '/api-user/organization/countBranchIdByNum',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据corpId获取当前公司的权限
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ThirdPartyUserCountByCorpId (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyUser/countByCorpId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过branchId或者projectId或者groupId查询工种数量
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerCountTypeOfWorkBranchIdOrProjectId (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/countTypeOfWorkBranchIdOrProjectId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过groupId,settingId查询还有多少工人
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerCountWorkerGroupIdSettingId (params, options) {
  const { groupId, settingId } = params
  return requestAjax({
    url: `/api-user/sysworker/countWorker/${groupId}/${settingId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询groupId，settingid查询工人列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerFindWorkerGroupIdGroupIdSettingId (params, options) {
  const { groupId, settingId } = params
  return requestAjax({
    url: `/api-user/sysworker/findWorkerGroupId/${groupId}/${settingId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过branchId或者projectId或者groupId查询还有多少工人
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerCountWorkerNumBranchIdOrProjectIdOrGroupId (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/countWorkerNumBranchIdOrProjectIdOrGroupId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过groupId查询还有多少工人
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerCountWorkerNumGroupIdGroupId (params, options) {
  const { groupId } = params
  return requestAjax({
    url: `/api-user/sysworker/countWorkerNumGroupId/${groupId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过groupIds查询还有多少工人
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerCountWorkerNumGroupIds (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/countWorkerNumGroupIds',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过projectId,groupId查询还有多少工人
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerCountWorkerNumProjectIdAndGroupId (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/countWorkerNumProjectIdAndGroupId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过projectId,groupId查询还有多少工人
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerCountWorkerNumProjectIdAndGroupIdsList (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/countWorkerNumProjectIdAndGroupIdsList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过projectId查询还有多少工人
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerCountWorkerNumProjectId (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/countWorkerNumProjectId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过groupId查询已进场和休息的工人数量
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerCountWorkingState13WorkerNumGroupIdGroupId (params, options) {
  const { groupId } = params
  return requestAjax({
    url: `/api-user/sysworker/countWorkingState13WorkerNumGroupId/${groupId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑创建公司
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserYzwCreateCompany (params, options) {
  return requestAjax({
    url: '/api-user/yzw/createCompany',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 授权后推送企业和管理员信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiQwCreateCompany (data, options) {
  return requestAjax({
    url: '/api-user/api/qw/createCompany',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 授权后推送企业和管理员信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ThirdPartyAuthCreateCompany (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyAuth/createCompany',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通讯录服务器验证通过后推送部门和人员信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiQwCreateDeptAndUser (data, options) {
  return requestAjax({
    url: '/api-user/api/qw/createDeptAndUser',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 云筑创建项目
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserYzwCreateProjectId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/yzw/createProject/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑创建供应商与项目进行绑定
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserYzwCreateSupplierCompany (data, options) {
  return requestAjax({
    url: '/api-user/yzw/createSupplierCompany',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 云筑创建工人
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerCreateWorkWorkerId (params, options) {
  const { workerId } = params
  return requestAjax({
    url: `/api-user/sysworker/createWork/${workerId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑创建工人
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserYzwCreateWorkWorkerId (params, options) {
  const { workerId } = params
  return requestAjax({
    url: `/api-user/yzw/createWork/${workerId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6.1个性化管理公司列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationCompanyCustomOrganization (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/company/customOrganization',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 5.1个性化管理公司列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationCompanyCustomOrganization (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/company/customOrganization',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过日期获取数据统计
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOperateDayData (data, options) {
  return requestAjax({
    url: '/api-user/api/operate/dayData',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 系统初始化默认角色
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserRolesDefaultRoles (params, options) {
  return requestAjax({
    url: '/api-user/roles/defaultRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 删除加签节点
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2SysApproveFlowDeleteApproveNode (data, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/deleteApproveNode',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 7.奖捐台账删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserApiV2WorkerDeleteAwardDonate (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/deleteAwardDonate',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除groupId,settingId下的工人
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserSysworkerDeleteByGroupIdAndSettingIdGroupIdSettingId (data, options) {
  const { groupId, settingId } = options.params
  return requestAjax({
    url: `/api-user/sysworker/deleteByGroupIdAndSettingId/${groupId}/${settingId}`,
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description 删除groupId下工人
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserSysworkerDeleteByGroupIdGroupId (data, options) {
  const { groupId } = options.params
  return requestAjax({
    url: `/api-user/sysworker/deleteByGroupId/${groupId}`,
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description 6.3删除个性化公司
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserApiOrganizationDeleteById (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/deleteById',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 2.3编辑人员-删除人员
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserApiV1RoleUserDeleteById (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/role/user/deleteById',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserApprovejobId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/approvejob/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 总后台角色库--删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserSysCompanyLibraryBackstageDeleteById (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/library/backstageDeleteById',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 5.2新增自定义公司-删除20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserV2OrganizationDeleteById (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/deleteById',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 党员突击队-删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserPartyPartyCommandoDeleteById (params, options) {
  return requestAjax({
    url: '/api-user/party/partyCommando/deleteById',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除审批关联业务当中除当前用户外的其他人的审批待办
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserApprovejobDeleteBySubjectIdNonThisUser (params, options) {
  return requestAjax({
    url: '/api-user/approvejob/deleteBySubjectIdNonThisUser',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 通过关联业务id删除审批
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserApprovejobDeleteBySubjectId (params, options) {
  return requestAjax({
    url: '/api-user/approvejob/deleteBySubjectId',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 3.2公司系统设置-组织架构管理-移出成员
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserApiOrganizationDeleteCompanyUserCompanyIdOrgIdUserId (params, options) {
  const { companyId, orgId, userId } = params
  return requestAjax({
    url: `/api-user/api/organization/deleteCompanyUser/${companyId}/${orgId}/${userId}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 1.5公司系统设置-组织架构管理-移出成员删除用户
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserV1OrganizationDeleteCompanyUserCompanyIdOrgIdUserId (params, options) {
  const { companyId, orgId, userId } = params
  return requestAjax({
    url: `/api-user/v1/organization/deleteCompanyUser/${companyId}/${orgId}/${userId}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 3.2公司系统设置-组织架构管理-移出成员
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserV2OrganizationDeleteCompanyUserCompanyIdOrgIdUserId (params, options) {
  const { companyId, orgId, userId } = params
  return requestAjax({
    url: `/api-user/v2/organization/deleteCompanyUser/${companyId}/${orgId}/${userId}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 1.5公司部门-移出成员删除用户
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserV1OrganizationDeleteDeptUserCompanyIdOrgIdUserId (params, options) {
  const { companyId, orgId, userId } = params
  return requestAjax({
    url: `/api-user/v1/organization/deleteDeptUser/${companyId}/${orgId}/${userId}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 1.1组织架构-移除本部门
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserV2OrganizationDeleteDeptUserCompanyIdOrgIdUserId (params, options) {
  const { companyId, orgId, userId } = params
  return requestAjax({
    url: `/api-user/v2/organization/deleteDeptUser/${companyId}/${orgId}/${userId}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 4.4项目人员管理-删除成员
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserApiOrganizationDeleteProjectUser (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/deleteProjectUser',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 3.1项目人员配置-移除本项目
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserV2OrganizationDeleteProjectUser (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/deleteProjectUser',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除阅示节点
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2SysApproveFlowDeleteReadNode (data, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/deleteReadNode',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 8.有关证书通过ID删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserApiV2WorkerDeleteRelatedCertificate (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/deleteRelatedCertificate',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 1.3我发布的任务-列表-删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysBranchTaskDeleteMyReleaseTaskByTaskId (params, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/deleteMyReleaseTaskByTaskId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.3我发布的任务-列表-删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyTaskDeleteMyReleaseTaskByTaskId (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/deleteMyReleaseTaskByTaskId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.3我发布的任务-列表-删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskDeleteMyReleaseTaskByTaskId (params, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/deleteMyReleaseTaskByTaskId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.1角色权限管理-删除角色
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserApiOrganizationDeleteRoleById (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/deleteRoleById',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 2.1角色权限管理-删除角色
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserV2OrganizationDeleteRoleById (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/deleteRoleById',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 机构层级-菜单管理-删除菜单模板
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserRolesDeleteRoleTemplate (params, options) {
  return requestAjax({
    url: '/api-user/roles/deleteRoleTemplate',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 后台管理删除角色
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserRolesId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/roles/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 4.0机构层级-角色管理-成员管理-删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserRolesDeleteRolesByUser (params, options) {
  return requestAjax({
    url: '/api-user/roles/deleteRolesByUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.3编辑部门-删除部门
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserApiOrganizationDeleteCompanyIdId (params, options) {
  const { companyId, id } = params
  return requestAjax({
    url: `/api-user/api/organization/delete/${companyId}/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserCompanyApiDetailsId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/company/api/details/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 人员管理-人员列表-机构删除绑定用户
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserOrganizationDeleteCompanyIdOrgIdUserId (params, options) {
  const { companyId, orgId, userId } = params
  return requestAjax({
    url: `/api-user/organization/delete/${companyId}/${orgId}/${userId}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 6.1公司系统设置-组织架构管理-功能菜单-删除组织架构
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserV1OrganizationDeleteBranchIdId (params, options) {
  const { branchId, id } = params
  return requestAjax({
    url: `/api-user/v1/organization/delete/${branchId}/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 1.1组织架构-删除组织架构
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserV2OrganizationDeleteCompanyIdId (params, options) {
  const { companyId, id } = params
  return requestAjax({
    url: `/api-user/v2/organization/delete/${companyId}/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserSysPermissionId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/permission/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除直接下架
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserSysProductId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/product/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserSysProjectTaskFileId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/project/task/file/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserSysProjectTaskInfoId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/project/task/info/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 公司删除绑定用户
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserUsercompanyDeleteCompanyIdUserId (params, options) {
  const { companyId, userId } = params
  return requestAjax({
    url: `/api-user/usercompany/delete/${companyId}/${userId}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserUserorgId (params, options) {
  const { userId } = params
  return requestAjax({
    url: `/api-user/userorg/${userId}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserWechatId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/wechat/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserSyscompanycomborenewalId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/syscompanycomborenewal/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除进场或退场文件
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserSysWorkerArchivesDeleteFile (params, options) {
  return requestAjax({
    url: '/api-user/sysWorkerArchives/deleteFile',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserEpidemicPreventionInfoId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/epidemic/prevention/info/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 奖捐台账删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserWorkerAwardDonateId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/workerAwardDonate/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 有关证书通过ID删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserWrkerrelatedCertificateId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/wrkerrelatedCertificate/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserWorkerTrainingId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/workerTraining/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserSysCompanyLibraryId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/company/library/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserSysConfigId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/config/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除菜单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserMenusId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/menus/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除接口
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserSysMethodDeleteId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/method/delete/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserSysoperateId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sysoperate/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserSysOperationalGuideId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/operational/guide/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 1.0机构层级-部门管理-删除组织架构
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserOrganizationDeleteBranchIdId (params, options) {
  const { branchId, id } = params
  return requestAjax({
    url: `/api-user/organization/delete/${branchId}/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description delete
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApproveFlowSettingDelete (params, options) {
  return requestAjax({
    url: '/api-user/approveFlowSetting/delete',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 删除班组里的工人为统一的考勤规则ID
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerDeleteWorkerAllGroupIdSettingId (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/deleteWorkerAllGroupIdSettingId',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 9.删除工人培训
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserApiV2WorkerDeleteWorkerTraining (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/deleteWorkerTraining',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 公司部门列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOaDeptList (params, options) {
  return requestAjax({
    url: '/api-user/api/oa/deptList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.5部门组织架构列表(直营，不返回自定义)
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationDeptList (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/deptList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司部门用户列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOaDeptUserList (params, options) {
  return requestAjax({
    url: '/api-user/api/oa/deptUserList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 明细-查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ProjectEssOrderDetailList (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/project/essOrder/detail/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 明细-头部统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ProjectEssOrderDetailSummary (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/project/essOrder/detail/summary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 每天9点检测公司版本到期提醒
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserJobDetectCompanyComboExpiredRemind (params, options) {
  return requestAjax({
    url: '/api-user/job/detectCompanyComboExpiredRemind',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 每天0点检测公司版本过期
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserJobDetectCompanyComboExpired (params, options) {
  return requestAjax({
    url: '/api-user/job/detectCompanyComboExpired',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 每天0点检测项目ESS电子签订单次数到期
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserJobDetectProjectEssOrderExpired (params, options) {
  return requestAjax({
    url: '/api-user/job/detectProjectEssOrderExpired',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 检测二维码
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserLoginDetectQrCode (params, options) {
  return requestAjax({
    url: '/api-user/login/detectQrCode',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 生成二维码
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiDiaryDiaryQrcode (data, options) {
  return requestAjax({
    url: '/api-user/api/diary/diaryQrcode',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 钉钉端H5登录(上架应用市场)-通过miniAppId,corpid,code登录
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserLoginDingDingH5LoginMiniAppIdCorpidCode (data, options) {
  const { code, corpid, miniAppId } = options.params
  return requestAjax({
    url: `/api-user/login/dingDingH5Login/${miniAppId}/${corpid}/${code}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 钉钉端小程序登录(上架应用市场)-通过appid,corpid,code登录
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserLoginDingDingLoginMiniAppIdCorpidCode (data, options) {
  const { code, corpid, miniAppId } = options.params
  return requestAjax({
    url: `/api-user/login/dingDingLogin/${miniAppId}/${corpid}/${code}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 修改加签节点
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2SysApproveFlowEditApproveNode (data, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/editApproveNode',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 开启或关闭流程审批
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ApproveFlowSettingEditEnableFlag (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlowSetting/editEnableFlag',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 开启或关闭流程审批
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2ApproveFlowSettingEditEnableFlagId (data, options) {
  const { id } = options.params
  return requestAjax({
    url: `/api-user/v2/approveFlowSetting/editEnableFlag/${id}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 修改阅示人员
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2SysApproveFlowEditReadNode (data, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/editReadNode',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 编辑工人信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysWorkerArchivesInformationEdit (data, options) {
  return requestAjax({
    url: '/api-user/sysWorkerArchives/information/edit',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6.进退场文件统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerEntryExitFileSummary (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/entryExitFileSummary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 监听部门变更
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiQwEventParty (data, options) {
  return requestAjax({
    url: '/api-user/api/qw/eventParty',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 监听人员变更
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiQwEventUser (data, options) {
  return requestAjax({
    url: '/api-user/api/qw/eventUser',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 审批接口-执行审批操作
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ApproveFlowExecuteApprove (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlow/executeApprove',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 审批接口-执行审批操作
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysApproveFlowExecuteApprove (data, options) {
  return requestAjax({
    url: '/api-user/sysApproveFlow/executeApprove',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 执行阅示操作
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2SysApproveFlowExecuteRead (params, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/executeRead',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人信息_退场文件保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysWorkerArchivesExitFilesSave (data, options) {
  return requestAjax({
    url: '/api-user/sysWorkerArchives/exitFilesSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 导出考勤记录，及薪酬统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserExportExcel (params, options) {
  return requestAjax({
    url: '/api-user/export/excel',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 导出考勤记录，及薪酬统计
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserExportExcelOss (data, options) {
  return requestAjax({
    url: '/api-user/export/excelOss',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 获取导出的版本0复杂，1简易龙源版本
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserExportVersion (params, options) {
  return requestAjax({
    url: '/api-user/export/version',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人花名册导出
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerWorkerExport (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/worker/export',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 超级管理员后台管理查询角色
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserRolesAdminRoles (params, options) {
  return requestAjax({
    url: '/api-user/roles/adminRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 超级管理员查询所有权限:type=1公司、3项目
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysPermissionFindAllsType (params, options) {
  const { type } = params
  return requestAjax({
    url: `/api-user/sys/permission/findAlls/${type}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询所有菜单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserMenusFindAlls (params, options) {
  return requestAjax({
    url: '/api-user/menus/findAlls',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 超级管理员查询所有菜单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserMenusMenuTypeFindAlls (params, options) {
  const { menuType } = params
  return requestAjax({
    url: `/api-user/menus/${menuType}/findAlls`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 超级管理员查询所有权限
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysPermissionFindAlls (params, options) {
  return requestAjax({
    url: '/api-user/sys/permission/findAlls',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 统计工种出勤人数
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerFindAttendanceWorkTypeNum (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/findAttendanceWorkTypeNum',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 7.奖捐明细
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerFindAwardDonateById (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/findAwardDonateById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4.0企业后台-企业基本信息-查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkbenchFindBasicEnterpriseInformation (params, options) {
  return requestAjax({
    url: '/api-user/api/workbench/findBasicEnterpriseInformation',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 现金流
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiProjectBoardFindBusinessFlowMoney (params, options) {
  return requestAjax({
    url: '/api-user/api/project/board/findBusinessFlowMoney',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 现金流
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserProjectBoardFindBusinessFlowMoney (params, options) {
  return requestAjax({
    url: '/api-user/project/board/findBusinessFlowMoney',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3_3_1新建班组-新建（新增）班组-选择所属分公司列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageCompanyCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/api/manage/company/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据companyId来查询公司部门branchType =0公司，1为分公司,包括分公司部门
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationCompanyCompanyIdBranchType (params, options) {
  const { branchType, companyId } = params
  return requestAjax({
    url: `/api-user/organization/company/${companyId}/${branchType}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询公司下面的分公司
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationFindByBranchCompanyId (params, options) {
  return requestAjax({
    url: '/api-user/organization/findByBranchCompanyId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询findByBranchId
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationFindByBranchId (params, options) {
  return requestAjax({
    url: '/api-user/organization/findByBranchId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysConfigFindByCodeAndCompanyId (params, options) {
  return requestAjax({
    url: '/api-user/sys/config/findByCodeAndCompanyId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.0公司系统设置-公司权限管理--获取已保存权限数据
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationFindByCompanyBranchPermission (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/findByCompanyBranchPermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 5.1个性化管理-设置-获取已保存公司权限数据20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationFindByCompanyBranchPermission (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/findByCompanyBranchPermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.1公司角色权限编辑-获取已提交数据20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationFindByCompanyBranchRoles (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/findByCompanyBranchRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.1公司系统设置-职位权限配置-公司职位权限编辑-获取已提交数据
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationFindByCompanyBranchRoles (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/findByCompanyBranchRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.1角色权限管理-公司角色权限编辑-获取已提交数据20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationFindByCompanyBranchRoles (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/findByCompanyBranchRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyFindByCompanyIdId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/company/findByCompanyId/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据公司ID查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationFindByCompanyId (params, options) {
  return requestAjax({
    url: '/api-user/organization/findByCompanyId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询companyId对应用户,0有效，1申请，2拒绝
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsercompanyUserCompanyIdType (params, options) {
  const { companyId, type } = params
  return requestAjax({
    url: `/api-user/usercompany/user/${companyId}/${type}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.1项目角色权限编辑-获取已提交数据20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationFindByCompanyProjectRoles (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/findByCompanyProjectRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.1公司系统设置-职位权限配置-项目职位权限编辑-获取已提交数据
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationFindByCompanyProjectRoles (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/findByCompanyProjectRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.1角色权限管理-项目角色权限编辑-获取已提交数据20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationFindByCompanyProjectRoles (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/findByCompanyProjectRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 5.2公司系统设置-流程详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ApproveFlowSettingId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/api/v1/approveFlowSetting/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1组织架构-查看部门
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationFindById (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/findById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 11.1通过ID查询公司角色、查看库
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyLibraryId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/company/library/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/companyproject/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysConfigId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/config/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询接口
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysMethodId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/method/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysoperateId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sysoperate/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysOperationalGuideFindById (params, options) {
  return requestAjax({
    url: '/api-user/sys/operational/guide/findById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysOrderId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/order/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1机构层级-部门管理-查看部门
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationFindById (params, options) {
  return requestAjax({
    url: '/api-user/organization/findById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6.0公司系统设置-组织架构管理-新增部门-查看部门
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationFindById (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/findById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1组织架构-查看部门
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationFindById (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/findById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 明细-详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ProjectEssOrderDetailId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/api/v1/project/essOrder/detail/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 权限查看
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysPermissionFindById (params, options) {
  return requestAjax({
    url: '/api-user/sys/permission/findById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskFileId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/project/task/file/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskInfoId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/project/task/info/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据ID查询用户
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsersanonId (params, options) {
  return requestAjax({
    url: '/api-user/users-anon/id',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserEpidemicPreventionInfoId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/epidemic/prevention/info/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 奖捐台账通过ID查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserWorkerAwardDonateId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/workerAwardDonate/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 有关证书通过id查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserWrkerrelatedCertificateId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/wrkerrelatedCertificate/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserWorkerTrainingId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/workerTraining/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询项目的基本信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerFindByProjectIdProjectId (params, options) {
  const { projectId } = params
  return requestAjax({
    url: `/api-user/api/worker/findByProjectId/${projectId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 5.2公司系统设置-流程详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2ApproveFlowSettingId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/v2/approveFlowSetting/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApprovejobId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/approvejob/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 5.2公司系统设置-流程详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysApproveTemplateFlowId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/approve/template/flow/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyApiDetailsId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/company/api/details/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyApiTotalId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/company/api/total/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSyscompanycomborenewalId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/syscompanycomborenewal/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 机构层级-角色管理-查看角色
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserRolesFindById (data, options) {
  return requestAjax({
    url: '/api-user/roles/findById',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过所有已进场工人信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerFindByInWorkerAll (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/findByInWorkerAll',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过所有进场、休息工人信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerFindByInWorkerAllWorkingState (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/findByInWorkerAllWorkingState',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过所有工人信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerFindByInWorkerList (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/findByInWorkerList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过workerId查询工人的详细信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerFindByInfoWorkerId (params, options) {
  const { workerId } = params
  return requestAjax({
    url: `/api-user/api/worker/findByInfo/${workerId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取工人的详细信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerFindByInfoWorkerId (params, options) {
  const { workerId } = params
  return requestAjax({
    url: `/api-user/sysworker/findByInfo/${workerId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据手机号查询用户
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerUsersanonMobile (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/users-anon/mobile',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据手机号查询用户
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsersanonMobile (params, options) {
  return requestAjax({
    url: '/api-user/users-anon/mobile',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据OpenId查询用户
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsersanonOpenId (params, options) {
  return requestAjax({
    url: '/api-user/users-anon/openId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description _2_2班组-02班组-工人列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageFindByProjectIdAndGroupIdList (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/findByProjectIdAndGroupIdList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_1_2工人班组-详情-工人列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerFindByProjectIdAndGroupIdList (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/findByProjectIdAndGroupIdList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过项目ID,班组ID查询所有工人信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerFindByProjectIdAndGroupId (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/findByProjectIdAndGroupId',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过项目ID查询指定workerId工人信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerFindByProjectIdAndWorkerIds (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/findByProjectIdAndWorkerIds',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectFindByIdId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/companyproject/findById/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过项目ID查询所有工人信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerFindByProjectId (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/findByProjectId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过项目IDs,公司ID查询所有工人信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerFindByProjectIdsAndCompanyId (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/findByProjectIdsAndCompanyId',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 查询项目的基本信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerFindByProjectUserStatusProjectId (params, options) {
  const { projectId } = params
  return requestAjax({
    url: `/api-user/api/worker/findByProjectUserStatus/${projectId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 机构层级-菜单管理-查看菜单模板
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserRolesFindByRoleTemplate (data, options) {
  return requestAjax({
    url: '/api-user/roles/findByRoleTemplate',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3-4-1-1-任务详情-详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiTaskFindByTaskId (params, options) {
  return requestAjax({
    url: '/api-user/api/task/findByTaskId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.5任务详情-详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysBranchTaskFindByTaskId (params, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/findByTaskId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.5任务详情-详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyTaskFindByTaskId (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/findByTaskId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.5任务详情-详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskFindByTaskId (params, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/findByTaskId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 从代办跳转到任务详情-审批
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiTaskFindByTaskInfoId (params, options) {
  return requestAjax({
    url: '/api-user/api/task/findByTaskInfoId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据UnionId查询用户
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsersanonUnionId (params, options) {
  return requestAjax({
    url: '/api-user/users-anon/unionId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1-4-切换企业-获取当前用户所有公司
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiHomePageFindByUserCompany (params, options) {
  return requestAjax({
    url: '/api-user/api/home/page/findByUserCompany',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1-4-切换企业-获取当前用户所有公司
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityFindByUserCompany (params, options) {
  return requestAjax({
    url: '/api-user/api/security/findByUserCompany',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过userid,项目ID查询工人信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerFindByUserIdAndProjectId (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/findByUserIdAndProjectId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过token查询用户ID
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageFindByUserId (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/findByUserId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取当前用户状态 type=0为空，type=2,返回公司信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageFindByUserStauts (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/findByUserStauts',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取当前用户状态
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerFindByUserStauts (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/findByUserStauts',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据用户名查询用户
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsersanonLogin (params, options) {
  return requestAjax({
    url: '/api-user/users-anon/login',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过workerId查询工人基本信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerFindByWorkerIdWorkerId (params, options) {
  const { workerId } = params
  return requestAjax({
    url: `/api-user/api/worker/findByWorkerId/${workerId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询天气
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiQualityFindCityAndDateByWeather (params, options) {
  return requestAjax({
    url: '/api-user/api/quality/findCityAndDateByWeather',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询天气
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityFindCityAndDateByWeather (params, options) {
  return requestAjax({
    url: '/api-user/api/security/findCityAndDateByWeather',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCityweatherFindCityAndDateByWeather (params, options) {
  return requestAjax({
    url: '/api-user/sys/cityweather/findCityAndDateByWeather',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.2版本管理 - 根据id查询详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProductComboId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/product/combo/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.1版本管理 - 版本列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProductComboList (params, options) {
  return requestAjax({
    url: '/api-user/sys/product/combo/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司管理员通过分公司ID查询所有菜单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserMenusCompanyCompanyIdBranchIdMenuTypeFindAlls (params, options) {
  const { branchId, companyId, menuType } = params
  return requestAjax({
    url: `/api-user/menus/company/${companyId}/${branchId}/${menuType}/findAlls`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司管理员查询所有菜单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserMenusCompanyCompanyIdMenuTypeFindAlls (params, options) {
  const { companyId, menuType } = params
  return requestAjax({
    url: `/api-user/menus/company/${companyId}/${menuType}/findAlls`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司管理员查询所有权限
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysPermissionCompanyCompanyIdFindAlls (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/sys/permission/company/${companyId}/findAlls`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司-分公司ID查询所有权限
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysPermissionCompanyCompanyIdBranchIdFindAlls (params, options) {
  const { branchId, companyId } = params
  return requestAjax({
    url: `/api-user/sys/permission/company/${companyId}/${branchId}/findAlls`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司-分公司ID查询所有权限,通过类型过滤
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysPermissionCompanyCompanyIdBranchIdFindAllsType (params, options) {
  const { branchId, companyId, type } = params
  return requestAjax({
    url: `/api-user/sys/permission/company/${companyId}/${branchId}/findAlls/${type}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 内部调用-根据companyId来获取分公司,根据userId来获取当前用户所在分公司
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationFindCompanyIdAndUserIdByBranchList (params, options) {
  return requestAjax({
    url: '/api-user/organization/findCompanyIdAndUserIdByBranchList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 12.3机构层级设置-菜单模板-通过模板id查看已勾选的
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyLibraryFindCompanyLibraryByRoleId (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/library/findCompanyLibraryByRoleId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 超级管理员查询公司、项目所有权限
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysPermissionFindCompanyPermission (params, options) {
  return requestAjax({
    url: '/api-user/sys/permission/findCompanyPermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1-5-创建项目-获取项目的角色
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityFindCompanyRole (params, options) {
  return requestAjax({
    url: '/api-user/api/security/findCompanyRole',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4.0机构层级-角色管理
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserRolesCompanyRoles (params, options) {
  return requestAjax({
    url: '/api-user/roles/companyRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6.3查询个性化公司
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationFindCustomById (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/findCustomById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 5.2-查看自定义公司
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationFindCustomById (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/findCustomById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6.查看进退场文件
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerFindEntryExitFileList (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/findEntryExitFileList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 防疫信息查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageEpidemicPreventionInfoByWorker (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/epidemicPreventionInfoByWorker',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 防疫信息查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1WorkerEpidemicPreventionInfoByWorker (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/worker/epidemicPreventionInfoByWorker',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 防疫信息详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserEpidemicPreventionInfoFindEpidemicPreventionInfo (params, options) {
  return requestAjax({
    url: '/api-user/epidemic/prevention/info/findEpidemicPreventionInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6.查看体检报告
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerExaminationReport (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/examinationReport',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 后台管理员根据roleId获取对应的菜单{menuType}
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserMenusAdminRoleIdMenusMenuType (params, options) {
  const { menuType, roleId } = params
  return requestAjax({
    url: `/api-user/menus/admin/${roleId}/menus/${menuType}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 后台管理员根据permissionId获取对应的菜单{menuType}
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysPermissionAdminPermissionIdMenusMenuType (params, options) {
  const { menuType, permissionId } = params
  return requestAjax({
    url: `/api-user/sys/permission/admin/${permissionId}/menus/${menuType}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 后台管理员根据roleId获取所有类型对应的菜单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserMenusAdminRoleIdMenus (params, options) {
  const { roleId } = params
  return requestAjax({
    url: `/api-user/menus/admin/${roleId}/menus`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 后台管理员根据roleId获取所有类型对应的菜单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysPermissionAdminPermissionIdMenus (params, options) {
  const { permissionId } = params
  return requestAjax({
    url: `/api-user/sys/permission/admin/${permissionId}/menus`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.0工作台-报警预警消息统计
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkbenchFindMessageCount (data, options) {
  return requestAjax({
    url: '/api-user/api/workbench/findMessageCount',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 根据roleIds获取对应的权限-后台网关调用控制
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysMethodFindMethodByRoleIds (params, options) {
  return requestAjax({
    url: '/api-user/sys/method/findMethodByRoleIds',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3-1-1-我的任务-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiTaskFindMyProjectTaskList (params, options) {
  return requestAjax({
    url: '/api-user/api/task/findMyProjectTaskList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1我的任务-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysBranchTaskFindMyProjectTaskList (params, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/findMyProjectTaskList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1我的任务-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyTaskFindMyProjectTaskList (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/findMyProjectTaskList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1我的任务-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskFindMyProjectTaskList (params, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/findMyProjectTaskList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取菜单以及顶级菜单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserMenusFindOnes (params, options) {
  return requestAjax({
    url: '/api-user/menus/findOnes',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据companyId来获取组织架构
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationCompanyCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/organization/company/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 超级管理员查询路由是否存在
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserMenusIsNameExist (params, options) {
  return requestAjax({
    url: '/api-user/menus/isNameExist',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 超级管理员查询所有父级菜单过滤按钮权限
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserMenusMenuTypeFindParentsAlls (params, options) {
  const { menuType } = params
  return requestAjax({
    url: `/api-user/menus/${menuType}/findParentsAlls`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectFindProjectByBranch (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/findProjectByBranch',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询项目详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ProjectFindProjectById (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/project/findProjectById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1-5-创建项目-获取项目信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityFindProjectById (params, options) {
  return requestAjax({
    url: '/api-user/api/security/findProjectById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目进度
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiProjectBoardFindProjectCurrentProgress (params, options) {
  return requestAjax({
    url: '/api-user/api/project/board/findProjectCurrentProgress',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目进度
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserProjectBoardFindProjectCurrentProgress (params, options) {
  return requestAjax({
    url: '/api-user/project/board/findProjectCurrentProgress',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目现金流详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserProjectBoardFindProjectMoneyFlowDetail (params, options) {
  return requestAjax({
    url: '/api-user/project/board/findProjectMoneyFlowDetail',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目利润详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserProjectBoardFindProjectProfitDetail (params, options) {
  return requestAjax({
    url: '/api-user/project/board/findProjectProfitDetail',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目利润
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiProjectBoardFindProjectProfit (params, options) {
  return requestAjax({
    url: '/api-user/api/project/board/findProjectProfit',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目利润
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserProjectBoardFindProjectProfit (params, options) {
  return requestAjax({
    url: '/api-user/project/board/findProjectProfit',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4.3公司系统设置-项目人员配置-职位权限-新增/编辑-查询数据
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationFindProjectRoleById (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/findProjectRoleById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3-3-1-所有任务-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiTaskFindProjectTaskList (params, options) {
  return requestAjax({
    url: '/api-user/api/task/findProjectTaskList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.6所有任务-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysBranchTaskFindProjectTaskList (params, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/findProjectTaskList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.6所有任务-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyTaskFindProjectTaskList (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/findProjectTaskList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.6所有任务-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskFindProjectTaskList (params, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/findProjectTaskList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 8.证书信息查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerFindRelatedCertificateById (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/findRelatedCertificateById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3-2-1-我发布的任务-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiTaskFindReleaseProjectTaskList (params, options) {
  return requestAjax({
    url: '/api-user/api/task/findReleaseProjectTaskList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.3我发布的任务-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysBranchTaskFindReleaseProjectTaskList (params, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/findReleaseProjectTaskList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.3我发布的任务-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyTaskFindReleaseProjectTaskList (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/findReleaseProjectTaskList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.3我发布的任务-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskFindReleaseProjectTaskList (params, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/findReleaseProjectTaskList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 管理查询角色
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProductComboRoleList (params, options) {
  return requestAjax({
    url: '/api-user/sys/product/combo/roleList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 管理查询角色
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserRolesList (params, options) {
  return requestAjax({
    url: '/api-user/roles/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目决算
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiProjectBoardFindSettleAccounts (params, options) {
  return requestAjax({
    url: '/api-user/api/project/board/findSettleAccounts',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目决算
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserProjectBoardFindSettleAccounts (params, options) {
  return requestAjax({
    url: '/api-user/project/board/findSettleAccounts',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.0工作台-审批代办、我的任务、我发布的任务、抄送我的任务
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkbenchFindStatisticsTask (params, options) {
  return requestAjax({
    url: '/api-user/api/workbench/findStatisticsTask',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 企业列表-全部版本
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOperatesFindSysProductList (params, options) {
  return requestAjax({
    url: '/api-user/api/operates/findSysProductList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询projectIdAndGroupId对应待审核工人列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerUserProjectIdProjectIdGroupId (params, options) {
  const { groupId, projectId } = params
  return requestAjax({
    url: `/api-user/sysworker/user/projectId/${projectId}/${groupId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 任务管理
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskUserFindTaskManagement (params, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/user/findTaskManagement',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询companyId对应待审核工人列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerUserCompanyIdCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/sysworker/user/companyId/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyFindById (params, options) {
  return requestAjax({
    url: '/api-user/company/findById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据ID查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserMenusId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/menus/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据ID查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/organization/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询companyId对应用户
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsercompanyUserCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/usercompany/user/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description findUserById
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsersId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/users/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserWechatId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/wechat/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sysworker/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询projectId对应待审核工人列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerUserProjectIdProjectId (params, options) {
  const { projectId } = params
  return requestAjax({
    url: `/api-user/sysworker/user/projectId/${projectId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 用户查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsers (params, options) {
  return requestAjax({
    url: '/api-user/users',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询项目班组实时在场、休息人员
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerFindWorkerAttendanceCountByGroupId (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/findWorkerAttendanceCountByGroupId',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过工人ID查询所有工人信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerFindWorkerByGroupIdAndWorkerId (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/findWorkerByGroupIdAndWorkerId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过ID查询工人信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerFindWorkerById (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/findWorkerById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据项目id查询班组姓名以及工人数量
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerFindWorkerGroupPeopleNum (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/findWorkerGroupPeopleNum',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 查询用户信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerFindWorkerList (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/findWorkerList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 在场工人统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerFindWorkerPresentStatistics (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/findWorkerPresentStatistics',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询工人工资
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerFindWorkerSalary (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/findWorkerSalary',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 9.工人培训信息查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerFindWorkerTrainingById (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/findWorkerTrainingById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 统计工种数量
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerFindWorkerTypeCount (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/findWorkerTypeCount',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过ID查询工人信息-返回工人user信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerFindWorkerUserById (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/findWorkerUserById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 统计每日在场、退场工人数量
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerFindWorkerWhetherPresent (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/findWorkerWhetherPresent',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 忘记密码
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserLoginForgetPassword (data, options) {
  return requestAjax({
    url: '/api-user/login/forgetPassword',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 审批接口-生成审批主体
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysApproveFlowGenerateSysApproveJob (data, options) {
  return requestAjax({
    url: '/api-user/sysApproveFlow/generateSysApproveJob',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 小程序通过appid获取AccessToken
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiQwGetAccessToken (data, options) {
  return requestAjax({
    url: '/api-user/api/qw/getAccessToken',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 小程序通过appid获取AccessToken
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ThirdPartyAuthGetAccessToken (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyAuth/getAccessToken',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 获取审批按钮权限
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2SysApproveFlowGetApproveButtonPermission (params, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/getApproveButtonPermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6.2公司系统设置-组织架构管理-成员申请-查询人员信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationGetApproveCompanyUserInfo (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/getApproveCompanyUserInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1组织架构--新申请成员-查询人员信息20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationGetApproveCompanyUserInfo (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/getApproveCompanyUserInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取流程模块审批条件
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2ApproveFlowSettingGetApproveConditions (data, options) {
  return requestAjax({
    url: '/api-user/v2/approveFlowSetting/getApproveConditions',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 5.0公司系统设置-流程管理
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysApproveTemplateFlowGetApproveFlowProperties (params, options) {
  return requestAjax({
    url: '/api-user/sys/approve/template/flow/getApproveFlowProperties',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 5.1公司系统设置-流程审批列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysApproveTemplateFlowGetApproveFlowSettingList (params, options) {
  return requestAjax({
    url: '/api-user/sys/approve/template/flow/getApproveFlowSettingList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 审批流程接口-获取审批流程的所有节点信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ApproveFlowSettingGetApproveInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlowSetting/getApproveInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 审批流程接口-获取审批流程的所有节点信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApproveFlowSettingGetApproveInfo (params, options) {
  return requestAjax({
    url: '/api-user/approveFlowSetting/getApproveInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 审批流程接口-获取审批流程的所有节点信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2SysApproveFlowGetApproveInfo (params, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/getApproveInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 审批流程接口-获取审批流程的所有节点信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2SysApproveFlowGetApproveInfoNew (data, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/getApproveInfoNew',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 获取审批角色列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ApproveFlowSettingGetApproveRoleListByOrg (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlowSetting/getApproveRoleListByOrg',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取审批角色列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2ApproveFlowSettingGetApproveRoleListByOrg (params, options) {
  return requestAjax({
    url: '/api-user/v2/approveFlowSetting/getApproveRoleListByOrg',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据角色类型获取审批角色列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ApproveFlowSettingGetApproveRoleListByOrgAndRoleType (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlowSetting/getApproveRoleListByOrgAndRoleType',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取审批角色列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysApproveTemplateFlowGetApproveTemplateRoleListByOrgIntersection (params, options) {
  return requestAjax({
    url: '/api-user/sys/approve/template/flow/getApproveTemplateRoleListByOrgIntersection',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据角色id获取审批人员
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2SysApproveFlowGetApproveUserByRoleIds (data, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/getApproveUserByRoleIds',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 个人信息_出勤记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysWorkerArchivesGetAttendanceInfoList (params, options) {
  return requestAjax({
    url: '/api-user/sysWorkerArchives/getAttendanceInfoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 个人信息_基本信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysWorkerArchivesBaseInfoWorkerId (params, options) {
  const { workerId } = params
  return requestAjax({
    url: `/api-user/sysWorkerArchives/baseInfo/${workerId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询分公司的名称
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageGetBranchCompanyById (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/getBranchCompanyById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 审批流程接口-获取流程设置
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApproveFlowSettingGetFlowSettingByCode (params, options) {
  return requestAjax({
    url: '/api-user/approveFlowSetting/getFlowSettingByCode',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.7公司系统设置-职位权限配置-公司功能权限
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationGetByCompanyBranchPermission (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/getByCompanyBranchPermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.7公司系统设置-职位权限配置-项目功能权限
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationGetByCompanyProjectPermission (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/getByCompanyProjectPermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据手机号查询是否已存在
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsersanonGetByMobile (params, options) {
  return requestAjax({
    url: '/api-user/users-anon/getByMobile',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据用户名查询是否已存在
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsersanonGetByUsername (params, options) {
  return requestAjax({
    url: '/api-user/users-anon/getByUsername',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 抄送我的
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ApproveFlowGetCcMyTodoList (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlow/getCcMyTodoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 个人信息_证件信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysWorkerArchivesCertificateInfoWorkerId (params, options) {
  const { workerId } = params
  return requestAjax({
    url: `/api-user/sysWorkerArchives/certificateInfo/${workerId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4.0企业后台-版本信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationCompanyGetComboOrderInfo (params, options) {
  return requestAjax({
    url: '/api-user/organization/company/getComboOrderInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.7公司系统设置-职位权限配置-查看公司职位
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationGetCompanyBranchLibraryById (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/getCompanyBranchLibraryById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6.3编辑个性化公司设置-获取上级赋予公司权限列表*20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationCompanyGetCompanyBranchPermission (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/company/getCompanyBranchPermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.0公司系统设置-公司权限管理-获取上级赋予权限列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationCompanyGetCompanyBranchPermission (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/company/getCompanyBranchPermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 5.1个性化管理-设置-获取上级赋予公司权限列表*20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationCompanyGetCompanyBranchPermission (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/company/getCompanyBranchPermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.1公司职位权限编辑-获取上级赋予权限列表20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationCompanyGetCompanyBranchRolePermission (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/company/getCompanyBranchRolePermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.1公司系统设置-职位权限配置-公司职位权限编辑-获取权限列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationCompanyGetCompanyBranchRolePermission (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/company/getCompanyBranchRolePermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.1角色权限管理-公司职位权限编辑-获取上级赋予权限列表20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationCompanyGetCompanyBranchRolePermission (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/company/getCompanyBranchRolePermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询公司的明细
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageGetCompanyById (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/getCompanyById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过订单号获取新创建企业
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysOrderGetCompanyByOrderId (params, options) {
  return requestAjax({
    url: '/api-user/sys/order/getCompanyByOrderId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑获取公司详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserYzwGetCompanyInfo (params, options) {
  return requestAjax({
    url: '/api-user/yzw/getCompanyInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 企业列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOperatesGetCompanyList (params, options) {
  return requestAjax({
    url: '/api-user/api/operates/getCompanyList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑公司列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserYzwCompanyList (params, options) {
  return requestAjax({
    url: '/api-user/yzw/companyList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询项目的明细
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageGetCompanyProjectById (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/getCompanyProjectById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.8公司系统设置-职位权限配置-查看项目职位
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationGetCompanyProjectLibraryById (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/getCompanyProjectLibraryById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.1项目职位权限编辑-获取上级赋予权限列表20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationCompanyGetCompanyProjectRolePermission (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/company/getCompanyProjectRolePermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.1公司系统设置-职位权限配置-项目职位权限编辑-获取权限列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationCompanyGetCompanyProjectRolePermission (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/company/getCompanyProjectRolePermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.1角色权限管理-项目职位权限编辑-获取上级赋予权限列表20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationCompanyGetCompanyProjectRolePermission (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/company/getCompanyProjectRolePermission',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1_1项目人员配置-查看所有职位20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectGetCompanyProjectRoles (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/getCompanyProjectRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4.1公司系统设置-项目人员配置-配置人员-查看所有职位
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationGetCompanyProjectRoles (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/getCompanyProjectRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.0企业后台-成员管理-获取公司管理名额
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationGetCompanyUserCount (params, options) {
  return requestAjax({
    url: '/api-user/organization/getCompanyUserCount',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4.1企业后台-成员管理-获取公司管理名额
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkbenchGetCompanyUserCount (data, options) {
  return requestAjax({
    url: '/api-user/api/workbench/getCompanyUserCount',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.4编辑人员信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationGetCompanyUserInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/getCompanyUserInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1公司系统设置-组织架构管理-查询人员具体信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationGetCompanyUserInfo (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/getCompanyUserInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1组织架构-获取人员信息20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationGetCompanyUserInfo (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/getCompanyUserInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 企业按周统计数据列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOperatesGetCompanyWeekList (params, options) {
  return requestAjax({
    url: '/api-user/api/operates/getCompanyWeekList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取企微对应租户的token
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserJobGetCorpAccessToken (data, options) {
  return requestAjax({
    url: '/api-user/job/getCorpAccessToken',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 获取企微对应租户的token
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiQwGetCorpAccessToken (data, options) {
  return requestAjax({
    url: '/api-user/api/qw/getCorpAccessToken',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 趋势图
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOperatesGetDateOperateData (params, options) {
  return requestAjax({
    url: '/api-user/api/operates/getDateOperateData',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据项目ids 查询所有项目在建状态的总天数
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectGetDaysNumTotalByProjectIds (data, options) {
  return requestAjax({
    url: '/api-user/companyproject/getDaysNumTotalByProjectIds',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 根据项目ids 查询各项目在建状态的天数
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectGetDaysNumTotalGroupByProjectIds (data, options) {
  return requestAjax({
    url: '/api-user/companyproject/getDaysNumTotalGroupByProjectIds',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 党员突击队-查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserPartyPartyCommandoGetDetailById (params, options) {
  return requestAjax({
    url: '/api-user/party/partyCommando/getDetailById',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 审批-获取审批任务下的所有流程节点配置
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ApproveFlowGetFlowItems (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlow/getFlowItems',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 审批-获取审批任务下的所有流程节点配置
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysApproveFlowGetFlowItems (params, options) {
  return requestAjax({
    url: '/api-user/sysApproveFlow/getFlowItems',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 审批-获取审批任务下的流程节点(带发布人节点)
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2SysApproveFlowGetFlowItems (params, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/getFlowItems',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 审批流程接口-根据module获取审批流程
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApproveFlowSettingGetFlowSettingsByModule (params, options) {
  return requestAjax({
    url: '/api-user/approveFlowSetting/getFlowSettingsByModule',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1_1账号申请-根据access_token获取信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageUsersCurrent (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/users/current',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询公司注册员工数量
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyGetManagerCountCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/company/getManagerCount/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取消息数量统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ApproveFlowGetMessageCount (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlow/getMessageCount',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 管理端-微信用户获取手机号码
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserApiManageUsersGetMobile (data, options) {
  return requestAjax({
    url: '/api-user/api/manage/users/getMobile',
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description 我发起的
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ApproveFlowGetMyInitiateTodoList (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlow/getMyInitiateTodoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 我的待办
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ApproveFlowGetMyTodoList (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlow/getMyTodoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectGetNotHasSettingProjectList (data, options) {
  return requestAjax({
    url: '/api-user/companyproject/getNotHasSettingProjectList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 获取流程模块列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ApproveFlowSettingGetApproveFlowSettingProperties (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlowSetting/getApproveFlowSettingProperties',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取流程模块列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2ApproveFlowSettingGetApproveFlowSettingProperties (params, options) {
  return requestAjax({
    url: '/api-user/v2/approveFlowSetting/getApproveFlowSettingProperties',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 数据统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1BoardGetOrgInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/board/getOrgInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取支付二维码base64
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrderGetPayQrcodeId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/api/order/getPayQrcode/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取支付二维码base64
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysOrderGetPayQrcodeId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/sys/order/getPayQrcode/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目考勤付费统计-带数据权限过滤
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectGetProjectAttnStatistics (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/getProjectAttnStatistics',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询公司分公司下项目数量
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectGetProjectCount (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/getProjectCount',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取项目电子合同次数统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ProjectEssOrderGetProjectEssOrderCountSummary (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/project/essOrder/getProjectEssOrderCountSummary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取项目电子合同剩余可用次数
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ProjectEssOrderGetProjectEssOrderSurplusCountFeign (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/project/essOrder/getProjectEssOrderSurplusCountFeign',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取项目电子合同剩余可用次数
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ProjectEssOrderGetProjectEssOrderSurplusCount (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/project/essOrder/getProjectEssOrderSurplusCount',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1-安全-首页-头部
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityGetProjectInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/security/getProjectInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑获取项目详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserYzwGetProjectInfo (params, options) {
  return requestAjax({
    url: '/api-user/yzw/getProjectInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑查询项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserYzwGetProjectList (params, options) {
  return requestAjax({
    url: '/api-user/yzw/getProjectList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 审批-获取任务下所有流程节点的审批记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ApproveFlowGetSpecialAllFlowItems (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlow/getSpecialAllFlowItems',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 审批-获取任务下所有流程节点的审批记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysApproveFlowGetSpecialAllFlowItems (params, options) {
  return requestAjax({
    url: '/api-user/sysApproveFlow/getSpecialAllFlowItems',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6.领料记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerGetStorageApplyDetailListByWorkerId (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/getStorageApplyDetailListByWorkerId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 个人信息_领料记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysWorkerArchivesGetStorageApplyDetailListByWorkerId (params, options) {
  return requestAjax({
    url: '/api-user/sysWorkerArchives/getStorageApplyDetailListByWorkerId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑查询项目参建单位列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserYzwGetSubcontractorList (params, options) {
  return requestAjax({
    url: '/api-user/yzw/getSubcontractorList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 个人信息_领料记录总金额
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysWorkerArchivesGetSumApplyTotalTaxPriceByWorkerId (params, options) {
  return requestAjax({
    url: '/api-user/sysWorkerArchives/getSumApplyTotalTaxPriceByWorkerId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 审批流程接口-根据审批id获取所属审批流程
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApproveFlowSettingGetFlowSettingByJobId (params, options) {
  return requestAjax({
    url: '/api-user/approveFlowSetting/getFlowSettingByJobId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据companyId、orgId获取当前节点所有下级机构
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationCompanyGetTheChildOrgId (params, options) {
  return requestAjax({
    url: '/api-user/organization/company/getTheChildOrgId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 5.0公司系统设置-机构树
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysApproveTemplateFlowCompanyGetTheChildOrg (params, options) {
  return requestAjax({
    url: '/api-user/sys/approve/template/flow/company/getTheChildOrg',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据companyId、orgId获取当前节点所有下级机构含名称
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationCompanyGetTheChildOrg (params, options) {
  return requestAjax({
    url: '/api-user/organization/company/getTheChildOrg',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 统计每个工人的工资
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerGetTodaySalaryTotal (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/getTodaySalaryTotal',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 统计今日在场工人数量
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerGetTodayWorkerNumByGroupIdList (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/getTodayWorkerNumByGroupIdList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 统计今日在场工人数量
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerGetTodayWorkerNumByProjectIdList (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/getTodayWorkerNumByProjectIdList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 获取待办事项列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ApproveFlowGetTodoList (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlow/getTodoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取待办事项列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysApproveFlowGetTodoList (params, options) {
  return requestAjax({
    url: '/api-user/sysApproveFlow/getTodoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取各事项统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ApproveFlowGetTodoStatistical (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlow/getTodoStatistical',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取TOKEN
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserLoginGetToken (data, options) {
  return requestAjax({
    url: '/api-user/login/getToken',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过公司ID,部门id查询用户列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUserorgGetUserByCompanyOrg (params, options) {
  return requestAjax({
    url: '/api-user/userorg/getUserByCompanyOrg',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 用户所在公司-分公司权限 true 多个分公司，false 只有一个分公司
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageGetUserCompanyBranchPermissions (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/getUserCompanyBranchPermissions',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取当前用户创建企业个数
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyGetUserCompanyCount (params, options) {
  return requestAjax({
    url: '/api-user/company/getUserCompanyCount',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 码里日志获取近7天数据，及用户总数
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserWechatDataStatistics (params, options) {
  return requestAjax({
    url: '/api-user/wechat/dataStatistics',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据用户Id查询用户头像
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV2UserGetUserFaceImgUrlList (data, options) {
  return requestAjax({
    url: '/api-user/api/v2/user/getUserFaceImgUrlList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 根据用户Id查询用户头像
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV2UserGetUserFaceImgUrlMap (data, options) {
  return requestAjax({
    url: '/api-user/api/v2/user/getUserFaceImgUrlMap',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 根据用户Id查询用户头像
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2UserGetUserFaceImgUrlUserId (params, options) {
  const { userId } = params
  return requestAjax({
    url: `/api-user/api/v2/user/getUserFaceImgUrl/${userId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description userId转换openId
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ThirdPartyUserGetUserIdByUnionId (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyUser/getUserIdByUnionId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过corpId获取授权过的userInfo
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ThirdPartyUserGetUserInfoByCorpIdAndAppid (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyUser/getUserInfoByCorpIdAndAppid',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过corpId获取授权过的userInfo
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ThirdPartyUserGetUserInfoByCorpId (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyUser/getUserInfoByCorpId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人信息_进场文件
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysWorkerArchivesGetWorkerIdApproachFiles (params, options) {
  return requestAjax({
    url: '/api-user/sysWorkerArchives/getWorkerIdApproachFiles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人信息_退场文件
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysWorkerArchivesGetWorkerIdExitFiles (params, options) {
  return requestAjax({
    url: '/api-user/sysWorkerArchives/getWorkerIdExitFiles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_4_1-05查看详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageGetWorkerInfoUser (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/getWorkerInfoUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_4_1-05查看详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerGetWorkerInfoUser (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/getWorkerInfoUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1_1工人登录-绑定申请-获取工人的信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerGetWorkerInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/getWorkerInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6_1_2个人档案-查看详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerGetWorkerInfoUser (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/getWorkerInfoUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 编辑工人信息_工人信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysWorkerArchivesInformationWorkerId (params, options) {
  const { workerId } = params
  return requestAjax({
    url: `/api-user/sysWorkerArchives/information/${workerId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过companyId和projectIds查询各工种类型
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerGetWorkerTypeListByProjectIds (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/getWorkerTypeListByProjectIds',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过branchId或者projectId查询学历
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1BoardZGetWorkersPortraitEducationList (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/boardZ/getWorkersPortraitEducationList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过branchId或者projectId查询所有工人是否党员
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1BoardZGetWorkersPortraitPartyMemberList (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/boardZ/getWorkersPortraitPartyMemberList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过branchId或者projectId查询所有工人是否党员
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerGetWorkersPortraitPartyMemberList (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/getWorkersPortraitPartyMemberList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过branchId或者projectId查询各籍贯类型
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerGetWorkersPortraitProvinceList (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/getWorkersPortraitProvinceList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过branchId或者projectId查询各籍贯类型（省）
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1BoardZGetWorkersPortraitProvinceList (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/boardZ/getWorkersPortraitProvinceList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过branchId或者projectId查询各籍贯类型(大区)
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1BoardZGetWorkersPortraitRegionList (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/boardZ/getWorkersPortraitRegionList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过branchId或者projectId查询所有工人年龄
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerGetWorkersPortraitWorkerAgeList (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/getWorkersPortraitWorkerAgeList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过branchId或者projectId查询所有工人年龄
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1BoardZGetWorkersPortraitWorkerAgeList (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/boardZ/getWorkersPortraitWorkerAgeList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过branchId或者projectId查询所有工人性别
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerGetWorkersPortraitWorkerGenderList (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/getWorkersPortraitWorkerGenderList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过branchId或者projectId查询所有工人性别
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1BoardZGetWorkersPortraitWorkerGenderList (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/boardZ/getWorkersPortraitWorkerGenderList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过branchId或者projectId查询各工种类型
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerGetWorkersPortraitWorkerTypeList (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/getWorkersPortraitWorkerTypeList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过branchId或者projectId查询各工种类型
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1BoardZGetWorkersPortraitWorkerTypeList (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/boardZ/getWorkersPortraitWorkerTypeList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 人脸身份证比对
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerGetfaceidb (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/getfaceidb',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 修改班组下工人授权状态
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerGroupAuthState (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/groupAuthState',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 下订单-班组剩余天数购买
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrderGroupDayOrder (data, options) {
  return requestAjax({
    url: '/api-user/api/order/groupDayOrder',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 班组长列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiQualityGroupMangageList (params, options) {
  return requestAjax({
    url: '/api-user/api/quality/groupMangageList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 班组长列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityGroupMangageList (params, options) {
  return requestAjax({
    url: '/api-user/api/security/groupMangageList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 5.工人管理-筛选条件-班组
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerGroupName (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/groupName',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 下订单-班组名额授权订单支付
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrderGroupNumberOrder (data, options) {
  return requestAjax({
    url: '/api-user/api/order/groupNumberOrder',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 下订单-班组名额授权订单支付
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysOrderGroupNumberOrder (data, options) {
  return requestAjax({
    url: '/api-user/sys/order/groupNumberOrder',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 下订单-班组授权订单支付
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrderGroupOrder (data, options) {
  return requestAjax({
    url: '/api-user/api/order/groupOrder',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 下订单-班组授权订单支付
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysOrderGroupOrder (data, options) {
  return requestAjax({
    url: '/api-user/sys/order/groupOrder',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3_3_3班组详情-考勤设置选择工人
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageGroupSettingUserlist (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/groupSettingUserlist',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3_3_3班组详情- 02选择工人-工人列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageGroupUserlist (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/groupUserlist',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 班组工人列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiQualityGroupWorkerList (params, options) {
  return requestAjax({
    url: '/api-user/api/quality/groupWorkerList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 班组工人列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityGroupWorkerList (params, options) {
  return requestAjax({
    url: '/api-user/api/security/groupWorkerList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_总部_列表数据(分公司)
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageHeadquartersBranch (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/headquartersBranch',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_总部_头部
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageHeadquartersCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/api/manage/headquarters/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_总部_头部
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageHeadquarters (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/headquarters',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 身份证识别
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageIdCardOCR (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/idCardOCR',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 身份证识别
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerIdCardOCR (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/idCardOCR',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 广告图片地址
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiDiaryImageList (params, options) {
  return requestAjax({
    url: '/api-user/api/diary/imageList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人花名册导入
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerWorkerImport (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/worker/import',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2_2_2班组-07工人详情 – 进场
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiManageInTimeWorkerId (data, options) {
  const { workerId } = options.params
  return requestAjax({
    url: `/api-user/api/manage/inTime/${workerId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2_1_8工人详情 工人进场
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkerInTimeWorkerId (data, options) {
  const { workerId } = options.params
  return requestAjax({
    url: `/api-user/api/worker/inTime/${workerId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 进场
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerInTimeId (data, options) {
  const { id } = options.params
  return requestAjax({
    url: `/api-user/sysworker/inTime/${id}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6_1项目部-工人档案列表-黑名单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerUserInfoBlackList (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/user/infoBlackList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 初始化公司信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyInitialCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/company/initial/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 初始化系统默认审批模板
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysApproveTemplateFlowInitialDefaultTemplate (params, options) {
  return requestAjax({
    url: '/api-user/sys/approve/template/flow/initialDefaultTemplate',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 初始化机构默认审批模板
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysApproveTemplateFlowInitialOrgDefaultTemplate (params, options) {
  return requestAjax({
    url: '/api-user/sys/approve/template/flow/initialOrgDefaultTemplate',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 添加加签节点
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2SysApproveFlowAddApproveNode (data, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/addApproveNode',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 安全隐患
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiProjectBoardInspectionStatistical (params, options) {
  return requestAjax({
    url: '/api-user/api/project/board/inspectionStatistical',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 安全隐患
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserProjectBoardInspectionStatistical (params, options) {
  return requestAjax({
    url: '/api-user/project/board/inspectionStatistical',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取质量违章类型整改类型
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiQualityInspectionTypeName (params, options) {
  return requestAjax({
    url: '/api-user/api/quality/inspectionTypeName',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取安全违章类型整改类型
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityInspectionTypeName (params, options) {
  return requestAjax({
    url: '/api-user/api/security/inspectionTypeName',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 判断是否初始化公司信息true需要初始化，false 不需要初始化
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyIsinitialCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/company/isinitial/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.3公司系统设置-职位权限配置-全部职位-3.6
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationLibraryRoleList (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/libraryRoleList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description link_work
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserIaiOCR (data, options) {
  return requestAjax({
    url: '/api-user/iaiOCR',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description listAllByCompanyIdAndUserId
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUserorgListAllByCompanyIdAndUserId (params, options) {
  return requestAjax({
    url: '/api-user/userorg/listAllByCompanyIdAndUserId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询项目、下面的安全员-不分页
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectListAllByCompanyProjectDiary (data, options) {
  return requestAjax({
    url: '/api-user/companyproject/listAllByCompanyProjectDiary',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 查询列表-不分页
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysOperationalGuideListAll (params, options) {
  return requestAjax({
    url: '/api-user/sys/operational/guide/listAll',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据机构id获取下层分公司列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationListBranchByCompanyIdAndBranchId (params, options) {
  return requestAjax({
    url: '/api-user/organization/listBranchByCompanyIdAndBranchId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目所属分公司-搜索
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationListBranchByCompanyIdAndBranchId (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/listBranchByCompanyIdAndBranchId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据公司ID、当前分公司ID、用户ID获取有权限的机构ID
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationListBranchCompanyByCompanyIdAndUserId (params, options) {
  return requestAjax({
    url: '/api-user/organization/listBranchCompanyByCompanyIdAndUserId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据公司ID、当前分公司ID、用户ID获取有权限的机构ID
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationListBranchIdByCompanyIdAndUserId (params, options) {
  return requestAjax({
    url: '/api-user/organization/listBranchIdByCompanyIdAndUserId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserOrganizationListByBranchIds (data, options) {
  return requestAjax({
    url: '/api-user/organization/listByBranchIds',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 查询项目、下面的安全员-不分页
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectListByCompanyProjectDiary (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/listByCompanyProjectDiary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过项目ID查询指定workerId工人信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerListByMobileWokrerNameAndWorkerIds (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/listByMobileWokrerNameAndWorkerIds',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2.0机构层级-人员管理-新申请-成员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationListByPendingUser (params, options) {
  return requestAjax({
    url: '/api-user/organization/listByPendingUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据项目id获取工人档案
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsersListByProjectIdProjectId (params, options) {
  const { projectId } = params
  return requestAjax({
    url: `/api-user/users/listByProjectId/${projectId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectListByProjectIds (data, options) {
  return requestAjax({
    url: '/api-user/companyproject/listByProjectIds',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 获取用户拥有某个项目角色的所有项目
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectListByRoleCodeAndUserId (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/listByRoleCodeAndUserId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1组织架构-列表-未分配部门列表人数20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationListCompanyAndNotAllocatedUser (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/listCompanyAndNotAllocatedUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.0公司系统设置-组织架构管理-成员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationListCompanyAndOrgByUser (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/listCompanyAndOrgByUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1组织架构-列表-部门列表人数20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationListCompanyAndOrgByUser (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/listCompanyAndOrgByUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.1邀请成员-新成员申请-通过-已拒绝
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationListCompanyByPendingUser (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/listCompanyByPendingUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6.2公司系统设置-组织架构管理-成员申请-新申请-成员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationListCompanyByPendingUser (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/listCompanyByPendingUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1组织架构--新申请成员列表20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationListCompanyByPendingUser (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/listCompanyByPendingUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.2人员权限-获取公司角色
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1RoleUserListCompanyByRole (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/role/user/listCompanyByRole',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6.2添加成员-人员列表(整个集团)
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationListCompanyByUser (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/listCompanyByUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.0机构层级-人员管理-人员通讯录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationListCompanyIdAndOrgAllByUser (params, options) {
  return requestAjax({
    url: '/api-user/organization/listCompanyIdAndOrgAllByUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4.1企业后台-成员管理
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkbenchListCompanyIdAndOrgAllByUserWithType (params, options) {
  return requestAjax({
    url: '/api-user/api/workbench/listCompanyIdAndOrgAllByUserWithType',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.0企业后台-成员管理
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationListCompanyIdAndOrgAllByUserWithType (params, options) {
  return requestAjax({
    url: '/api-user/organization/listCompanyIdAndOrgAllByUserWithType',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.0机构层级-人员管理-部门成员
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationListCompanyIdAndOrgByUser (params, options) {
  return requestAjax({
    url: '/api-user/organization/listCompanyIdAndOrgByUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司角色管理-成员管理
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationCompanyBranchRolesUser (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/companyBranchRolesUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司角色管理-成员管理
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationCompanyBranchRolesUser (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/companyBranchRolesUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4.0机构层级-角色管理-成员管理
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserRolesCompanyRolesUser (params, options) {
  return requestAjax({
    url: '/api-user/roles/companyRolesUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据公司ID、当前分公司ID、用户ID获取有权限的机构ID-条件多选
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserOrganizationListCompanyOrganizationUserByCompanyIdAndUserIdMultipleChoices (data, options) {
  return requestAjax({
    url: '/api-user/organization/listCompanyOrganizationUserByCompanyIdAndUserIdMultipleChoices',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 根据公司ID、当前分公司ID、用户ID获取有权限的机构ID
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationListCompanyOrganizationUserByCompanyIdAndUserId (params, options) {
  return requestAjax({
    url: '/api-user/organization/listCompanyOrganizationUserByCompanyIdAndUserId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过项目ID集合-并搜索条件过滤
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectListCompanyProjectByAll (data, options) {
  return requestAjax({
    url: '/api-user/companyproject/listCompanyProjectByAll',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 通过公司ID，多个机构ID查询直营项目
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectListCompanyProjectByCompanyIdAndBranchIds (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/listCompanyProjectByCompanyIdAndBranchIds',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询公司项目
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectListCompanyProjectByUserRole (data, options) {
  return requestAjax({
    url: '/api-user/companyproject/listCompanyProjectByUserRole',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 4.2项目人员配置-人员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationListCompanyProjectByUser (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/listCompanyProjectByUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.1项目人员配置-人员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationListCompanyProjectByUser (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/listCompanyProjectByUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.3编辑人员-人员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1RoleUserListCompanyRoleByUser (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/role/user/listCompanyRoleByUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取审批职位等级列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysApproveTemplateFlowListLevelLayersByCompanyIdAndLevel (params, options) {
  return requestAjax({
    url: '/api-user/sys/approve/template/flow/listLevelLayersByCompanyIdAndLevel',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过分公司ID查询项目信息-返回当前人对应的菜单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyListMenuByBranchId (params, options) {
  return requestAjax({
    url: '/api-user/company/listMenuByBranchId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 通过项目ID查询项目信息-返回当前人对应的菜单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyListMenuByProjectId (params, options) {
  return requestAjax({
    url: '/api-user/company/listMenuByProjectId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description openUserId转换userId
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ThirdPartyUserListOpenUserIdToUserId (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyUser/listOpenUserIdToUserId',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 查询审批节点应用范围
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2ApproveFlowSettingGetApproveScopeLevelList (data, options) {
  return requestAjax({
    url: '/api-user/v2/approveFlowSetting/getApproveScopeLevelList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 总后台角色库-角色查看已配置权限
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyLibraryBackstageListPermissionByCompanyLibraryId (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/library/backstageListPermissionByCompanyLibraryId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 角色查看已配置权限
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysRolePermissionListPermissionByRoleId (params, options) {
  return requestAjax({
    url: '/api-user/sys/role/permission/listPermissionByRoleId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据项目ids查询公司项目表
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectListPermissionedProject (data, options) {
  return requestAjax({
    url: '/api-user/companyproject/listPermissionedProject',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.2公司系统设置-组织架构管理-编辑人员信息-项目列表列表
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV1OrganizationListProjectByCompanyIdAndBranchIds (data, options) {
  return requestAjax({
    url: '/api-user/v1/organization/listProjectByCompanyIdAndBranchIds',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.6.1-项目列表列表20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationListProjectByCompanyId (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/listProjectByCompanyId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.2编辑人员信息-项目列表列表20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationListProjectByCompanyId (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/listProjectByCompanyId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据项目编号查询项目名称
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectListProjectByMessage (data, options) {
  return requestAjax({
    url: '/api-user/companyproject/listProjectByMessage',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 根据projectId、roleCode查询人员
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectListProjectByUserNickname (data, options) {
  return requestAjax({
    url: '/api-user/companyproject/listProjectByUserNickname',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 本部-通过公司ID、机构ID查询本部项目ID
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectListProjectIdByCompanyIdAndBranchId (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/listProjectIdByCompanyIdAndBranchId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据用户ID获取所有项目ID权限
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectListProjectIdByCompanyIdAndUserId (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/listProjectIdByCompanyIdAndUserId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.2人员权限
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1RoleUserListRoleByCompanyId (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/role/user/listRoleByCompanyId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.3编辑人员-人员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1RoleUserListUserByRoleId (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/role/user/listUserByRoleId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description listUserByUserIds
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsersListUserByUserIds (params, options) {
  return requestAjax({
    url: '/api-user/users/listUserByUserIds',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description userId转换openId返回Map
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ThirdPartyUserListUserIdToOpenUserIdMap (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyUser/listUserIdToOpenUserIdMap',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description userId转换openId
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ThirdPartyUserListUserIdToOpenUserId (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyUser/listUserIdToOpenUserId',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3_功能-02选择所属项目
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageCompanyProjectList (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/companyProjectList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApprovejobList (params, options) {
  return requestAjax({
    url: '/api-user/approvejob/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysoperateList (params, options) {
  return requestAjax({
    url: '/api-user/sysoperate/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表-分页
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysOperationalGuideList (params, options) {
  return requestAjax({
    url: '/api-user/sys/operational/guide/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询订单列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysOrderList (params, options) {
  return requestAjax({
    url: '/api-user/sys/order/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationList (params, options) {
  return requestAjax({
    url: '/api-user/organization/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 权限列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysPermissionList (params, options) {
  return requestAjax({
    url: '/api-user/sys/permission/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskFileList (params, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/file/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskInfoList (params, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/info/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsercompanyList (params, options) {
  return requestAjax({
    url: '/api-user/usercompany/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUserorgList (params, options) {
  return requestAjax({
    url: '/api-user/userorg/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCityweatherList (params, options) {
  return requestAjax({
    url: '/api-user/sys/cityweather/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserWechatList (params, options) {
  return requestAjax({
    url: '/api-user/wechat/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserEpidemicPreventionInfoList (params, options) {
  return requestAjax({
    url: '/api-user/epidemic/prevention/info/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 奖捐台账列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserWorkerAwardDonateList (params, options) {
  return requestAjax({
    url: '/api-user/workerAwardDonate/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 有关证书列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserWrkerrelatedCertificateList (params, options) {
  return requestAjax({
    url: '/api-user/wrkerrelatedCertificate/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserWorkerTrainingList (params, options) {
  return requestAjax({
    url: '/api-user/workerTraining/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyApiDetailsList (params, options) {
  return requestAjax({
    url: '/api-user/company/api/details/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyApiTotalList (params, options) {
  return requestAjax({
    url: '/api-user/company/api/total/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSyscompanycomborenewalList (params, options) {
  return requestAjax({
    url: '/api-user/syscompanycomborenewal/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyList (params, options) {
  return requestAjax({
    url: '/api-user/company/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 11.0公司机构角色库列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyLibraryList (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/library/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysConfigList (params, options) {
  return requestAjax({
    url: '/api-user/sys/config/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysMethodList (params, options) {
  return requestAjax({
    url: '/api-user/sys/method/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人年龄每日02点00分推送预警和报警
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerListWarningAndCallPoliceWorkerAge (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/listWarningAndCallPoliceWorkerAge',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人档案
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerListWorkersInfo (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/listWorkersInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 小程序根据code，登录
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserWechatLogin (data, options) {
  return requestAjax({
    url: '/api-user/wechat/login',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 日历-标记-管理员
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyProjectDiaryManager (params, options) {
  return requestAjax({
    url: '/api-user/company/project/diary/manager',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 5_我的
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageMyCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/api/manage/my/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3_我的
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerMyWorkerId (params, options) {
  const { workerId } = params
  return requestAjax({
    url: `/api-user/api/worker/my/${workerId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 我的
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiDiaryMyUsers (params, options) {
  return requestAjax({
    url: '/api-user/api/diary/myUsers',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4_消息_已处理列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerNewCompleteList (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/newCompleteList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4_消息_抄送我
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerNewCopiedList (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/newCopiedList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4_消息_待处理列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerNewPendingList (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/newPendingList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_2_4-新成员-01-新工人
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageNewWorkerUserInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/newWorkerUserInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_1_2工人班组-待审核工人列表-01-新工人
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerNewWorkerUserInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/newWorkerUserInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询工人正常列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerUserNormallist (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/user/normallist',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 催办 (通知审批人进行审批)
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ApproveFlowNotifyCurrentApproveMember (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlow/notifyCurrentApproveMember',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 催办 (通知审批人进行审批)
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysApproveFlowNotifyCurrentApproveMember (params, options) {
  return requestAjax({
    url: '/api-user/sysApproveFlow/notifyCurrentApproveMember',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 催办 (通知审批人进行审批)
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2SysApproveFlowNotifyCurrentApproveMember (params, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/notifyCurrentApproveMember',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description oa通过token查询用户相关信息V1
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageOaCompanyUserInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/oaCompanyUserInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 开通订单（临时使用）
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsOkorderchannel (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/ok_order_channel',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 开通订单（临时使用）
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsTestOkorderchannel (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/test/ok_order_channel',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 开通BJ_395
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsOpenbj395channelbyprojectid (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/open_bj_395_channel_by_project_id',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 开通BJ_395
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsTestOpenbj395channelbyprojectid (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/test/open_bj_395_channel_by_project_id',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 每天定时生成昨天公司用户统计数据
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserJobOperateData (params, options) {
  return requestAjax({
    url: '/api-user/job/operateData',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑项目申请授权订单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserYzwOptplatformBind (params, options) {
  return requestAjax({
    url: '/api-user/yzw/optplatformBind',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑项目通过key激活云筑
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserYzwOptplatformOkOrders (params, options) {
  return requestAjax({
    url: '/api-user/yzw/optplatformOkOrders',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 下订单-版本购买
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrderComboOrder (data, options) {
  return requestAjax({
    url: '/api-user/api/order/comboOrder',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 下订单-版本购买
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysOrderOrder (data, options) {
  return requestAjax({
    url: '/api-user/sys/order/order',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 下订单-版本购买及创建企业
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysOrderOrderWithCompany (data, options) {
  return requestAjax({
    url: '/api-user/sys/order/orderWithCompany',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3.1项目人员配置-机构列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationOrganizationBranchList (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/organizationBranchList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据companyId、orgId来获取本机部门或机构
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationCompanyOrganizationDepartmentList (params, options) {
  return requestAjax({
    url: '/api-user/organization/company/organizationDepartmentList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1组织架构-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationOrganizationList (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/organizationList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.0机构层级-部门管理-根据companyId、orgId来获取组织架构
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationCompanyOrganizationList (params, options) {
  return requestAjax({
    url: '/api-user/organization/company/organizationList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.4公司系统设置-组织架构管理-编辑人员信息-选择部门及角色-组织架构列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationOrganizationList (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/organizationList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.2编辑人员信息-选择部门及角色-组织架构列表20221116-查看组织结构图
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationOrganizationList (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/organizationList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1组织架构-列表-搜索
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationOrganizationQuery (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/organizationQuery',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.0机构层级-部门管理-根据companyId、orgId来获取组织架构-总人数
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationCompanyOrganizationTotal (params, options) {
  return requestAjax({
    url: '/api-user/organization/company/organizationTotal',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.0公司系统设置-组织架构管理列-列表-总人数
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationCompanyOrganizationTotal (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/company/organizationTotal',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.1组织架构-列表-总人数20221116
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationCompanyOrganizationTotal (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/company/organizationTotal',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.0机构层级-部门管理-当前用户根据companyId来获取机构
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationCompanyOrganizationUser (params, options) {
  return requestAjax({
    url: '/api-user/organization/company/organizationUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_2_2班组-06工人详情 – 退场
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiManageOutTimeWorkerId (data, options) {
  const { workerId } = options.params
  return requestAjax({
    url: `/api-user/api/manage/outTime/${workerId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2_1_7工人详情 – 退场确认
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkerOutTimeWorkerId (data, options) {
  const { workerId } = options.params
  return requestAjax({
    url: `/api-user/api/worker/outTime/${workerId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 退场
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerOutTimeId (data, options) {
  const { id } = options.params
  return requestAjax({
    url: `/api-user/sysworker/outTime/${id}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 超时自动审批通过
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysApproveFlowOvertimeAutoApprove (params, options) {
  return requestAjax({
    url: '/api-user/sysApproveFlow/overtimeAutoApprove',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4_消息_我的代办总数
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerPendingJob (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/pendingJob',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4_消息_待处理列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerPendingList (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/pendingList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 权限列表-不分页
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysPermissionPermissionList (params, options) {
  return requestAjax({
    url: '/api-user/sys/permission/permissionList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 创建渠道订单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsPlaceorderchannel (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/place_order_channel',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 创建渠道订单
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsTestPlaceorderchannel (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/test/place_order_channel',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 预览订单-版本增购名额
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrderPreComboNumberOrder (params, options) {
  return requestAjax({
    url: '/api-user/api/order/preComboNumberOrder',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 预览订单-版本增购名额
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysOrderPreComboNumberOrder (params, options) {
  return requestAjax({
    url: '/api-user/sys/order/preComboNumberOrder',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 预览订单-版本续费V2
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrderPreComboRenewalOrderV2 (params, options) {
  return requestAjax({
    url: '/api-user/api/order/preComboRenewalOrderV2',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 预览订单-版本续费V2
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysOrderPreComboRenewalOrderV2 (params, options) {
  return requestAjax({
    url: '/api-user/sys/order/preComboRenewalOrderV2',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 预览订单-版本升级
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrderPreComboUpgradeOrder (params, options) {
  return requestAjax({
    url: '/api-user/api/order/preComboUpgradeOrder',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 预览订单-版本升级
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysOrderPreComboUpgradeOrder (params, options) {
  return requestAjax({
    url: '/api-user/sys/order/preComboUpgradeOrder',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 预览订单-版本购买
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrderPreOrder (data, options) {
  return requestAjax({
    url: '/api-user/api/order/preOrder',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 预览订单-版本购买
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysOrderPreOrder (data, options) {
  return requestAjax({
    url: '/api-user/sys/order/preOrder',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 预览订单-版本购买及创建企业
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysOrderPreOrderWithCompany (data, options) {
  return requestAjax({
    url: '/api-user/sys/order/preOrderWithCompany',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 33.考勤统计-筛选条件-项目地区
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerProjectArea (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/projectArea',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 下订单-考勤付费-按项目付费-增购名额
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrderProjectAttnNumberOrder (data, options) {
  return requestAjax({
    url: '/api-user/api/order/projectAttnNumberOrder',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 下订单-考勤付费-按项目付费
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrderProjectAttnOrder (data, options) {
  return requestAjax({
    url: '/api-user/api/order/projectAttnOrder',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2_2项目详情-头部
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageProjectDetailsHeadCompanyIdProjectId (params, options) {
  const { companyId, projectId } = params
  return requestAjax({
    url: `/api-user/api/manage/projectDetailsHead/${companyId}/${projectId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 下单-购买电子合同次数
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ProjectEssOrderProjectEssOrder (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/project/essOrder/projectEssOrder',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 消耗项目电子合同次数(带明细生成)
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ProjectEssOrderOperateProjectEssCountWithDetail (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/project/essOrder/operateProjectEssCountWithDetail',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 获取劳务管理员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectProjectLabour (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/projectLabour',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人管理-项目
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2CompanyProjectList (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/company/projectList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_2项目（8/24新增）,只有登陆projectType=1查看自己的项目
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageProjectListCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/api/manage/projectList/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ProjectProjectList (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/project/projectList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_2项目（8/21新增）,只有登陆projectType=1查看自己的项目
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerProjectListCompanyId (params, options) {
  const { companyId } = params
  return requestAjax({
    url: `/api-user/api/worker/projectList/${companyId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司端-项目管理-项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageProjectListV1 (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/projectListV1',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 管理人员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiQualityProjectManagerList (params, options) {
  return requestAjax({
    url: '/api-user/api/quality/projectManagerList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 管理人员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiSecurityProjectManagerList (params, options) {
  return requestAjax({
    url: '/api-user/api/security/projectManagerList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 管理人员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiTaskProjectManagerList (params, options) {
  return requestAjax({
    url: '/api-user/api/task/projectManagerList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 0.1管理人员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysBranchTaskProjectManagerList (params, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/projectManagerList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 0.1管理人员列表-管理人员
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyTaskProjectManagerList (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/projectManagerList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 0.1管理人员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskProjectManagerList (params, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/projectManagerList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取项目经理列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectProjectManager (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/projectManager',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 4.2公司系统设置-项目人员配置-职位权限-角色列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationProjectRoles (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/projectRoles',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3-5-3任务完成-明细
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiTaskProjectTaskCompleteByTaskInfoId (params, options) {
  return requestAjax({
    url: '/api-user/api/task/projectTaskCompleteByTaskInfoId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3-4-4-1-任务详情-完成-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiTaskProjectTaskCompleteList (params, options) {
  return requestAjax({
    url: '/api-user/api/task/projectTaskCompleteList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.5任务详情-完成记录-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysBranchTaskProjectTaskCompleteList (params, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/projectTaskCompleteList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.5任务详情-完成记录-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyTaskProjectTaskCompleteList (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/projectTaskCompleteList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.5任务详情-完成记录-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskProjectTaskCompleteList (params, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/projectTaskCompleteList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3-5-3任务完成-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiTaskProjectTaskCompleteSave (data, options) {
  return requestAjax({
    url: '/api-user/api/task/projectTaskCompleteSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.2任务完成-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysBranchTaskProjectTaskCompleteSave (data, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/projectTaskCompleteSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.2任务完成-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysCompanyTaskProjectTaskCompleteSave (data, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/projectTaskCompleteSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.2任务完成-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysProjectTaskProjectTaskCompleteSave (data, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/projectTaskCompleteSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3-5-2任务延期-明细
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiTaskProjectTaskDelayByTaskInfoId (params, options) {
  return requestAjax({
    url: '/api-user/api/task/projectTaskDelayByTaskInfoId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3-4-3-任务详情-延期-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiTaskProjectTaskDelayList (params, options) {
  return requestAjax({
    url: '/api-user/api/task/projectTaskDelayList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.5任务详情-延期记录-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysBranchTaskProjectTaskDelayList (params, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/projectTaskDelayList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.5任务详情-延期记录-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyTaskProjectTaskDelayList (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/projectTaskDelayList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.5任务详情-延期记录-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskProjectTaskDelayList (params, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/projectTaskDelayList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3-5-2任务延期-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiTaskProjectTaskDelaySave (data, options) {
  return requestAjax({
    url: '/api-user/api/task/projectTaskDelaySave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.2任务延期-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysBranchTaskProjectTaskDelaySave (data, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/projectTaskDelaySave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.2任务延期-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysCompanyTaskProjectTaskDelaySave (data, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/projectTaskDelaySave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.2任务延期-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysProjectTaskProjectTaskDelaySave (data, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/projectTaskDelaySave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3-5-1任务更新-明细/新增获取最新更新时间
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiTaskProjectTaskUpdateByTaskInfoId (params, options) {
  return requestAjax({
    url: '/api-user/api/task/projectTaskUpdateByTaskInfoId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3-4-2-任务详情-更新-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiTaskProjectTaskUpdateList (params, options) {
  return requestAjax({
    url: '/api-user/api/task/projectTaskUpdateList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.5任务详情-更新记录-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysBranchTaskProjectTaskUpdateList (params, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/projectTaskUpdateList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.5任务详情-更新记录-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysCompanyTaskProjectTaskUpdateList (params, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/projectTaskUpdateList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.5任务详情-更新记录-列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysProjectTaskProjectTaskUpdateList (params, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/projectTaskUpdateList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3-5-1任务更新-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiTaskProjectTaskUpdateSave (data, options) {
  return requestAjax({
    url: '/api-user/api/task/projectTaskUpdateSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.2任务更新-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysBranchTaskProjectTaskUpdateSave (data, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/projectTaskUpdateSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.2任务更新-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysCompanyTaskProjectTaskUpdateSave (data, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/projectTaskUpdateSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.2任务更新-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysProjectTaskProjectTaskUpdateSave (data, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/projectTaskUpdateSave',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 项目（新增/编辑）
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsProject (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/project',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目（新增/编辑）
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsTestProject (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/test/project',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_2_1人员管理
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageProjectWorkerCompanyIdProjectId (params, options) {
  const { companyId, projectId } = params
  return requestAjax({
    url: `/api-user/api/manage/projectWorker/${companyId}/${projectId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 33.班组考勤统计-筛选条件-籍贯
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerProvince (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/province',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 推送消息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysMsgPushMsg (data, options) {
  return requestAjax({
    url: '/api-user/sys/msg/pushMsg',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 激活后推送云筑平台
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserYzwPushYzw (params, options) {
  return requestAjax({
    url: '/api-user/yzw/pushYzw',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 质量隐患
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiProjectBoardQualityStatistical (params, options) {
  return requestAjax({
    url: '/api-user/api/project/board/qualityStatistical',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 质量隐患
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserProjectBoardQualityStatistical (params, options) {
  return requestAjax({
    url: '/api-user/project/board/qualityStatistical',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description (最新)企业微信-小程序通过code登录
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserLoginQyWechatLoginAppidCode (data, options) {
  const { appid, code } = options.params
  return requestAjax({
    url: `/api-user/login/qyWechatLogin/${appid}/${code}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description (最新)企业微信-小程序登录通过code,UserPhone登录
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserLoginQyWechatLogin (data, options) {
  return requestAjax({
    url: '/api-user/login/qyWechatLogin',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 企业微信端H5登录(上架应用市场)-通过state,code登录
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserLoginQyWeiXinH5LoginStateCode (data, options) {
  const { code, state } = options.params
  return requestAjax({
    url: `/api-user/login/qyWeiXinH5Login/${state}/${code}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 企业微信端小程序登录(上架应用市场)-通过appid,qwCode,code登录
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserLoginQyWeiXinLoginAppidQwCodeCode (data, options) {
  const { appid, code, qwCode } = options.params
  return requestAjax({
    url: `/api-user/login/qyWeiXinLogin/${appid}/${qwCode}/${code}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 企业微信端PC登录(上架应用市场)-通过state,authCode登录
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserLoginQyWeiXinPcLoginStateAuthCode (data, options) {
  const { authCode, state } = options.params
  return requestAjax({
    url: `/api-user/login/qyWeiXinPcLogin/${state}/${authCode}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 头部实时统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOperatesRealTimeData (params, options) {
  return requestAjax({
    url: '/api-user/api/operates/realTimeData',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6.领用详情-领用信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerRecipientsList (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/recipientsList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 疫情防控-健康码识别
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageRecognizeHealthCodeOCR (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/recognizeHealthCodeOCR',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 疫情防控-健康码识别
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1WorkerRecognizeHealthCodeOCR (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/worker/recognizeHealthCodeOCR',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 疫情防控-行程码识别
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageRecognizeTravelCodeOCR (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/recognizeTravelCodeOCR',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 疫情防控-行程码识别
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1WorkerRecognizeTravelCodeOCR (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/worker/recognizeTravelCodeOCR',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.5拒绝成员加入-批量
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrganizationRefuseUserApply (data, options) {
  return requestAjax({
    url: '/api-user/api/organization/refuseUserApply',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6.2公司系统设置-组织架构管理-成员申请-批量拒绝
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV1OrganizationRefuseUserApply (data, options) {
  return requestAjax({
    url: '/api-user/v1/organization/refuseUserApply',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.1组织架构--新申请成员-批量拒绝20221116
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationRefuseUserApply (data, options) {
  return requestAjax({
    url: '/api-user/v2/organization/refuseUserApply',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 公司信息注册
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserLoginRegisterCompany (data, options) {
  return requestAjax({
    url: '/api-user/login/registerCompany',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 8.工人证书【6.工人详情-证书记录】-汇总
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerRelatedCertificateSummary (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/relatedCertificateSummary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 重新推送企业，更换企业管理员
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiQwReplaceCompanyUser (data, options) {
  return requestAjax({
    url: '/api-user/api/qw/replaceCompanyUser',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 企微更换管理员
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ThirdPartyUserReplaceUserAdmin (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyUser/replaceUserAdmin',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 重置还未未审批的人员配置
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ApproveFlowResetApproveHandlerSysApproveJobId (data, options) {
  const { sysApproveJobId } = options.params
  return requestAjax({
    url: `/api-user/api/v1/approveFlow/resetApproveHandler/${sysApproveJobId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 重置还未未审批的人员配置
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysApproveFlowResetApproveHandlerSysApproveJobId (data, options) {
  const { sysApproveJobId } = options.params
  return requestAjax({
    url: `/api-user/sysApproveFlow/resetApproveHandler/${sysApproveJobId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 给用户重置密码
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserUsersIdPassword (data, options) {
  const { id } = options.params
  return requestAjax({
    url: `/api-user/users/${id}/password`,
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description 2_2_2班组-07工人详情 – 休息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiManageRestWorkerId (data, options) {
  const { workerId } = options.params
  return requestAjax({
    url: `/api-user/api/manage/rest/${workerId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2_1_6工人详情 – 休息确认
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkerRestWorkerId (data, options) {
  const { workerId } = options.params
  return requestAjax({
    url: `/api-user/api/worker/rest/${workerId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 党员突击队-撤销突击队
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApiuserPartyPartyCommandoRevokeCommando (params, options) {
  return requestAjax({
    url: '/api-user/party/partyCommando/revokeCommando',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 撤销
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2SysApproveFlowRevokeToDrafted (params, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/revokeToDrafted',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 撤回
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2SysApproveFlowRevokeToPreviousStep (params, options) {
  return requestAjax({
    url: '/api-user/v2/sysApproveFlow/revokeToPreviousStep',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目右侧列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectRightList (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/rightList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 层级切换-有权限项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageRightListV1 (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/rightListV1',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目右侧列表-获取当前机构层级下有权限项目列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectRightListV1 (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/rightListV1',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_3人员管理风险预警-风险预警
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageRiskWarning (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/riskWarning',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_4风险预警
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerRiskWarning (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/riskWarning',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑推送工人考勤
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserYzwRnCreate (data, options) {
  return requestAjax({
    url: '/api-user/yzw/rnCreate',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 公司角色列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOaRoleList (params, options) {
  return requestAjax({
    url: '/api-user/api/oa/roleList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司角色用户列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOaRoleUserList (params, options) {
  return requestAjax({
    url: '/api-user/api/oa/roleUserList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目成员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ProjectRoleUserList (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/project/roleUserList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目角色列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyProjectDiaryRolesList (params, options) {
  return requestAjax({
    url: '/api-user/company/project/diary/rolesList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.2批量通过成员申请
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrganizationSaveApproveCompanyUserInfo (data, options) {
  return requestAjax({
    url: '/api-user/api/organization/saveApproveCompanyUserInfo',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6.2公司系统设置-组织架构管理-成员申请-同意保存人员信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV1OrganizationSaveApproveCompanyUserInfo (data, options) {
  return requestAjax({
    url: '/api-user/v1/organization/saveApproveCompanyUserInfo',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.1组织架构--新申请成员-同意保存人员信息20221116
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationSaveApproveCompanyUserInfo (data, options) {
  return requestAjax({
    url: '/api-user/v2/organization/saveApproveCompanyUserInfo',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 7.录入/编辑奖捐信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV2WorkerSaveAwardDonate (data, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/saveAwardDonate',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6.2添加成员(批量)-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationSaveBathCompanyOrgUser (data, options) {
  return requestAjax({
    url: '/api-user/v2/organization/saveBathCompanyOrgUser',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 4.6添加人员-批量(新增)保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrganizationSaveBathUserProjectRole (data, options) {
  return requestAjax({
    url: '/api-user/api/organization/saveBathUserProjectRole',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3.1项目人员配置-添加人员-批量(新增)保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationSaveBathUserProjectRole (data, options) {
  return requestAjax({
    url: '/api-user/v2/organization/saveBathUserProjectRole',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2.2版本管理 - 新增/编辑版本
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysProductComboSave (data, options) {
  return requestAjax({
    url: '/api-user/sys/product/combo/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2.3版本管理 - 配置
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserSysProductComboSaveRoleId (data, options) {
  const { id } = options.params
  return requestAjax({
    url: `/api-user/sys/product/combo/saveRole/${id}`,
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description 3.7公司系统设置-职位权限配置-新增公司职位-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV1OrganizationSaveCompanyBranchLibaryPermission (data, options) {
  return requestAjax({
    url: '/api-user/v1/organization/saveCompanyBranchLibaryPermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6.3编辑个性化公司权限-保存(新增-管理员-权限一起保存)
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrganizationSaveCompanyBranchPermission (data, options) {
  return requestAjax({
    url: '/api-user/api/organization/saveCompanyBranchPermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2.0公司系统设置-公司权限管理-保存公司、项目权限
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV1OrganizationSaveCompanyBranchPermission (data, options) {
  return requestAjax({
    url: '/api-user/v1/organization/saveCompanyBranchPermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 5.1个性化管理-设置-保存个性化公司权限*20221116
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationSaveCompanyBranchPermission (data, options) {
  return requestAjax({
    url: '/api-user/v2/organization/saveCompanyBranchPermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3.1公司职位权限编辑-保存20221116
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrganizationSaveCompanyBranchRolesPermission (data, options) {
  return requestAjax({
    url: '/api-user/api/organization/saveCompanyBranchRolesPermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2.1角色权限管理-公司职位权限编辑-保存20221116
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationSaveCompanyBranchRolesPermission (data, options) {
  return requestAjax({
    url: '/api-user/v2/organization/saveCompanyBranchRolesPermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3.1公司系统设置-职位权限配置-公司职位权限编辑-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV1OrganizationSaveCompanyBranchRolesPermission (data, options) {
  return requestAjax({
    url: '/api-user/v1/organization/saveCompanyBranchRolesPermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.1添加成员列表-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrganizationSaveCompanyOrgUser (data, options) {
  return requestAjax({
    url: '/api-user/api/organization/saveCompanyOrgUser',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3.8公司系统设置-职位权限配置-新增项目职位-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV1OrganizationSaveCompanyProjectLibaryPermission (data, options) {
  return requestAjax({
    url: '/api-user/v1/organization/saveCompanyProjectLibaryPermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3.2公司系统设置-职位权限配置-项目职位权限编辑-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV1OrganizationSaveCompanyProjectRolesPermission (data, options) {
  return requestAjax({
    url: '/api-user/v1/organization/saveCompanyProjectRolesPermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 4.3新增项目-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ProjectSaveCompanyProject (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/project/saveCompanyProject',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2.4编辑人员-保存人员
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1RoleUserSaveCompanyRoleUser (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/role/user/saveCompanyRoleUser',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2.4编辑人员-保存人员-v1(保存之前先删除)
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1RoleUserV1SaveCompanyRoleUser (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/role/user/v1/saveCompanyRoleUser',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2.4申请人员-审批通过
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1RoleUserSaveCompanyUserApply (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/role/user/saveCompanyUserApply',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.4-保存人员信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrganizationSaveCompanyUserInfo (data, options) {
  return requestAjax({
    url: '/api-user/api/organization/saveCompanyUserInfo',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.1公司系统设置-组织架构管理-保存人员信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV1OrganizationSaveCompanyUserInfo (data, options) {
  return requestAjax({
    url: '/api-user/v1/organization/saveCompanyUserInfo',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.1组织架构-保存人员信息20221116
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationSaveCompanyUserInfo (data, options) {
  return requestAjax({
    url: '/api-user/v2/organization/saveCompanyUserInfo',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 5.2新增自定义公司20221116
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationSaveCustomOrganization (data, options) {
  return requestAjax({
    url: '/api-user/v2/organization/saveCustomOrganization',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 批量保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApprovejobSaveList (data, options) {
  return requestAjax({
    url: '/api-user/approvejob/saveList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存新增接口
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysMethodSaveMethod (data, options) {
  return requestAjax({
    url: '/api-user/sys/method/saveMethod',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 防疫信息保存or更新
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ManageSaveOrUpdateEpidemicPrevention (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/saveOrUpdateEpidemicPrevention',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 防疫信息保存or更新
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1WorkerSaveOrUpdateEpidemicPrevention (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/worker/saveOrUpdateEpidemicPrevention',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6.上传体检报告
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV2WorkerSaveOrUpdateExaminationReport (data, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/saveOrUpdateExaminationReport',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2.1角色权限管理--新增修改角色
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationSaveOrUpdateRole (data, options) {
  return requestAjax({
    url: '/api-user/v2/organization/saveOrUpdateRole',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 新增菜单
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserMenusSaveOrUpdate (data, options) {
  return requestAjax({
    url: '/api-user/menus/saveOrUpdate',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 机构层级-角色管理-新增角色
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserRolesSaveOrUpdate (data, options) {
  return requestAjax({
    url: '/api-user/roles/saveOrUpdate',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3.3机构层级-菜单管理-高级设置-给下属机构更换菜单模板（角色）、设置权限应用范围
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserOrganizationSaveOrgTemplateBranchId (data, options) {
  const { branchId } = options.params
  return requestAjax({
    url: `/api-user/organization/saveOrgTemplate/${branchId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 机构层级-菜单管理-高级设置
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserOrganizationSaveOrganizationRoleBranchId (data, options) {
  const { branchId } = options.params
  return requestAjax({
    url: `/api-user/organization/saveOrganizationRole/${branchId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 权限保存or新增
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysPermissionSavePermission (data, options) {
  return requestAjax({
    url: '/api-user/sys/permission/savePermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3.1项目职位权限编辑-保存20221116
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrganizationSaveProjectRolePermission (data, options) {
  return requestAjax({
    url: '/api-user/api/organization/saveProjectRolePermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 4.3公司系统设置-项目人员配置-职位权限-新增/编辑-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV1OrganizationSaveProjectRolePermission (data, options) {
  return requestAjax({
    url: '/api-user/v1/organization/saveProjectRolePermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2.1角色权限管理-项目职位权限编辑-保存20221116
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationSaveProjectRolePermission (data, options) {
  return requestAjax({
    url: '/api-user/v2/organization/saveProjectRolePermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 8.录入/编辑证书明细
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV2WorkerSaveRelatedCertificate (data, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/saveRelatedCertificate',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 公司人员配置、保存公司人员配置
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanySaveRoleUserCompanyId (data, options) {
  const { companyId } = options.params
  return requestAjax({
    url: `/api-user/company/saveRoleUser/${companyId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 创建周例会发布新任务-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysProjectTaskSaveSecurityWeeklyMeetingTask (data, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/saveSecurityWeeklyMeetingTask',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 11.1公司机构角色库-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysCompanyLibrarySaveSysCompanyLibrary (data, options) {
  return requestAjax({
    url: '/api-user/sys/company/library/saveSysCompanyLibrary',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 小程序-创建新企业
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ManageSaveSysCompany (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/saveSysCompany',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.1个人设置-创建新企业
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanySaveSysCompany (data, options) {
  return requestAjax({
    url: '/api-user/company/saveSysCompany',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysOperationalGuideSaveSysOperationalGuide (data, options) {
  return requestAjax({
    url: '/api-user/sys/operational/guide/saveSysOperationalGuide',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 机构层级-菜单管理-保存菜单模板-新版本：保存角色模板
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserRolesSaveSysRoleTemplateBranchId (data, options) {
  const { branchId } = options.params
  return requestAjax({
    url: `/api-user/roles/saveSysRoleTemplate/${branchId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3-2-2-发布任务-填写-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiTaskSaveTask (data, options) {
  return requestAjax({
    url: '/api-user/api/task/saveTask',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.4发布新任务-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysBranchTaskSaveTask (data, options) {
  return requestAjax({
    url: '/api-user/sys/branch/task/saveTask',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.4发布新任务-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysCompanyTaskSaveTask (data, options) {
  return requestAjax({
    url: '/api-user/sys/company/task/saveTask',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.4发布新任务-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysProjectTaskSaveTask (data, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/saveTask',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 4.1机构层级-角色管理-添加成员
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserRolesSaveUserByRole (data, options) {
  return requestAjax({
    url: '/api-user/roles/saveUserByRole',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存公司相关用户职位，所属分公司职位
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserUsersSaveUserCompanyInfo (data, options) {
  return requestAjax({
    url: '/api-user/users/saveUserCompanyInfo',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2.0机构层级-人员管理-新申请-同意、拒绝
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserOrganizationSaveUserCompany (data, options) {
  return requestAjax({
    url: '/api-user/organization/saveUserCompany',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 4.4项目成员列表-设置权限-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrganizationSaveUserProjectRole (data, options) {
  return requestAjax({
    url: '/api-user/api/organization/saveUserProjectRole',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3.1项目人员配置-选择角色-(单人)保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationSaveUserProjectRole (data, options) {
  return requestAjax({
    url: '/api-user/v2/organization/saveUserProjectRole',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description v1.1-2.2机构层级-人员管理-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserOrganizationCompanySaveUserRoleInfo (data, options) {
  return requestAjax({
    url: '/api-user/organization/company/saveUserRoleInfo',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 5.2公司系统设置-流程编辑
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ApproveFlowSettingSave (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlowSetting/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.2新增or修改部门
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiOrganizationSaveSysOrganization (data, options) {
  return requestAjax({
    url: '/api-user/api/organization/saveSysOrganization',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysoperateSave (data, options) {
  return requestAjax({
    url: '/api-user/sysoperate/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.1机构层级-部门管理-新增部门
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserOrganizationSaveBranchId (data, options) {
  const { branchId } = options.params
  return requestAjax({
    url: `/api-user/organization/save/${branchId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6.0公司系统设置-组织架构管理-新增部门
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV1OrganizationSaveBranchId (data, options) {
  const { branchId } = options.params
  return requestAjax({
    url: `/api-user/v1/organization/save/${branchId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.1组织架构-新增部门
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationSaveBranchId (data, options) {
  const { branchId } = options.params
  return requestAjax({
    url: `/api-user/v2/organization/save/${branchId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysProjectTaskFileSave (data, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/file/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysProjectTaskInfoSave (data, options) {
  return requestAjax({
    url: '/api-user/sys/project/task/info/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存公司管理员扫描申请绑定
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserUsercompanySave (data, options) {
  return requestAjax({
    url: '/api-user/usercompany/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserWechatSave (data, options) {
  return requestAjax({
    url: '/api-user/wechat/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerSave (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 5.2公司系统设置-流程编辑
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2ApproveFlowSettingSave (data, options) {
  return requestAjax({
    url: '/api-user/v2/approveFlowSetting/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserEpidemicPreventionInfoSave (data, options) {
  return requestAjax({
    url: '/api-user/epidemic/prevention/info/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 奖捐台账保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserWorkerAwardDonateSave (data, options) {
  return requestAjax({
    url: '/api-user/workerAwardDonate/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 有关证书保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserWrkerrelatedCertificateSave (data, options) {
  return requestAjax({
    url: '/api-user/wrkerrelatedCertificate/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserWorkerTrainingSave (data, options) {
  return requestAjax({
    url: '/api-user/workerTraining/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApprovejobSave (data, options) {
  return requestAjax({
    url: '/api-user/approvejob/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 5.2公司系统设置-流程编辑
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysApproveTemplateFlowSave (data, options) {
  return requestAjax({
    url: '/api-user/sys/approve/template/flow/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyApiDetailsSave (data, options) {
  return requestAjax({
    url: '/api-user/company/api/details/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyApiTotalSave (data, options) {
  return requestAjax({
    url: '/api-user/company/api/total/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSyscompanycomborenewalSave (data, options) {
  return requestAjax({
    url: '/api-user/syscompanycomborenewal/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectSave (data, options) {
  return requestAjax({
    url: '/api-user/companyproject/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysConfigSave (data, options) {
  return requestAjax({
    url: '/api-user/sys/config/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 批量保存工人所在的班组，班组ID
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerSaveWorkerGroupId (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/saveWorkerGroupId',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6_1_2个人档案-查看详情保存
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerSaveWorkerInfoUser (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/saveWorkerInfoUser',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_4_1-05查看详情保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiManageSaveWorkerInfoUser (data, options) {
  return requestAjax({
    url: '/api-user/api/manage/saveWorkerInfoUser',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存工人的详细信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerSaveWorkerInfo (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/saveWorkerInfo',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 9.录入/编辑培训信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV2WorkerSaveWorkerTraining (data, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/saveWorkerTraining',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 根据用户名查询用户实体
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsersIdId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-user/users/id/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据用户名查询用户实体
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsersNameUsername (params, options) {
  const { username } = params
  return requestAjax({
    url: `/api-user/users/name/${username}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 公司端-项目管理-项目列表-下拉选择
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageSelectProjectListV1 (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/selectProjectListV1',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description selectX
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserJobSelectX (params, options) {
  return requestAjax({
    url: '/api-user/job/selectX',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 发送版本到期提醒
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ThirdPartyAuthSendEditionExpireRemind (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyAuth/sendEditionExpireRemind',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 发送消息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ThirdPartyMsgSendMaLiCloudMsg (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyMsg/sendMaLiCloudMsg',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 发送企微邀请消息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ThirdPartyUserSendQiWeiInvitationMsg (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyUser/sendQiWeiInvitationMsg',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1.0机构层级-部门管理-设置部门为分公司
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserOrganizationSetBranchCompanyBranchIdId (data, options) {
  const { branchId, id } = options.params
  return requestAjax({
    url: `/api-user/organization/setBranchCompany/${branchId}/${id}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6.1公司系统设置-组织架构管理-功能菜单-设置部门为分公司
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV1OrganizationSetBranchCompanyBranchIdId (data, options) {
  const { branchId, id } = options.params
  return requestAjax({
    url: `/api-user/v1/organization/setBranchCompany/${branchId}/${id}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.1组织架构-功能菜单-设置部门为分公司20221116
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationSetBranchCompanyCompanyIdId (data, options) {
  const { companyId, id } = options.params
  return requestAjax({
    url: `/api-user/v2/organization/setBranchCompany/${companyId}/${id}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.0机构层级-部门管理-取消部门为分公司
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserOrganizationSetCancelBranchCompanyBranchIdId (data, options) {
  const { branchId, id } = options.params
  return requestAjax({
    url: `/api-user/organization/setCancelBranchCompany/${branchId}/${id}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6.1公司系统设置-组织架构管理-功能菜单-取消部门为分公司
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV1OrganizationSetCancelBranchCompanyBranchIdId (data, options) {
  const { branchId, id } = options.params
  return requestAjax({
    url: `/api-user/v1/organization/setCancelBranchCompany/${branchId}/${id}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1.1组织架构--功能菜单-取消部门为分公司
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV2OrganizationSetCancelBranchCompanyCompanyIdId (data, options) {
  const { companyId, id } = options.params
  return requestAjax({
    url: `/api-user/v2/organization/setCancelBranchCompany/${companyId}/${id}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 超级管理员后台给公司分配版本
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanySetComboToCompany (data, options) {
  return requestAjax({
    url: '/api-user/company/setComboToCompany',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 12.3机构层级设置-菜单模板-保存角色库
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysCompanyLibrarySetCompanyLibraryToPermission (data, options) {
  return requestAjax({
    url: '/api-user/sys/company/library/setCompanyLibraryToPermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 超级管理员-新增or修改角色复制权限
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserRolesSetCopyPermissionToRole (data, options) {
  return requestAjax({
    url: '/api-user/roles/setCopyPermissionToRole',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 权限分配菜单
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysPermissionSetMenuToPermission (data, options) {
  return requestAjax({
    url: '/api-user/sys/permission/setMenuToPermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 角色分配菜单
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserMenusSetMenuToRole (data, options) {
  return requestAjax({
    url: '/api-user/menus/setMenuToRole',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 权限分配后台接口
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysMethodSetMethodToPermission (data, options) {
  return requestAjax({
    url: '/api-user/sys/method/setMethodToPermission',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 总后台角色库-角色分配权限
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysCompanyLibraryBackstageSetPermissionToCompanyLibrary (data, options) {
  return requestAjax({
    url: '/api-user/sys/company/library/backstageSetPermissionToCompanyLibrary',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description v1.1-3.2机构层级-菜单管理=角色分配权限
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserRolesSetPermissionToRole (data, options) {
  return requestAjax({
    url: '/api-user/roles/setPermissionToRole',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 超级管理员角色分配权限
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysRolePermissionSetPermissionToRole (data, options) {
  return requestAjax({
    url: '/api-user/sys/role/permission/setPermissionToRole',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1_1项目人员配置、保存项目人员配置-后台管理员给项目，角色分配关联用户
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectSaveRoleUserProjectId (data, options) {
  const { projectId } = options.params
  return requestAjax({
    url: `/api-user/companyproject/saveRoleUser/${projectId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 4.1公司系统设置-项目人员配置-配置人员-保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserV1OrganizationSaveRoleUserProjectId (data, options) {
  const { projectId } = options.params
  return requestAjax({
    url: `/api-user/v1/organization/saveRoleUser/${projectId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 超级管理员后台给公司分配角色
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyCompanyIdRoles (data, options) {
  const { companyId } = options.params
  return requestAjax({
    url: `/api-user/company/${companyId}/roles`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description shieldingGroupWorker
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserSysworkerShieldingGroupWorker (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/shieldingGroupWorker',
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description LW-QB-1.11-20230305签补审批-同意-驳回
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkerV2SignRecordApproveJob (data, options) {
  return requestAjax({
    url: '/api-user/api/worker/v2/signRecordApproveJob',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2_2_2班组-07工人详情 – 工人开工
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiManageStartsWorkerId (data, options) {
  const { workerId } = options.params
  return requestAjax({
    url: `/api-user/api/manage/starts/${workerId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2_1_6工人详情 – 工人开工
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkerStartsWorkerId (data, options) {
  const { workerId } = options.params
  return requestAjax({
    url: `/api-user/api/worker/starts/${workerId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description starts
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerStartsWorkerId (data, options) {
  const { workerId } = options.params
  return requestAjax({
    url: `/api-user/sysworker/starts/${workerId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 1_4代打卡，班组在场工人列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerSubuserlist (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/subuserlist',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 代工人端账号申请绑定
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkerSubworkerUserAccount (data, options) {
  return requestAjax({
    url: '/api-user/api/worker/subworkerUserAccount',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 关联发票
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysOrderSyncLinkInvoice (data, options) {
  return requestAjax({
    url: '/api-user/sys/order/syncLinkInvoice',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 任务-超期提醒
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserJobTaskAreReminded (params, options) {
  return requestAjax({
    url: '/api-user/job/taskAreReminded',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 班组绑定工人（创建/编辑）
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsTeambindwork (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/team_bind_work',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 班组绑定工人（创建/编辑）
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsTestTeambindwork (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/test/team_bind_work',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑班组创建-创建供应商绑定，班组绑定创建
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserYzwTeamCreate (params, options) {
  return requestAjax({
    url: '/api-user/yzw/teamCreate',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人进出场（时序）
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsTeamentry (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/team_entry',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人进出场（时序）
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1TsTestTeamentry (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/ts/test/team_entry',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑工人进场、出场
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserYzwTeamEntry (params, options) {
  return requestAjax({
    url: '/api-user/yzw/teamEntry',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑班组列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserYzwTeamList (params, options) {
  return requestAjax({
    url: '/api-user/yzw/teamList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.1机构层级-菜单管理-高级设置-模板列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserRolesTemplateList (params, options) {
  return requestAjax({
    url: '/api-user/roles/templateList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 每周定时同步企业注册、支付
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserJobTimeGenerationCompanyWeekOperate (params, options) {
  return requestAjax({
    url: '/api-user/job/timeGenerationCompanyWeekOperate',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人信息_培训记录统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysWorkerArchivesTrainingStatisticsByWorkerId (params, options) {
  return requestAjax({
    url: '/api-user/sysWorkerArchives/trainingStatisticsByWorkerId',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2.0企业后台-基本信息-编辑
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyUpdateSysCompany (data, options) {
  return requestAjax({
    url: '/api-user/company/updateSysCompany',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description updateApproveJobTaskByModuleAndUserIds
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApprovejobUpdateApproveJobTaskByModuleAndUserIds (data, options) {
  return requestAjax({
    url: '/api-user/approvejob/updateApproveJobTaskByModuleAndUserIds',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description updateApproveJobTaskByModule
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserApprovejobUpdateApproveJobTaskByModule (data, options) {
  return requestAjax({
    url: '/api-user/approvejob/updateApproveJobTaskByModule',
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description updateApproveJobTask
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserApprovejobUpdateApproveJobTask (data, options) {
  return requestAjax({
    url: '/api-user/approvejob/updateApproveJobTask',
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description 1_6_2签补审批-同意-驳回
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkerApproveJob (data, options) {
  return requestAjax({
    url: '/api-user/api/worker/approveJob',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 4.0企业后台-企业基本信息-编辑
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkbenchUpdateBasicEnterpriseInformation (data, options) {
  return requestAjax({
    url: '/api-user/api/workbench/updateBasicEnterpriseInformation',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 修改抄送我的待办已读状态
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserApiV1ApproveFlowUpdateCcMyTodoStatus (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/approveFlow/updateCcMyTodoStatus',
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description 更换版本
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserApiV1ThirdPartyUserUpdateCompanyEdition (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyUser/updateCompanyEdition',
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description 修改用户状态
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserUsersUpdateEnabled (params, options) {
  return requestAjax({
    url: '/api-user/users/updateEnabled',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 5_我的_修改头像
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiManageMyUpdateFaceImg (data, options) {
  return requestAjax({
    url: '/api-user/api/manage/my/updateFaceImg',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3_我的_修改头像
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkerMyUpdateFaceImg (data, options) {
  return requestAjax({
    url: '/api-user/api/worker/my/updateFaceImg',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 个人信息设置-个人信息
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserUsersUpdateFaceImg (data, options) {
  return requestAjax({
    url: '/api-user/users/updateFaceImg',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 同步更改工人对应的班组名字
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerUpdateGroupNameByWorkerGroupId (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/updateGroupNameByWorkerGroupId',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description updateOpenid
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserUsersUpdateOpenid (data, options) {
  return requestAjax({
    url: '/api-user/users/updateOpenid',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 个人账号设置-账号密码-重置密码
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserUsersPassword (data, options) {
  return requestAjax({
    url: '/api-user/users/password',
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description 分公司-4-班前教育记录-开启关闭班前教育状态
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserApiSecurityUpdateProjectSwitchEducationProjectIdSwitchEducation (data, options) {
  const { projectId, switchEducation } = options.params
  return requestAjax({
    url: `/api-user/api/security/updateProjectSwitchEducation/${projectId}/${switchEducation}`,
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description 6_1_1工人档案-拉黑
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV2WorkerUpdateStateBlackWorkerId (data, options) {
  const { workerId } = options.params
  return requestAjax({
    url: `/api-user/api/v2/worker/updateStateBlack/${workerId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6_1_1工人档案-拉黑
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerUpdateStateBlackWorkerId (data, options) {
  const { workerId } = options.params
  return requestAjax({
    url: `/api-user/sysworker/updateStateBlack/${workerId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6_1_1工人档案-撤销拉黑
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV2WorkerUpdateStateRevokeWorkerId (data, options) {
  const { workerId } = options.params
  return requestAjax({
    url: `/api-user/api/v2/worker/updateStateRevoke/${workerId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 6_1_1工人档案-撤销拉黑-
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerUpdateStateRevokeWorkerId (data, options) {
  const { workerId } = options.params
  return requestAjax({
    url: `/api-user/sysworker/updateStateRevoke/${workerId}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description updateSubscribe
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserUsersUpdateSubscribe (data, options) {
  return requestAjax({
    url: '/api-user/users/updateSubscribe',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 管理后台修改用户
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserUsers (data, options) {
  return requestAjax({
    url: '/api-user/users',
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description 每天定时更改昨天任务状态
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserJobUpdateTask (params, options) {
  return requestAjax({
    url: '/api-user/job/updateTask',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 每天定时执行api容量同步至数据库
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserJobUpdateTotal (params, options) {
  return requestAjax({
    url: '/api-user/job/updateTotal',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 单个修改申请审核状态
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserUsercompanyUpdateCompanyIdUserIdType (data, options) {
  const { companyId, type, userId } = options.params
  return requestAjax({
    url: `/api-user/usercompany/update/${companyId}/${userId}/${type}`,
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description 批量修改申请审核状态
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserUsercompanyUpdateCompanyIdType (data, options) {
  const { companyId, type } = options.params
  return requestAjax({
    url: `/api-user/usercompany/update/${companyId}/${type}`,
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description 微信用户更新微信昵称，头像
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function putApiuserUsersUpdateUserInfo (data, options) {
  return requestAjax({
    url: '/api-user/users/updateUserInfo',
    method: 'PUT',
    data,
    ...options
  })
}

/**
 * @description 更新人员名字
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1RoleUserUpdateUserNickname (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/role/user/updateUserNickname',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 每天定时更新城市天气
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserJobUpdateWeather (params, options) {
  return requestAjax({
    url: '/api-user/job/updateWeather',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 每天0点推送更新工人年龄
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserJobUpdateWorkerAge (params, options) {
  return requestAjax({
    url: '/api-user/job/updateWorkerAge',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 修改班组里的工人为统一的考勤规则ID
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerUpdateWorkerAllGroupIdSettingId (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/updateWorkerAllGroupIdSettingId',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 小程序绑定pc登陆uuid
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageUserBindUuid (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/userBindUuid',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 小程序工人端通过token查询用户相关信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerUserCompanyInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/userCompanyInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据公司、用户ID查询相关信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyUserCompanyInfoCompanyIdUserId (params, options) {
  const { companyId, userId } = params
  return requestAjax({
    url: `/api-user/company/userCompanyInfo/${companyId}/${userId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description userId转换openId-OpenFeign
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV1ThirdPartyUserUserIdToOpenUserIdList (data, options) {
  return requestAjax({
    url: '/api-user/api/v1/thirdPartyUser/userIdToOpenUserIdList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 4.6项目添加成员-弹框-选择人员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiOrganizationUserInfoList (params, options) {
  return requestAjax({
    url: '/api-user/api/organization/userInfoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目选择人员-弹框-选择人员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV1OrganizationUserInfoList (params, options) {
  return requestAjax({
    url: '/api-user/v1/organization/userInfoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.1项目人员配置-弹框-选择人员列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserV2OrganizationUserInfoList (params, options) {
  return requestAjax({
    url: '/api-user/v2/organization/userInfoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 根据corpId获取当前公司的权限
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ThirdPartyUserUserInfoCorpId (params, options) {
  const { corpId } = params
  return requestAjax({
    url: `/api-user/api/v1/thirdPartyUser/userInfo/${corpId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description v1.1-2.2机构层级-人员管理-查询人员当前机构信息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserOrganizationCompanyUserRoleInfo (params, options) {
  return requestAjax({
    url: '/api-user/organization/company/userRoleInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取用户是否关注公众号,1已关注  0或其他未关注
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageUserSubscribe (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/userSubscribe',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6_1项目部-4种状态
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserSysworkerUserinfoStatus (data, options) {
  return requestAjax({
    url: '/api-user/sysworker/userinfoStatus',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 工人-在场工人列表，查询班组，项目，公司，考勤规则，工人名字，工人申请状态
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerUserlist (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/userlist',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 查询工人列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerUserList (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/user/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取城市地区天气
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiDiaryWeather (params, options) {
  return requestAjax({
    url: '/api-user/api/diary/weather',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取项目地区天气
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserCompanyprojectWeather (params, options) {
  return requestAjax({
    url: '/api-user/companyproject/weather',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 小程序通过appid获取AccessToken
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserCompanyprojectAccessTokenAppid (data, options) {
  const { appid } = options.params
  return requestAjax({
    url: `/api-user/companyproject/accessToken/${appid}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description (最新)微信-小程序通过code登录
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserLoginWeixinLoginAppidCode (data, options) {
  const { appid, code } = options.params
  return requestAjax({
    url: `/api-user/login/weixinLogin/${appid}/${code}`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description (最新)微信-小程序通过code,UserPhone登录
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserLoginWeixinLogin (data, options) {
  return requestAjax({
    url: '/api-user/login/weixinLogin',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 云筑创建工人退场
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerWokrOutWorkerId (params, options) {
  const { workerId } = params
  return requestAjax({
    url: `/api-user/sysworker/wokrOut/${workerId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 云筑创建工人进场
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerWorkInWorkerId (params, options) {
  const { workerId } = params
  return requestAjax({
    url: `/api-user/sysworker/workIn/${workerId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 5、工人管理-列表-汇总
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV2WorkerWorkerAndGroupTitleTotal (data, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/workerAndGroupTitleTotal',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 7.奖捐台账【6.工人详情-奖捐记录】-汇总
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerWorkerAwardDonateSummary (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/workerAwardDonateSummary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2工人档案-列表
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV2CompanyWorkerCompanyRecordList (data, options) {
  return requestAjax({
    url: '/api-user/api/v2/company/workerCompanyRecordList',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 2工人档案-汇总
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV2CompanyWorkerCompanyRecordSummary (data, options) {
  return requestAjax({
    url: '/api-user/api/v2/company/workerCompanyRecordSummary',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 7.奖捐台账【6.工人详情-奖捐记录】
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerWorkerIdAwardDonateList (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/workerIdAwardDonateList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人信息_奖励记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysWorkerArchivesWorkerIdAwardDonateList (params, options) {
  return requestAjax({
    url: '/api-user/sysWorkerArchives/workerIdAwardDonateList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 8.工人证书【6.工人详情-证书记录】
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerWorkerIdRelatedCertificateList (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/workerIdRelatedCertificateList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人信息_资格证书
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysWorkerArchivesWorkerIdRelatedCertificateList (params, options) {
  return requestAjax({
    url: '/api-user/sysWorkerArchives/workerIdRelatedCertificateList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人信息_培训记录
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysWorkerArchivesWorkerIdWorkerTrainingList (params, options) {
  return requestAjax({
    url: '/api-user/sysWorkerArchives/workerIdWorkerTrainingList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目工人列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageWorkerInfoList (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/workerInfoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 项目工人列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerWorkerInfoList (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/workerInfoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_2_3工人-01在场工人头部数组
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageWorkerInfoTitle (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/workerInfoTitle',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6_1项目部-工人档案列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerUserInfoList (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/user/infoList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_2_3工人-01在场工人
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageWorkerInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/manage/workerInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人管理
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1ManageWorkerInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/manage/workerInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人管理
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1WorkerWorkerInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/worker/workerInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3.班组管理-班组详情
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerWorkerInfo (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/workerInfo',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 5.工人管理-列表
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiV2WorkerWorkerMangeInfo (data, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/workerMangeInfo',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 7_2项目_当月考勤统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserSysworkerUserWorkerMonthList (params, options) {
  return requestAjax({
    url: '/api-user/sysworker/user/workerMonthList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 导出考勤统计
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserExportWorkerMonth (params, options) {
  return requestAjax({
    url: '/api-user/export/workerMonth',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 3_5_1劳务BI-04项目信息工人画像
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiManageWorkerPortraitCompanyIdProjectId (params, options) {
  const { companyId, projectId } = params
  return requestAjax({
    url: `/api-user/api/manage/workerPortrait/${companyId}/${projectId}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 6.项目经历
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerWorkerProjectList (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/workerProjectList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 2_2_2班组-04设置职务
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiManageWorkerSetPosition (data, options) {
  return requestAjax({
    url: '/api-user/api/manage/workerSetPosition',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 9.工人培训【6.工人详情-培训记录】
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerWorkerTrainingList (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/workerTrainingList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 9.工人培训【6.工人详情-培训记录】-汇总
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerWorkerTrainingSummary (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/workerTrainingSummary',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人管理-工种
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2CompanyWorkerTypeList (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/company/workerTypeList',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 获取工种列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiWorkerWorkerType (params, options) {
  return requestAjax({
    url: '/api-user/api/worker/workerType',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 33.班组考勤统计-筛选条件-工种
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV2WorkerWorkerType (params, options) {
  return requestAjax({
    url: '/api-user/api/v2/worker/workerType',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 1_1工人登录-绑定申请-工人端账号申请绑定
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApiuserApiWorkerWorkerUserAccount (data, options) {
  return requestAjax({
    url: '/api-user/api/worker/workerUserAccount',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 3.班组详情-工人统计状态-在场退场休息
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApiuserApiV1WorkerWorkerWorkingState (params, options) {
  return requestAjax({
    url: '/api-user/api/v1/worker/workerWorkingState',
    method: 'GET',
    params,
    ...options
  })
}

import { MaliStorage } from 'mali-h5-ui'

const url = {
  state: {
    appId: MaliStorage.getItem('APP_ID'),
    pageLoading: false
  },
  getters: {
    appId: (state) => state.appId,
    pageLoading: (state) => state.pageLoading
  },
  mutations: {
    setPageLoading (state, visible) {
      state.pageLoading = visible
    },
    setAppId (state, appId) {
      MaliStorage.setItem('APP_ID', appId)
      state.appId = appId
    }
  },
  actions: {
  }
}

export default url

import ProjectContainer from '@/views/layout/platform/ProjectContainer.vue'

/**
 * 财务模块
 */
export default [
  // 项目层级
  {
    path: '/project/financial',
    component: ProjectContainer,
    children: [
      {
        path: 'requestPrice',
        name: 'ProjectFinancialApplyCostApply',
        component: () => import('@/views/project/financial/requestPrice/RequestPrice.vue'),
        meta: {
          title: '申请费用'
        }
      },
      {
        path: 'myApplication',
        name: 'ProjectFinancialApplyMyApply',
        component: () => import('@/views/project/financial/requestPrice/MyApplication.vue'),
        meta: {
          title: '我的申请'
        }
      },
      {
        path: 'partyAContractList',
        name: 'ProjectFinancialEarningPartyContractList',
        component: () => import('@/views/project/financial/income/partyAContract/PartyAContractList.vue')
      },
      {
        path: 'partyAContractDetail',
        name: 'ProjectFinancialPartyAContractDetail',
        component: () => import('@/views/project/financial/income/partyAContract/PartyAContractDetail.vue')
      },
      {
        path: 'visaChangeList',
        name: 'ProjectFinancialEarningVisaList',
        component: () => import('@/views/project/financial/income/visaChange/VisaChangeList.vue')
      },
      {
        path: 'visaChangeDetail',
        name: 'ProjectFinancialVisaChangeDetail',
        component: () => import('@/views/project/financial/income/visaChange/VisaChangeDetail.vue')
      },
      {
        path: 'collectionList',
        name: 'ProjectFinancialEarningEarningAccount',
        component: () => import('@/views/project/financial/income/collection/CollectionList.vue')
      },
      {
        path: 'collectionDetail',
        name: 'ProjectFinancialCollectionDetail',
        component: () => import('@/views/project/financial/income/collection/CollectionDetail.vue')
      },
      {
        path: 'addCollection',
        name: 'ProjectFinancialAddCollection',
        component: () => import('@/views/project/financial/income/addCollection/AddCollection.vue')
      }
    ]
  }
]

import XEUtils from 'xe-utils'
import { MaliUtils } from 'mali-h5-ui'
import Base64 from 'base-64'

if (process.env.NODE_ENV === 'development') {
  // 仅用于开发环境调试
  window.XEUtils = XEUtils
  window.MaliUtils = MaliUtils
}

export function getClientAuthorization () {
  const CLIENT_SECRET = process.env.VUE_APP_CLIENT_ID
  return Base64.encode(`${process.env.VUE_APP_CLIENT_ID}:${CLIENT_SECRET}`)
}

export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 递归查询数组中某个字段所在的对象
 * @param array 查询的数组
 * @param key key
 * @param value value
 */
export function findObjWithKeyValue (array, key, value) {
  let o
  array.some(function iter (a) {
    if (a[key] === value) {
      o = a
      return true
    }
    return Array.isArray(a.children) && a.children.some(iter)
  })
  return o
}

/**
 * 从接口返回的数据中重新生成符合 tree 组件的数据结构
 * @param data
 */
export function createDepartmentTree (data = [], disabledDepartment) {
  return data.map(item => {
    let children = []
    if (item.children && item.children.length) children = createDepartmentTree(item.children, disabledDepartment)
    const { id, name, branchType, total, branchId, branchStatus, pid } = item
    const tree = {
      title: name,
      key: id,
      branchType,
      total,
      children,
      branchId,
      branchStatus,
      pid
    }
    if (disabledDepartment) {
      if (item.branchType === 1) {
        tree.disabled = false
      } else {
        tree.disabled = true
      }
    }

    return tree
  })
}

/**
 * 自定义校验 企业信用代码
 * @param rule
 * @param value
 * @param cb
 * @param errorMsg
 */
export const validateCode = (rule, value, cb, { errorMsg = '请填写18位的企业信用代码' }) => {
  if (value.length !== 18) {
    cb(new Error(errorMsg))
  }
  cb()
}

/**
 * FormModel 自定义校验 手机号码
 * @param rule 规则
 * @param value 值
 * @param cb 回调
 */
export function validatePhone (rule, value, cb, { errorMsg = '请输入正确格式的手机号码' }) {
  const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  if (rule.required && value.trim() === '') {
    cb(new Error('该项为必填项'))
  }
  if (value && !phoneReg.test(value)) {
    cb(new Error(errorMsg))
  }
  cb()
}

/**
 * FormModel 自定义校验 身份证
 * @param rule 规则
 * @param value 值
 * @param cb 回调
 */
export function validateIdcard (rule, value, cb, { errorMsg = '请输入正确格式的身份证号' }) {
  const idcardReg = /(^\d{17}(\d|X|x)$)/
  if (rule.required && value.trim() === '') {
    cb(new Error('该项为必填项'))
  }
  if (value && !idcardReg.test(value)) {
    cb(new Error(errorMsg))
  }
  cb()
}

/**
 * FormModel 自定义校验 金额（正整数，正浮点数）
 * @param rule 规则
 * @param value 值
 * @param cb 回调
 */
export function validateMoney (rule, value, cb, { errorMsg = '请输入正确格式的金额' }) {
  const moneyReg = /^[1-9]\d*\.\d*$|0\.\d*[1-9]\d*$|^[1-9]\d*$/
  if (rule.required && !value) {
    cb(new Error('该项为必填项'))
  }
  if (value && !moneyReg.test(value)) {
    cb(new Error(errorMsg))
  }
  cb()
}

/**
 * FormModel 自定义校验 银行卡号
 * @param rule 规则
 * @param value 值
 * @param cb 回调
 */
export function validateBank (rule, value, cb, { errorMsg = '请输入正确格式的银行卡号' }) {
  const bankReg = /[1-9]\d{12,18}/
  if (rule.required && value.trim() === '') {
    cb(new Error('该项为必填项'))
  }
  if (value && !bankReg.test(value)) {
    cb(new Error(errorMsg))
  }
  cb()
}

/**
 * FormModel 自定义校验 邮箱
 * @param rule 规则
 * @param value 值
 * @param cb 回调
 */
export function validateEmail (rule, value, cb, { errorMsg = '请输入正确格式的邮箱' }) {
  const emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
  if (rule.required && value.trim() === '') {
    cb(new Error('该项为必填项'))
  }
  if (value && !emailReg.test(value)) {
    cb(new Error(errorMsg))
  }
  cb()
}

/**
 * 获取当前时间 (YYYY-MM-DD hh:mm:ss)
 * @returns {string}
 */
export function getNowFormatDate () {
  return toDateString(new Date(), 'yyyy-MM-DD HH:mm:ss')
}

/**
 * 获取当前年月日
 * @returns {string}
 */
export function getYearMonthdayFormat () {
  return toDateString(new Date(), 'yyyy-MM-DD')
}

/**
 * 获取当前月时间
 * @returns {string}
 */
export function getNowMonth () {
  return toDateString(new Date(), 'yyyy-MM')
}

/**
 * 获取当前年
 * @returns {string}
 */
export function getNowYear () {
  return toDateString(new Date(), 'yyyy')
}

/**
 * 获取上月时间
 * @returns {string}
 */
export function getPrevFormatDate () {
  const date = new Date()
  const seperator1 = '-'
  let month = date.getMonth()
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  return date.getFullYear() + seperator1 + month
}

/**
 * 获取上月时间
 * @returns {string}
 */

export function getPreMonth () {
  const arr = getNowMonth().split('-')
  const year = arr[0]
  const month = arr[1]

  let year2 = year
  let month2 = parseInt(month) - 1
  if (month2 === 0) {
    year2 = parseInt(year2) - 1
    month2 = 12
  }

  if (month2 < 10) {
    month2 = '0' + month2
  }
  const preMonth = year2 + '-' + month2
  return preMonth
}

/**
 * 格式化日期
 * @param {*} date
 * @param {*} format
 * @returns
 */
export function toDateString (date, format = 'YYYY-MM-DD HH:mm:ss') {
  return XEUtils.toDateString(date, format.replace('YYYY', 'yyyy').replace('DD', 'dd'))
}

/**
 * 格式化数值
 * @param {*} value
 * @returns
 */
export function toNumberString (value) {
  return `${isNaN(value) ? 0 : Number(value)}`
}

export function formatAmountString (value) {
  const num = Number(value || 0)
  if (num) {
    if (num > 99999999) {
      return `${toAmountString(num / 100000000, 9)}亿元`
    }
    if (num > 9999) {
      return `${toAmountString(num / 10000, 5)}万元`
    }
    return `${toAmountString(num)}元`
  }
  return '0元'
}

/**
 * 格式化为万元单位
 * @param {*} value
 * @returns
 */
export function toWanAmountString (value, fixed = 2) {
  return XEUtils.commafy(value >= 0 ? XEUtils.floor(value ? value / 10000 : 0, fixed) : XEUtils.ceil(value ? value / 10000 : 0, fixed), { digits: fixed })
}

/**
 * 格式化为金额
 * @param {*} value
 * @param {*} fixed
 */
export function toAmountString (value, fixed = 2) {
  return XEUtils.commafy(XEUtils.round(value, fixed), { digits: fixed })
}

/**
 * 根据blob下载
 * @param {*} blob
 * @param {*} name
 */
export function downloadByBlob (blob, name) {
  const elem = document.createElement('a')
  elem.href = URL.createObjectURL(blob)
  elem.download = name
  elem.click()
}

/**
 * 加法
 */
export function addNum (num1, num2) {
  return XEUtils.add(num1, num2)
}

/**
 * 减法
 */
export function subtractNum (num1, num2) {
  return XEUtils.subtract(num1, num2)
}

/**
 * 乘法
 */
export function multiplyNum (num1, num2) {
  return XEUtils.multiply(num1, num2)
}

/**
 * 除法
 */
export function divideNum (num1, num2) {
  return XEUtils.divide(num1, num2)
}

/**
 * 税金计算
 * @param 含税金额 taxPrice
 * @param 税率 taxRate
 * @returns {number}
 */
export function computedTax (taxPrice, taxRate) {
  console.log(taxPrice / (1 + (taxRate / 100)), (taxRate / 100))
  const number = multiplyNum(taxPrice / (1 + (taxRate / 100)), (taxRate / 100))
  return XEUtils.round(number, 2)
}

/**
 * 审批状态
 * @param 状态 status
 * @returns {string}
 */
export function approveType (status) {
  return ['起草中', '审批中', '已通过', '驳回', '会签中', '处理中', '已完成'][parseInt(status)]
}

/**
 * 获取多层级所有节点中的某个属性值
 * @param 源数据* datas
 * @param 属性名  key
 * @param 子节点  children
 * @param 初始值  result
 * @returns {array}
 */
export function findForAllKey ({ datas = [], key = 'id', children = 'children', result = [] }) {
  for (const item of datas) {
    result.push(item[key])
    if (item[children] && item[children].length) findForAllKey({ datas: item[children], key, children, result })
  }
  return result
}

import App from 'vue'
import './nprogress'
import './vant'
import './mali'

import PageLayout from '@/views/layout/PageLayout.vue'
import ViewContainer from '@/views/layout/ViewContainer.vue'

App.component('PageLayout', PageLayout)
App.component('ViewContainer', ViewContainer)

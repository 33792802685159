const url = {
  state: {
    staticUrl: process.env.VUE_APP_PUBLIC_RESOURCES_URL,
    imgApiUrl: process.env.VUE_APP_UPLOAD_PROVIDER === 'HUAWEIOBS' ? process.env.VUE_APP_API_HUAWEIOBS_UPLOAD_URL : process.env.VUE_APP_API_ALIOSS_UPLOAD_URL,
    apiUrl: process.env.VUE_APP_API_BASE_URL
  },
  getters: {
    staticUrl: (state) => state.staticUrl,
    imgApiUrl: (state) => state.imgApiUrl,
    apiUrl: (state) => state.apiUrl
  },
  mutations: {
  },
  actions: {
  }
}

export default url

import CompanyContainer from '@/views/layout/platform/CompanyContainer.vue'
import ProjectContainer from '@/views/layout/platform/ProjectContainer.vue'

/**
 * 设计管理
 */
export default [
  // 公司层级
  {
    path: '/company/business',
    component: CompanyContainer,
    children: [
      // 对内-劳务图纸
      {
        path: 'laborDraw',
        name: 'CompanyDeepDesignInternalLaborDraw',
        component: () => import('@/views/company/deepDesign/inside/internal/LaborDraw.vue'),
        meta: {
          title: '劳务图纸'
        }
      },
      {
        path: 'projectLaborDraw',
        name: 'CompanyDeepDesignInternalProjectLaborDraw',
        component: () => import('@/views/company/deepDesign/inside/internal/ProjectLaborDraw.vue'),
        meta: {
          title: '劳务图纸'
        }
      },
      // 对内-材料图纸
      {
        path: 'materialsDraw',
        name: 'CompanyDeepDesignInternalMaterialDrawingsDraw',
        component: () => import('@/views/company/deepDesign/inside/internal/MaterialsDraw.vue'),
        meta: {
          title: '材料图纸'
        }
      },
      {
        path: 'projectMaterialsDraw',
        name: 'CompanyDeepDesignInternalMaterialProjectMaterialsDraw',
        component: () => import('@/views/company/deepDesign/inside/internal/ProjectMaterialsDraw.vue'),
        meta: {
          title: '材料图纸'
        }
      },
      // 对内-专业分包图纸
      {
        path: 'subcontractingDraw',
        name: 'CompanyDeepDesignInternalSubcontractingDraw',
        component: () => import('@/views/company/deepDesign/inside/internal/SubcontractingDraw.vue'),
        meta: {
          title: '专业分包图纸'
        }
      },
      {
        path: 'projectSubcontractingDraw',
        name: 'CompanyDeepDesignInternalProjectSubcontractingDraw',
        component: () => import('@/views/company/deepDesign/inside/internal/ProjectSubcontractingDraw.vue'),
        meta: {
          title: '专业分包图纸'
        }
      },
      {
        path: 'drawDetailList',
        name: 'CompanyDeepDesignInternalDrawDetailList',
        component: () => import('@/views/company/deepDesign/inside/DetailList.vue'),
        meta: {
          title: '专业分包图纸'
        }
      },
      // 对内-其他图纸
      {
        path: 'otherDraw',
        name: 'CompanyDeepDesignInternalOtherDraw',
        component: () => import('@/views/company/deepDesign/inside/internal/OtherDraw.vue'),
        meta: {
          title: '其他图纸'
        }
      },
      {
        path: 'projectOtherDraw',
        name: 'CompanyDeepDesignInternalProjectOtherDraw',
        component: () => import('@/views/company/deepDesign/inside/internal/ProjectOtherDraw.vue'),
        meta: {
          title: '其他图纸'
        }
      },
      {
        path: 'foreignDraw',
        name: 'CompanyDeepDesignInternalForeignDraw',
        component: () => import('@/views/company/deepDesign/outside/externalDrawings/foreign/ForeignDraw.vue'),
        meta: {
          title: '对外图纸'
        }
      },
      {
        path: 'projectForeignDraw',
        name: 'CompanyDeepDesignInternalProjectForeignDraw',
        component: () => import('@/views/company/deepDesign/outside/externalDrawings/foreign/ProjectForeignDraw.vue'),
        meta: {
          title: '对外图纸'
        }
      }
    ]
  },
  // 项目层级
  {
    path: '/project/business',
    component: ProjectContainer,
    children: [
      // 项目层级-劳务图纸
      {
        path: 'laborDraw',
        name: 'ProjectDeepDesignInternalLaborDraw',
        component: () => import('@/views/project/deepDesign/inside/labour/internal/LaborDraw.vue'),
        meta: {
          title: '劳务图纸'
        }
      },
      // 新增劳务图纸会审
      {
        path: 'addJointTrial',
        name: 'ProjectDeepDesignInternalLaborAddJointTrial',
        component: () => import('@/views/project/deepDesign/inside/labour/jointTrial/AddJointTrial.vue'),
        meta: {
          title: '新增劳务图纸会审'
        }
      },
      // 图纸会审详情
      {
        path: 'jointTrialDetalis',
        name: 'ProjectDeepDesignInternalLaborJointTrialDetalis',
        component: () => import('@/views/project/deepDesign/details/jointTrialDetalis/JointTrialDetalis.vue'),
        meta: {
          title: '图纸会审详情'
        }
      },
      // 新增劳务设计变更
      {
        path: 'addDesignChange',
        name: 'ProjectDeepDesignInternalLaborAddDesignChange',
        component: () => import('@/views/project/deepDesign/inside/labour/designChange/AddDesignChange.vue'),
        meta: {
          title: '新增劳务设计变更'
        }
      },
      // 劳务竣工图
      {
        path: 'addBeCompleted',
        name: 'ProjectDeepDesignInternalAddBeCompleted',
        component: () => import('@/views/project/deepDesign/inside/labour/beCompleted/AddBeCompleted.vue'),
        meta: {
          title: '劳务图纸会审详情'
        }
      },
      // 新增劳务图纸深化
      {
        path: 'addDrawingDeepening',
        name: 'ProjectDeepDesignInternalLaborAddDrawingDeepening',
        component: () => import('@/views/project/deepDesign/inside/labour/drawingDeepening/AddDrawingDeepening.vue'),
        meta: {
          title: '新增劳务图纸深化'
        }
      },
      // 图纸深化详情
      {
        path: 'drawingDeepeningDetalis',
        name: 'ProjectDeepDesignInternalLaborDrawingDeepeningDetalis',
        component: () => import('@/views/project/deepDesign/details/drawingDeepeningDetalis/DrawingDeepeningDetalis.vue'),
        meta: {
          title: '图纸深化详情'
        }
      },
      // 新增劳务下料申请
      {
        path: 'addBlankingApplication',
        name: 'ProjectDeepDesignInternalLaborAddBlankingApplication',
        component: () => import('@/views/project/deepDesign/inside/labour/blankingApplication/AddBlankingApplication.vue'),
        meta: {
          title: '新增劳务下料申请'
        }
      },
      // 下料申请详情
      {
        path: 'blankingApplicationDetalis',
        name: 'ProjectDeepDesignInternalLaborBlankingApplicationDetalis',
        component: () => import('@/views/project/deepDesign/details/blankingApplicationDetalis/BlankingApplicationDetalis.vue'),
        meta: {
          title: '下料申请详情'
        }
      },
      // 材料图纸
      {
        path: 'materialDraw',
        name: 'ProjectMaterialDrawingsInternalMaterialDraw',
        component: () => import('@/views/project/deepDesign/inside/materialDrawings/internal/MaterialDraw.vue'),
        meta: {
          title: '材料图纸'
        }
      },
      // 新增材料图纸会审
      {
        path: 'addBlankingApplication',
        name: 'ProjectDeepDesignInternalMaterialAddBlankingApplication',
        component: () => import('@/views/project/deepDesign/inside/materialDrawings/blankingApplication/AddBlankingApplication.vue'),
        meta: {
          title: '新增材料图纸会审'
        }
      },
      // 新增材料设计变更
      {
        path: 'addDesignChange',
        name: 'ProjectDeepDesignInternalMaterialAddDesignChange',
        component: () => import('@/views/project/deepDesign/inside/materialDrawings/designChange/AddDesignChange.vue'),
        meta: {
          title: '新增材料设计变更'
        }
      },
      // 新增材料图纸深化
      {
        path: 'addDrawingDeepening',
        name: 'ProjectDeepDesignInternalMaterialAddDrawingDeepening',
        component: () => import('@/views/project/deepDesign/inside/materialDrawings/drawingDeepening/AddDrawingDeepening.vue'),
        meta: {
          title: '新增材料图纸深化'
        }
      },
      // 新增材料图纸会审
      {
        path: 'addJointTrial',
        name: 'ProjectDeepDesignInternalMaterialAddJointTrial',
        component: () => import('@/views/project/deepDesign/inside/materialDrawings/jointTrial/AddJointTrial.vue'),
        meta: {
          title: '新增材料图纸会审'
        }
      },
      // 分包图纸
      {
        path: 'professionalSubcontractingDraw',
        name: 'ProjectSubcontractingInternalSubcontractingDraw',
        component: () => import('@/views/project/deepDesign/inside/professionalSubcontracting/internal/ProfessionalSubcontractingDraw.vue'),
        meta: {
          title: '分包图纸'
        }
      },
      // 新增分包图纸会审
      {
        path: 'addJointTrial',
        name: 'ProjectSubcontractingInternalSubcontractingDrawAddJointTrial',
        component: () => import('@/views/project/deepDesign/inside/professionalSubcontracting/jointTrial/AddJointTrial.vue'),
        meta: {
          title: '新增分包图纸会审'
        }
      },
      // 新增分包竣工图
      {
        path: 'addBeCompleted',
        name: 'ProjectSubcontractingInternalSubcontractingDrawAddBeCompleted',
        component: () => import('@/views/project/deepDesign/inside/professionalSubcontracting/beCompleted/AddBeCompleted.vue'),
        meta: {
          title: '新增分包竣工图'
        }
      },
      // 新增分包设计变更
      {
        path: 'addDesignChange',
        name: 'ProjectSubcontractingInternalSubcontractingDrawAddDesignChange',
        component: () => import('@/views/project/deepDesign/inside/professionalSubcontracting/designChange/AddDesignChange.vue'),
        meta: {
          title: '新增分包设计变更'
        }
      },
      // 新增分包图纸深化
      {
        path: 'addDrawingDeepening',
        name: 'ProjectSubcontractingInternalSubcontractingDrawAddDrawingDeepening',
        component: () => import('@/views/project/deepDesign/inside/professionalSubcontracting/drawingDeepening/AddDrawingDeepening.vue'),
        meta: {
          title: '新增分包图纸深化'
        }
      },
      // 新增分包下料申请
      {
        path: 'addBlankingApplication',
        name: 'ProjectSubcontractingInternalSubcontractingDrawAddBlankingApplication',
        component: () => import('@/views/project/deepDesign/inside/professionalSubcontracting/blankingApplication/AddBlankingApplication.vue'),
        meta: {
          title: '新增分包下料申请'
        }
      },
      // 其他图纸
      {
        path: 'otherDraw',
        name: 'ProjectOtherInternalOtherDraw',
        component: () => import('@/views/project/deepDesign/inside/other/internal/OtherDraw.vue'),
        meta: {
          title: '其他图纸'
        }
      },
      // 新增其他图纸会审
      {
        path: 'addJointTrial',
        name: 'ProjectOtherInternalOtherDrawAddJointTrial',
        component: () => import('@/views/project/deepDesign/inside/other/jointTrial/AddJointTrial.vue'),
        meta: {
          title: '新增其他图纸会审'
        }
      },
      // 新增其他竣工图
      {
        path: 'addBeCompleted',
        name: 'ProjectOtherInternalOtherDrawAddBeCompleted',
        component: () => import('@/views/project/deepDesign/inside/other/beCompleted/AddBeCompleted.vue'),
        meta: {
          title: '新增其他竣工图'
        }
      },
      // 新增其他设计变更
      {
        path: 'addDesignChange',
        name: 'ProjectOtherInternalOtherDrawAddDesignChange',
        component: () => import('@/views/project/deepDesign/inside/other/designChange/AddDesignChange.vue'),
        meta: {
          title: '新增其他设计变更'
        }
      },
      // 新增其他图纸深化
      {
        path: 'addDrawingDeepening',
        name: 'ProjectOtherInternalOtherDrawAddDrawingDeepening',
        component: () => import('@/views/project/deepDesign/inside/other/drawingDeepening/AddDrawingDeepening.vue'),
        meta: {
          title: '新增其他图纸深化'
        }
      },
      // 新增其他下料申请
      {
        path: 'addBlankingApplication',
        name: 'ProjectOtherInternalOtherDrawAddBlankingApplication',
        component: () => import('@/views/project/deepDesign/inside/other/blankingApplication/AddBlankingApplication.vue'),
        meta: {
          title: '新增其他下料申请'
        }
      },
      // 项目层级-对外图纸
      {
        path: 'foreignDraw',
        name: 'ProjectExternalDrawingsForeignForeignDraw',
        component: () => import('@/views/project/deepDesign/outside/externalDrawings/foreign/ForeignDraw.vue'),
        meta: {
          title: '对外图纸'
        }
      },
      // 新增施工图纸
      {
        path: 'addSchedule',
        name: 'ProjectExternalDrawingsForeignAddSchedule',
        component: () => import('@/views/project/deepDesign/outside/externalDrawings/schedule/AddSchedule.vue'),
        meta: {
          title: '新增施工图纸'
        }
      },
      // 施工图纸详情
      {
        path: 'scheduleDetalis',
        name: 'ProjectExternalDrawingsForeignScheduleDetalis',
        component: () => import('@/views/project/deepDesign/outside/externalDrawings/schedule/ScheduleDetalis.vue'),
        meta: {
          title: '施工图纸详情'
        }
      },
      // 新增补充图
      {
        path: 'addSupplement',
        name: 'ProjectExternalDrawingsForeignAddSupplement',
        component: () => import('@/views/project/deepDesign/outside/externalDrawings/supplement/AddSupplement.vue'),
        meta: {
          title: '新增补充图'
        }
      },
      // 补充图详情
      {
        path: 'supplementDetalis',
        name: 'ProjectExternalDrawingsForeignSupplementDetalis',
        component: () => import('@/views/project/deepDesign/outside/externalDrawings/supplement/SupplementDetalis.vue'),
        meta: {
          title: '补充图详情'
        }
      },
      // 添加其他图纸
      {
        path: 'addOther',
        name: 'ProjectExternalDrawingsForeignAddOther',
        component: () => import('@/views/project/deepDesign/outside/externalDrawings/other/AddOther.vue'),
        meta: {
          title: '添加其他图纸'
        }
      },
      // 其他图纸详情
      {
        path: 'OtherDetalis',
        name: 'ProjectExternalDrawingsForeignOtherDetalis',
        component: () => import('@/views/project/deepDesign/outside/externalDrawings/other/OtherDetalis.vue'),
        meta: {
          title: '其他图纸详情'
        }
      },
      // 新增图纸会审
      {
        path: 'addJointTrial',
        name: 'ProjectExternalDrawingsForeignAddJointTrial',
        component: () => import('@/views/project/deepDesign/outside/externalDrawings/jointTrial/AddJointTrial.vue'),
        meta: {
          title: '新增图纸会审'
        }
      },
      // 图纸会审详情
      {
        path: 'jointTrialDetalis',
        name: 'ProjectExternalDrawingsForeignJointTrialDetalis',
        component: () => import('@/views/project/deepDesign/outside/externalDrawings/jointTrial/JointTrialDetalis.vue'),
        meta: {
          title: '图纸会审详情'
        }
      },
      // 新增设计变更
      {
        path: 'addDesignChange',
        name: 'ProjectExternalDrawingsForeignAddDesignChange',
        component: () => import('@/views/project/deepDesign/outside/externalDrawings/designChange/AddDesignChange.vue'),
        meta: {
          title: '新增设计变更'
        }
      },
      // 设计变更详情
      {
        path: 'designChangeDetalis',
        name: 'ProjectExternalDrawingsForeignDesignChangeDetalis',
        component: () => import('@/views/project/deepDesign/details/designChangeDetalis/DesignChangeDetalis.vue'),
        meta: {
          title: '设计变更详情'
        }
      },
      // 新增劳务竣工图
      {
        path: 'addBeCompleted',
        name: 'ProjectExternalDrawingsForeignAddBeCompleted',
        component: () => import('@/views/project/deepDesign/outside/externalDrawings/beCompleted/AddBeCompleted.vue'),
        meta: {
          title: '新增劳务竣工图'
        }
      },
      // 竣工图详情
      {
        path: 'beCompletedDetalis',
        name: 'ProjectExternalDrawingsForeignBeCompletedDetalis',
        component: () => import('@/views/project/deepDesign/details/beCompletedDetalis/BeCompletedDetalis.vue'),
        meta: {
          title: '竣工图详情'
        }
      }
    ]
  }
]

import { getApiuserCompanyListMenuByBranchId, getApiuserCompanyListMenuByProjectId } from '@/api/user'
import router from '@/router'
import XEUtils from 'xe-utils'

/**
 * 菜单分类
 * @param menus
 * @returns Array
 */
function menuSort (menus) {
  if (menus && menus.length) {
    const admin = menus.filter(item => item.menuType === 0)
    const company = menus.filter(item => item.menuType === 1)
    const project = menus.filter(item => item.menuType === 2)
    return { company, project, admin }
  } else {
    return { company: [], project: [], admin: [] }
  }
}

/**
 * 转换树形菜单
 */
function listToTreeMenu (list, parentId) {
  const tree = []
  list.forEach(item => {
    // 判断是否为父级菜单
    if (item.parentId === parentId) {
      const child = {
        ...item,
        key: item.key || item.name
      }
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      child.children = listToTreeMenu(list, item.id)
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children
      }
      // 加入到树中
      tree.push(child)
    }
  })
  return tree.sort((pre, next) => {
    return pre.sort - next.sort
  })
}

function findFirstMenu (menus) {
  const rest = XEUtils.findTree(menus, item => !item.hidden && (item.type === 0 ? item.redirect : item.type === 1))
  return rest ? rest.item : null
}

// 如果是劳务模块，仅滤出劳务的菜单
const labourCompanyAllMenu = ['CompanyLaborAddProvider', 'CompanyLaborProviderEdit', 'CompanyLaborProviderDetail', 'CompanyProjectListAddBtn', 'CompanyLaborProviderList', 'CompanySubcontractorList', 'project_list', 'CompanyLaborSubcontractorAdd', 'systemManage', 'CompanyTeamList', 'CompanyNewTeamAdd', 'CompanyWorkerList', 'companyLabourFileManagement', 'CompanyLaborBlackMenu', 'companyLabourProvider', 'CompanyWorkPlacceTodo', 'CompanyStatisticalBoardLabor', 'companyEditProject', 'project_add', 'CompanyWorkPlacce', 'CompanyStatisticalBoard', 'CompanyProject1', 'CompanyLabour1', 'CompanySystem']
const labourProjectAllMenu = ['ProjectLaborArchivesWorkerDetail', 'ProjectLaborArchivesTeam', 'ProjectLaborArchivesWorker', 'ProjectLaborArchivesWorkerEdit', 'ProjectAttendanceAccount', 'ProjectAttendanceMonths', 'ProjectAttendanceDays', 'ProjectProjectSystemManageRole', 'projectAttendance', 'projectArchives', 'PorjectLaborGYSGL', 'ProjectLaborProviderList', 'ProjectDashboardLabour', 'ProjectLaborArchivesRewardPunishAccount', 'ProjectLaborArchivesAboutCertificate', 'ProjectLaborArchivesTrainingRecord', 'ProjectDashboard', 'ProjectLabour1', 'ProjectLaborArchivesTeamEdit', 'ProjectLaborProviderEdit', 'ProjectLaborProviderDetail', 'ProjectLaborArchivesTeamAdd', 'ProjectLaborProviderAdd', 'ProjectProjectSystemManage', 'ProjectRoleManagement']

function filterViewMenu (list) {
  const labourAllMenu = [...labourCompanyAllMenu, ...labourProjectAllMenu]
  // 如果是码里劳务
  if (process.env.VUE_APP_CUSTOM_MODULE === 'labour') {
    return list.filter(item => item.menuType === 0 || labourAllMenu.includes(item.name))
  }
  return list
}

function parseMenu (list) {
  const { admin, company, project } = menuSort(filterViewMenu(list))
  const menuPermission = {
    adminMenu: [],
    adminAllMenu: admin.slice(0),
    companyMenu: [],
    companyAllMenu: company.slice(0),
    projectMenu: [],
    projectAllMenu: project.slice(0)
  }
  const adminChildrenNav = listToTreeMenu(admin.filter(item => [0, 1].includes(item.type)), '-1')
  const companyChildrenNav = listToTreeMenu(company.filter(item => [0, 1].includes(item.type)), '-1')
  const projectChildrenNav = listToTreeMenu(project.filter(item => [0, 1].includes(item.type)), '-1')
  if (adminChildrenNav && adminChildrenNav.length) {
    menuPermission.adminMenu = adminChildrenNav
  }
  if (companyChildrenNav && companyChildrenNav.length) {
    menuPermission.companyMenu = companyChildrenNav
  }
  if (projectChildrenNav && projectChildrenNav.length) {
    menuPermission.projectMenu = projectChildrenNav
  }
  menuPermission.adminHomeRoute = findFirstMenu(menuPermission.adminMenu)
  menuPermission.companyHomeRoute = findFirstMenu(menuPermission.companyMenu)
  menuPermission.projectHomeRoute = findFirstMenu(menuPermission.projectMenu)
  return menuPermission
}

const menu = {
  state: {
    menuKey: '',
    menuPromise: null,
    adminMenu: [],
    adminAllMaps: {},
    companyMenu: [],
    companyAllMenu: [],
    companyAllMaps: {},
    projectMenu: [],
    projectMenuMaps: {},
    projectAllMenu: [],
    currentMenuList: []
  },
  getters: {
    adminHomeRoute: (state) => {
      return findFirstMenu(state.adminMenu)
    },
    companyHomeRoute: (state) => {
      return findFirstMenu(state.companyMenu) || { name: 'CompanyWorkPlacce' }
    },
    projectHomeRoute: (state) => {
      return findFirstMenu(state.projectMenu) || { name: 'ProjectWorkPlacce' }
    },
    currentMenuList: (state, getters, rootState, rootGetters) => {
      if (rootGetters.isAdminUser) {
        return state.adminMenu
      }
      if (rootGetters.projectId) {
        return state.projectMenu
      }
      if (rootGetters.branchId) {
        return state.companyMenu
      }
      return []
    },
    adminMenu: (state) => state.adminMenu,
    companyMenu: (state) => state.companyMenu,
    companyAllMenu: (state) => state.companyAllMenu,
    companyAllMaps: (state) => state.companyAllMaps,
    projectMenu: (state) => state.projectMenu,
    projectAllMenu: (state) => state.projectAllMenu,
    projectMenuMaps: (state) => state.projectMenuMaps
  },
  mutations: {
    setMenuKey (state, key) {
      state.menuKey = key
    },
    setMenus: (state, { adminMenu, companyAllMenu, companyMenu, projectAllMenu, projectMenu }) => {
      state.adminMenu = adminMenu || []
      state.companyMenu = companyMenu || []
      state.acompanyAllMenu = companyAllMenu || []
      state.projectMenu = projectMenu || []
      state.projectAllMenu = projectAllMenu || []

      const companyAllMaps = {}
      state.acompanyAllMenu.forEach(item => {
        if (companyAllMaps[item.name]) {
          console.error(`菜单配置重复：name=${item.name} title=${item.title}`)
        }
        companyAllMaps[item.name] = item
      })
      state.companyAllMaps = companyAllMaps
      const projectMenuMaps = {}
      state.projectAllMenu.forEach(item => {
        if (projectMenuMaps[item.name]) {
          console.error(`菜单配置重复：name=${item.name} title=${item.title}`)
        }
        projectMenuMaps[item.name] = item
      })
      state.projectMenuMaps = projectMenuMaps
    }
  },
  actions: {
    toHome ({ rootGetters, dispatch }) {
      if (rootGetters.isAdminUser) {
        dispatch('toAdminHome')
      } else if (rootGetters.projectId) {
        dispatch('toProjectHome')
      } else if (rootGetters.companyId) {
        dispatch('toCompanyHome')
      }
    },
    toAdminHome ({ getters }) {
      if (getters.adminHomeRoute) {
        router.push({
          name: getters.adminHomeRoute.name
        })
      } else {
        // MlModal.alert({
        //   content: '无权限访问！',
        //   status: 'warning'
        // })
      }
    },
    toCompanyHome ({ getters }) {
      if (getters.companyHomeRoute) {
        router.push({
          name: getters.companyHomeRoute.name
        })
      } else {
        // MlModal.alert({
        //   content: '无权限访问！',
        //   status: 'warning'
        // })
      }
    },
    toProjectHome ({ getters }) {
      if (getters.projectHomeRoute) {
        router.push({
          name: getters.projectHomeRoute.name
        })
      } else {
        // MlModal.alert({
        //   content: '无权限访问！',
        //   status: 'warning'
        // })
      }
    },
    /**
     * 动态生成菜单
     */
    updateViewMenu ({ commit }, menus) {
      return new Promise((resolve) => {
        const menuPermission = parseMenu(menus)
        commit('setMenus', menuPermission)
        resolve()
      })
    },
    updateCurrentViewMenu ({ dispatch, commit, rootGetters }) {
      const { branchId, projectId } = rootGetters
      if (projectId) {
        return dispatch('updateProjectMenu')
      } else if (branchId) {
        return dispatch('updateOrganizationMenu')
      }
      return Promise.resolve()
    },
    getOrganizationMenu ({ state, dispatch, commit, rootGetters }, { companyId, branchId }) {
      const menuKey = `company_${companyId || ''}_${branchId || ''}`
      if (menuKey !== state.menuKey) {
        state.menuPromise = getApiuserCompanyListMenuByBranchId({
          companyId,
          branchId,
          menuType: 1
        }).then(res => {
          commit('setMenuKey', menuKey)
          const menuPermission = parseMenu(res.datas)
          return menuPermission
        })
      }
      return state.menuPromise
    },
    /**
     * 更新公司层级菜单
     */
    updateOrganizationMenu ({ state, dispatch, commit, rootGetters }) {
      const { companyId, branchId } = rootGetters
      return dispatch('getOrganizationMenu', { companyId, branchId }).then(menuPermission => {
        commit('setMenus', menuPermission)
      })
    },
    getProjectMenu ({ state, dispatch, commit, rootGetters }, { companyId, branchId, projectId }) {
      const menuKey = `project_${companyId || ''}_${branchId || ''}_${projectId || ''}`
      if (menuKey !== state.menuKey) {
        state.menuPromise = getApiuserCompanyListMenuByProjectId({
          companyId,
          branchId,
          projectId,
          menuType: 2
        }).then(res => {
          commit('setMenuKey', menuKey)
          const menuPermission = parseMenu(res.datas)
          return menuPermission
        })
      }
      return state.menuPromise
    },
    /**
     * 更新项目层级菜单
     */
    updateProjectMenu ({ state, dispatch, commit, rootGetters }) {
      const { companyId, branchId, projectId } = rootGetters
      return dispatch('getProjectMenu', { companyId, branchId, projectId }).then(menuPermission => {
        commit('setMenus', menuPermission)
      })
    }
  }
}

export default menu

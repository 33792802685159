
import store from '@/store'
import XEUtils from 'xe-utils'

const Loading = {
  show (option) {
    const opts = XEUtils.isString(option) ? { content: option } : { ...option }
    return store.dispatch('openLoading', {
      content: opts.content || opts.title || '加载中',
      type: 'loading'
    })
  },
  hide () {
    return store.dispatch('closeLoading')
  }
}

export default Loading

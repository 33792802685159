import { requestAjax } from '@/utils/http'

/**
 * @description 图片识别文字
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApifileAccurateBasic (params, options) {
  return requestAjax({
    url: '/api-file/accurateBasic',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 保存日志
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApifileAppLogAdd (params, options) {
  return requestAjax({
    url: '/api-file/app/log/add',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 保存日志
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileAppLogAdd (data, options) {
  return requestAjax({
    url: '/api-file/app/log/add',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 银行卡识别base64
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileBankcardBase64 (data, options) {
  return requestAjax({
    url: '/api-file/bankcardBase64',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 银行卡识别
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApifileBankcard (params, options) {
  return requestAjax({
    url: '/api-file/bankcard',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description base64识别文字
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileBase64AccurateBasic (data, options) {
  return requestAjax({
    url: '/api-file/base64AccurateBasic',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 动态生成二维码
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileFilesCreateQrcode (data, options) {
  return requestAjax({
    url: '/api-file/files/createQrcode',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 删除oss文件
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApifileFilesDelete (params, options) {
  return requestAjax({
    url: '/api-file/files/delete',
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description 删除
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function deleteApifileSysCompanyFileUseInfoId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-file/sys/company/file/use/info/${id}`,
    method: 'DELETE',
    params,
    ...options
  })
}

/**
 * @description diaryQrcode
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileFilesdiaryQrcode (data, options) {
  return requestAjax({
    url: '/api-file/files-diaryQrcode',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description ESS下载生成压缩包
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileFilesEssDownloadContractZip (data, options) {
  return requestAjax({
    url: '/api-file/files/ess/downloadContractZip',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description exportCompanyWorkerAttendance
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileOpenFeginFileExportCompanyWorkerAttendance (data, options) {
  return requestAjax({
    url: '/api-file/openFegin/file/exportCompanyWorkerAttendance',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description exportListMapData
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileOpenFeginFileExportListMapData (data, options) {
  return requestAjax({
    url: '/api-file/openFegin/file/exportListMapData',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 查询
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApifileSysCompanyFileUseInfoId (params, options) {
  const { id } = params
  return requestAjax({
    url: `/api-file/sys/company/file/use/info/${id}`,
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description findFiles
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApifileFiles (params, options) {
  return requestAjax({
    url: '/api-file/files',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description generateFaceImgUrl
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApifileFilesgenerateFaceImgUrl (params, options) {
  return requestAjax({
    url: '/api-file/files-generateFaceImgUrl',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 企业获取阿里云OSS上传securityToken，及文件后缀
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApifileFilesgetOssfsTokenFileExt (params, options) {
  return requestAjax({
    url: '/api-file/files-getOssfsTokenFileExt',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 企业获取阿里云OSS上传securityToken-上传oss需要自己拼接文件后缀
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApifileFilesgetOssfsToken (params, options) {
  return requestAjax({
    url: '/api-file/files-getOssfsToken',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 个人获取阿里云OSS上传securityToken，及文件路径
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApifileFilesgetUserOssfsTokenFileExt (params, options) {
  return requestAjax({
    url: '/api-file/files-getUserOssfsTokenFileExt',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人身份证识别Base64-反面
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileIdCardOCRBackBase64 (data, options) {
  return requestAjax({
    url: '/api-file/idCardOCRBackBase64',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 工人身份证识别-反面
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApifileIdCardOCRBack (params, options) {
  return requestAjax({
    url: '/api-file/idCardOCRBack',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 工人身份证识别Base64-正面
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileIdCardOCRFrontBase64 (data, options) {
  return requestAjax({
    url: '/api-file/idCardOCRFrontBase64',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 工人身份证识别-正面
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApifileIdCardOCRFront (params, options) {
  return requestAjax({
    url: '/api-file/idCardOCRFront',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 员工渠道邀请码
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileFilesInviteChannelsQrCode (data, options) {
  return requestAjax({
    url: '/api-file/files/inviteChannelsQrCode',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 查询列表
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApifileSysCompanyFileUseInfoList (params, options) {
  return requestAjax({
    url: '/api-file/sys/company/file/use/info/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description PC登陆-获取登录二维码-返回base64
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileFilesloginBase64 (data, options) {
  return requestAjax({
    url: '/api-file/files-loginBase64',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 小程序获取登录二维码-返回base64
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileFilesloginQrCode (data, options) {
  return requestAjax({
    url: '/api-file/files-loginQrCode',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 小程序动态生成二维码
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileFilesProjectQrcode (data, options) {
  return requestAjax({
    url: '/api-file/files/projectQrcode',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description qrcode
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileFilesqrcode (data, options) {
  return requestAjax({
    url: '/api-file/files-qrcode',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 公司员工注册邀请码生成-返回base64
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileFilesregisterQrCode (data, options) {
  return requestAjax({
    url: '/api-file/files-registerQrCode',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 保存
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileSysCompanyFileUseInfoSave (data, options) {
  return requestAjax({
    url: '/api-file/sys/company/file/use/info/save',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 统计所有企业至昨日文件使用情况
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApifileSysCompanyFileUseInfoStatisticsAllCompanyFileUsage (params, options) {
  return requestAjax({
    url: '/api-file/sys/company/file/use/info/statisticsAllCompanyFileUsage',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description 统计昨日企业文件使用情况
 * @param params 查询参数
 * @param options 请求参数配置axios
 */
export function getApifileSysCompanyFileUseInfoStatisticsCompanyFileUsageByDay (params, options) {
  return requestAjax({
    url: '/api-file/sys/company/file/use/info/statisticsCompanyFileUsageByDay',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * @description uploadOss
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileFilesuploadOss (data, options) {
  return requestAjax({
    url: '/api-file/files-uploadOss',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description uploadUserAvatar
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileFilesuploadUserAvatar (data, options) {
  return requestAjax({
    url: '/api-file/files-uploadUserAvatar',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 上传文件
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileFilesanon (data, options) {
  return requestAjax({
    url: '/api-file/files-anon',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * @description 上传oss文件
 * @param data 提交参数
 * @param options 请求参数配置axios
 */
export function postApifileFilesUpload (data, options) {
  return requestAjax({
    url: '/api-file/files/upload',
    method: 'POST',
    data,
    ...options
  })
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import XEUtils from 'xe-utils'

import UserContainer from '@/views/layout/platform/UserContainer.vue'
import HomeView from '../views/HomeView.vue'

import dataManagement from './modules/dataManagement'
import subpackage from './modules/subpackage'
import machine from './modules/machine'
import financial from './modules/financial'
import business from './modules/business'
import deepDesign from './modules/deepDesign'

Vue.use(VueRouter)

const locat = XEUtils.parseUrl(location.href)
console.log(locat)

const routeModules = [
  ...dataManagement,
  ...subpackage,
  ...machine,
  ...financial,
  ...business,
  ...deepDesign
]

const routes = [
  {
    path: '/',
    redirect: {
      name: locat.searchQuery.defaultRouteName || 'HomeView'
    }
  },
  {
    path: '/404',
    name: 'PageError404',
    component: () => import('@/views/error/PageError404.vue'),
    meta: {
      title: '404'
    }
  },
  {
    path: '/403',
    name: 'PageError403',
    component: () => import('@/views/error/PageError403.vue'),
    meta: {
      title: '403'
    }
  },
  {
    path: '/homeView',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/',
    component: UserContainer,
    children: [
      {
        path: 'upgradeLog',
        name: 'UpgradeLog',
        component: () => import('@/views/upgrade/UpgradeLog.vue'),
        meta: {
          title: '更新日志'
        }
      },
      {
        path: 'privacyPolicy',
        name: 'PrivacyPolicy',
        component: () => import('@/views/user/agreement/PrivacyPolicy.vue'),
        meta: {
          title: '隐私条款'
        }
      },
      {
        path: 'termsService',
        name: 'TermsService',
        component: () => import('@/views/user/agreement/TermsService.vue'),
        meta: {
          title: '服务条款'
        }
      },
      {
        path: 'aboutUs',
        name: 'AboutUs',
        component: () => import('@/views/user/about/AboutUs.vue'),
        meta: {
          title: '关于我们'
        }
      }
    ]
  },
  ...routeModules,
  {
    path: '*',
    redirect: {
      name: 'PageError404'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import Vue from 'vue'
import MaliUI, { MaliUtils, MaliModal } from 'mali-h5-ui'
import 'mali-h5-ui/lib/mali-h5-ui.css'
import { uploadSingleFile } from '@/utils/upload'
import { deleteApifileFilesDelete } from '@/api/file'
import store from '@/store'
import XEUtils from 'xe-utils'
import Toast from './toast'
import Loading from './loading'

Vue.use(MaliUI)

Vue.prototype.$modal = MaliModal
Vue.prototype.$loading = Loading
Vue.prototype.$toast = Toast

// 码里云组件全局配置
MaliUI.config({
  // 上传组件
  upload: {
    // 配置附件默认读取的字段名
    fileProps: {
      fileUrl: 'fileUrl',
      fileType: 'fileType',
      fileName: 'fileName'
    },
    // 获取附件缩略图方法
    getThumbnailFileUrl ({ item }) {
      if (/^http/.test(item.fileUrl)) {
        return `${item.fileUrl}${item.fileUrl.indexOf('?') > -1 ? '&' : '?'}x-oss-process=image/resize,m_lfit,h_100,w_100&x-obs-process=image/resize,m_lfit,h_90,w_90`
      }
      return `${store.getters.imgApiUrl}/${item.fileUrl}?x-oss-process=image/resize,m_lfit,h_90,w_90&x-obs-process=image/resize,m_lfit,h_90,w_90`
    },
    // 获取附件路径方法
    getFileUrl ({ item }) {
      if (/^http/.test(item.fileUrl)) {
        return item.fileUrl
      }
      return `${store.getters.imgApiUrl}/${item.fileUrl}`
    },
    // 上传附件方法
    async uploadFile ({ file, params }) {
      // return { fileUrl: '' }
      const res = await uploadSingleFile(file, {
        companyId: store.getters.companyId,
        branchId: store.getters.branchId,
        projectId: store.getters.projectId,
        ...params
      })
      return {
        fileUrl: res.url
      }
    },
    // 下载附件方法
    downloadFile ({ item }) {
      const url = /^http/.test(item.fileUrl) ? item.fileUrl : `${store.getters.imgApiUrl}/${item.fileUrl}`
      return MaliUtils.saveFileByUrl(url, item.fileName)
    },
    // 删除附件方法
    async removeFile ({ item }) {
      if (item.isNew) {
        await deleteApifileFilesDelete({ path: item.fileUrl })
      }
    }
  },
  // 上拉加载更多
  loadMore: {
    pageSize: 30,
    pageProp: {
      pageSize: 'limit',
      currentPage: 'page'
    },
    resProps: {
      result ({ response }) {
        if (XEUtils.isArray(response)) {
          return response
        }
        return response.datas
      },
      total ({ response }) {
        return response.total
      }
    },
    notData: {
      emptyUrl: `${store.getters.staticUrl}/applet/business/image/not_data/empty.png`,
      resultUrl: `${store.getters.staticUrl}/applet/business/image/not_data/result.png`
    }
  },
  // 分页器
  pager: {
    pageSize: 30,
    pageProp: {
      pageSize: 'limit',
      currentPage: 'page'
    },
    resProps: {
      result ({ response }) {
        if (XEUtils.isArray(response)) {
          return response
        }
        return response.datas
      },
      total ({ response }) {
        return response.total
      }
    },
    notData: {
      emptyUrl: `${store.getters.staticUrl}/applet/business/image/not_data/empty.png`,
      resultUrl: `${store.getters.staticUrl}/applet/business/image/not_data/result.png`
    }
  },
  notData: {
    emptyUrl: `${store.getters.staticUrl}/applet/business/image/not_data/empty.png`
  }
  // 审批
  // approval: {
  //   select: {
  //     async loadFlow ({ code }) {
  //       const params = {
  //         module: code,
  //         flowSettingCode: code,
  //         fillUserFlag: true,
  //         companyId: store.getters.companyId,
  //         branchId: store.getters.branchId,
  //         organizationId: store.getters.branchId,
  //         projectId: store.getters.projectId
  //       }
  //       const res = await getApproveFlowSettingGetApproveInfo(params)
  //       const datas = res.datas
  //       const flowItems = []
  //       if (datas) {
  //         if (datas.sysApproveFlowItemSettings) {
  //           datas.sysApproveFlowItemSettings.forEach(flowItem => {
  //             flowItems.push({
  //               id: flowItem.id,
  //               name: flowItem.itemSettingName,
  //               copyRoles: flowItem.copyRoles,
  //               roleList: flowItem.handlerRoles.filter(item => item.userList && item.userList.length > 0) // 有用户的角色才显示可选
  //             })
  //           })
  //         }
  //         return {
  //           enableFlag: datas.enableFlag,
  //           flowSettingCode: datas.flowSettingCode,
  //           module: datas.module,
  //           flowItems
  //         }
  //       }
  //       return {
  //         enableFlag: false,
  //         flowSettingCode: code,
  //         module: code,
  //         flowItems
  //       }
  //     },
  //     // 获取已提交的审批节点，用于驳回重载
  //     async updateFlowItems ({ sysApproveJobId, flowItems }) {
  //       const res = await getSysApproveFlowGetFlowItems({ sysApproveJobId })
  //       const datas = res.datas
  //       console.log('flowItems===XX:', flowItems)
  //       const newFlowItem = flowItems.map(item => {
  //         const confirmItem = datas.find(ele => item.id === ele.flowItemSettingId)
  //         return {
  //           ...item,
  //           users: (confirmItem?.userList || []).map(userItem => ({
  //             // 角色ID_用户ID
  //             id: `${userItem.approveRoles?.roleId}_${userItem.id}`,
  //             name: userItem.nickname,
  //             faceImgUrl: userItem.faceImgUrl
  //           }))
  //         }
  //       })
  //       return newFlowItem
  //     }
  //   },
  //   record: {
  //     async loadFlow (params) {
  //       const res = await Promise.all([
  //         getSysApproveFlowGetFlowItems(params),
  //         getSysApproveFlowGetSpecialAllFlowItems(params)
  //       ])
  //       const [res1, res2] = res
  //       return { flowConfigItems: res1.datas || [], specialAllFlowItems: res2.datas || [] }
  //     },
  //     // 提交审批
  //     async submitApprove (params) {
  //       const res = await postSysApproveFlowExecuteApprove(params)
  //       return res
  //     },
  //     // 获取当前用户，用于判断是否可审批
  //     getCurrentUserInfo () {
  //       return store.getters.userInfo
  //     }
  //   },
  //   listSelectUserPage: {
  //     defaultAvatarUrl: `${}/applet/business/image/portrait2.png`
  //   }
  // }
})

let loadingTime = null
let messageTime = null

const toast = {
  state: {
    loadingToast: {
      visible: false
    },
    messageToast: {
      visible: false
    }
  },
  getters: {
    loadingToast: (state) => state.loadingToast,
    messageToast: (state) => state.messageToast
  },
  mutations: {
    setLoading (state, options) {
      state.loadingToast = options
    },
    setToast (state, options) {
      state.messageToast = options
    }
  },
  actions: {
    openLoading ({ commit }, options) {
      return new Promise(resolve => {
        if (loadingTime) {
          resolve()
          return
        }
        const opts = {
          ...options,
          visible: true
        }
        commit('setLoading', opts)
      })
    },
    closeLoading ({ commit }) {
      return new Promise(resolve => {
        if (loadingTime) {
          clearTimeout(loadingTime)
          loadingTime = null
        }
        commit('setLoading', {
          visible: false
        })
        setTimeout(() => resolve(), 50)
      })
    },
    openToast ({ commit, dispatch }, options) {
      return new Promise(resolve => {
        if (messageTime) {
          resolve()
          return
        }
        const opts = {
          ...options,
          visible: true
        }
        commit('setToast', opts)
        if (opts.duration !== -1) {
          messageTime = setTimeout(() => {
            dispatch('closeToast').then(() => resolve())
          }, opts.duration || 2000)
        }
      })
    },
    closeToast ({ commit }) {
      return new Promise(resolve => {
        if (messageTime) {
          clearTimeout(messageTime)
          messageTime = null
        }
        commit('setToast', {
          visible: false
        })
        setTimeout(() => resolve(), 50)
      })
    }
  }
}

export default toast

import { MaliStorage } from 'mali-h5-ui'
import XEUtils from 'xe-utils'

const locat = XEUtils.parseUrl(location.href)

const view = {
  state: {
    selectCompanyId: locat.searchQuery.companyId || MaliStorage.getItem('SELECT_COMPANY_ID') || '',
    selectBranchId: locat.searchQuery.branchId || MaliStorage.getItem('SELECT_BRANCH_ID') || '',
    selectProjectId: locat.searchQuery.projectId || MaliStorage.getItem('SELECT_PROJECT_ID') || '',
    selectProjectInfo: null,
    selectOrgInfo: null,
    selectOrgList: []
  },
  getters: {
    selectCompanyId: state => state.selectCompanyId,
    selectBranchId: state => state.selectBranchId,
    selectProjectId: state => state.selectProjectId,
    selectCompanyInfo: (state, getters, rootState, rootGetters) => {
      return rootGetters.companyList.find(item => item.companyId === state.selectCompanyId)
    },
    selectBranchInfo: (state, getters) => state.selectOrgInfo,
    selectProjectInfo: state => state.selectProjectInfo,
    selectOrgInfo: (state) => state.selectOrgInfo,
    companyId: state => state.selectCompanyId,
    branchId: state => state.selectBranchId,
    projectId: state => state.selectProjectId
  },
  mutations: {
    setSelectPageView (state, { companyId = '', branchId = '', projectId = '' }) {
      state.selectCompanyId = companyId
      state.selectBranchId = branchId
      state.selectProjectId = projectId
      if (state.selectCompanyId) {
        MaliStorage.setItem('SELECT_COMPANY_ID', state.selectCompanyId)
      } else {
        MaliStorage.removeItem('SELECT_COMPANY_ID')
      }
      if (state.selectBranchId) {
        MaliStorage.setItem('SELECT_BRANCH_ID', state.selectBranchId)
      } else {
        MaliStorage.removeItem('SELECT_BRANCH_ID')
      }
      if (state.selectProjectId) {
        MaliStorage.setItem('SELECT_PROJECT_ID', state.selectProjectId)
      } else {
        state.selectProjectInfo = null
        MaliStorage.removeItem('SELECT_PROJECT_ID')
      }
    },
    setSelectProjectInfo (state, item) {
      state.selectProjectInfo = item
    },
    setSelectOrganizationInfo (state, item) {
      state.selectOrgInfo = item
    }
  },
  actions: {
    clearPageView ({ commit }) {
      commit('setSelectPageView', { })
    }
  }
}

export default view

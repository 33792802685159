import { requestAjax } from '@/utils/http'
import { getClientAuthorization } from '@/utils/util'

// 登录
export function userLogin (params) {
  return requestAjax({
    url: '/api-uaa/oauth/token',
    method: 'post',
    params: { grant_type: 'password_code', ...params },
    headers: {
      Authorization: `Basic ${getClientAuthorization()}`
    }
  })
}

// 退出
export function userLogout (token) {
  return requestAjax({
    url: '/api-uaa/oauth/remove/token',
    method: 'post',
    data: { access_token: token, redirect_uri: '' }
  })
}

export function removeUserToken (data) {
  return requestAjax({
    url: '/api-uaa/oauth/remove/token',
    method: 'post',
    data
  })
}

import axios from 'axios'
import store from '@/store'
import XEUtils from 'xe-utils'

export function createHttp (baseURL) {
  // 创建 axios 实例
  const request = axios.create({
    // API 请求的默认前缀
    baseURL: baseURL || process.env.VUE_APP_API_BASE_URL,
    timeout: 20000 // 请求超时时间
  })

  // 异常拦截处理器
  const errorHandler = error => {
    if (error.response) {
      const SERVICE_ERROR_REGEX = /^5/
      const data = error.response.data
      // 从 localstorage 获取 token
      const token = store.getters.token
      if (error.response.status === 403) {
        // notification.error({
        //   message: 'Forbidden',
        //   description: data.message
        // })
      }
      if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
        if (token) {
        //   notification.error({
        //     message: '系统提示',
        //     description: '登录状态过期，请重新登录！'
        //   })
          store.dispatch('logoutSystem')
        }
      }
      // 5 开头的服务器错误
      if (SERVICE_ERROR_REGEX.test(error.response.status)) {
        const { config, data, status, statusText } = error.response
        const description = `URL ☛ ${config?.url} \n MESSAGE ☛ ${data?.message}`
        console.error(status, statusText, description)
        // notification.error({
        //   message: `系统提示 ${status}`,
        //   description: '无权限操作'
        // })
        // notification.error({
        //   message: `${status} ${statusText}`,
        //   description
        // })
      }
    }
    return Promise.reject(error)
  }

  // request interceptor
  request.interceptors.request.use(config => {
    const token = store.getters.token
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    const defHeaders = {}
    if (token) {
      defHeaders.Authorization = `Bearer ${token}`
    }
    // 改头部参数用于开发调试
    if (process.env.NODE_ENV === 'development') {
      // defHeaders['saas-version'] = 'zhangcaibao'
    }
    config.headers = Object.assign({}, defHeaders, config.headers)

    // 处理空值
    let dataParams = config.params
    if (/^get$/i.test(config.method) && XEUtils.isPlainObject(config.params)) {
      dataParams = {}
      XEUtils.each(config.params, (value, key) => {
        if (value !== null && value !== undefined && value !== '') {
          dataParams[key] = value
        }
      })
      config.params = dataParams
    }

    return config
  }, errorHandler)

  // response interceptor
  request.interceptors.response.use(response => {
    const { data, config: { responseType }, headers } = response
    if (responseType === 'blob') {
      const { 'content-disposition': contentDisposition } = headers
      const pair = decodeURI(contentDisposition).split('=')
      const filename = pair[1]
      return {
        filename,
        data
      }
    }
    // 统一判断请求成功了但 code 不是 200 的情况，流转到 catch 里
    if (data.code !== 200 && data.code !== 120) { // 120,扫码登录监听
      // notification.error({
      //   message: `系统提示 ${data.code}`,
      //   description: data.message
      // })
      return Promise.reject(data)
    }
    return data
  }, errorHandler)

  return request
}

export const requestAjax = createHttp(process.env.VUE_APP_API_BASE_URL)

// 仅用于本地调试
if (process.env.NODE_ENV === 'development') {
  window.requestAjax = requestAjax
}

<template>
  <div class="page-layout" :class="{'is-background': background}">
    <div class="page-layout-warpper" :style="styles">
      <slot></slot>
    </div>

    <!--全局提示-->
    <ml-toast :value="pageLoading" type="loading" content="加载中" />
    <ml-toast :value="loadingToast.visible || false" :type="loadingToast.type || ''" :content="loadingToast.content || ''" />
    <ml-toast :value="messageToast.visible || false" :type="messageToast.type || ''" :content="messageToast.content || ''" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PageLayout',
  props: {
    loading: Boolean,
    background: Boolean,
    paddingTop: String,
    paddingBottom: String
  },
  provide () {
    return {
      $pageRootVM: this.$parent,
      $pageLayout: this
    }
  },
  data () {
    return {
      pullLoading: false,
      compPaddingTop: null,
      compPaddingBottom: null,
      pages: []
    }
  },
  computed: {
    ...mapGetters([
      'token',
      'isH5',
      'isQWMP',
      'isDDMP',
      'isWXMP',
      'wxKfUrl',
      'qwAuthInfo',
      'showKfPopup',
      'loadingToast',
      'messageToast',
      'pageLoading',
      'userInfo',
      'watermarkText',
      'companyOrderInfo',
      'showAuthExpirePopup',
      'showQwAuthExpirePopup'
    ]),
    styles () {
      let tSys = ''
      if (this.paddingTop && this.compPaddingTop) {
        tSys = `padding-top: calc(${this.paddingTop} + ${this.compPaddingTop});`
      } else {
        const pTop = this.paddingTop || this.compPaddingTop
        tSys = pTop ? `padding-top: ${pTop};` : ''
      }
      let bSys = ''
      if (this.paddingBottom && this.compPaddingBottom) {
        bSys = `padding-bottom: calc(${this.paddingBottom} + ${this.compPaddingBottom});`
      } else {
        const pBottom = this.paddingBottom || this.compPaddingBottom
        bSys = pBottom ? `padding-bottom: ${pBottom};` : ''
      }
      return `${tSys}${bSys}`
    }
  },
  watch: {
    loading (value) {
      if (value) {
        this.$loading.show()
      } else {
        this.$loading.hide()
      }
    }
  },
  created () {
    if (this.loading) {
      this.$loading.show()
    } else {
      this.$loading.hide()
    }
  },
  beforeDestroy () {
    this.clearPadding()
    this.$loading.hide()
  },
  methods: {
    ...mapActions([
      'initLogin',
      'openShowKfPopup',
      'closeShowKfPopup',
      'updateCurrentUserInfo',
      'updateQWCorpAuthModeInfo'
    ]),
    changeKfEvent ({ value }) {
      if (!value) {
        this.closeShowKfPopup(value)
      }
    },
    clearPadding () {
      this.compPaddingTop = null
      this.compPaddingBottom = null
    },
    setPaddingTop (val) {
      this.compPaddingTop = val
    },
    setPaddingBottom (val) {
      this.compPaddingBottom = val
    }
  }
}
</script>

<style lang="scss">
@import 'mali-h5-ui/style/variable.scss';

.page-layout {
  color: $ml-font-color;
  font-size: $ml-base-font-size;
  height: 100%;
  &.is-background {
    background: #ffffff;
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

import mToast from './modules/toast.js'
import custom from './modules/custom.js'
import app from './modules/app'
import user from './modules/user'
import menu from './modules/menu'
import url from './modules/url'
import project from './modules/project'
import view from './modules/view'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    mToast,
    custom,
    app,
    user,
    menu,
    url,
    project,
    view
  },
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  }
})

const platform = {
  state: {
    // 私有化版本名称
    customModuleName: process.env.VUE_APP_CUSTOM_MODULE,
    // 自有定制化版本-劳务
    isLabourView: process.env.VUE_APP_CUSTOM_MODULE === 'labour',
    // 私有化版本-北装
    isBeizhuangView: process.env.VUE_APP_CUSTOM_MODULE === 'beizhuang',
    // 私有化版本-中建一局
    isZhongjian1View: process.env.VUE_APP_CUSTOM_MODULE === 'zhongjian1',
    // 私有化版本-恒坤
    isHengkunView: process.env.VUE_APP_CUSTOM_MODULE === 'hengkun'
  },
  getters: {
    // 演示环境
    isDemoView: () => process.env.VUE_APP_MODE === 'demo',
    customModuleName: (state) => state.customModuleName,
    isLabourView: (state) => state.isLabourView,
    isBeizhuangView: (state) => state.isBeizhuangView,
    isZhongjian1View: (state) => state.isZhongjian1View,
    isHengkunView: (state) => state.isHengkunView
  },
  mutations: {
  },
  actions: {
  }
}

export default platform

import ProjectContainer from '@/views/layout/platform/ProjectContainer.vue'

/**
 * 商务管理
 */
export default [
  // 项目层级
  {
    path: '/project/business',
    component: ProjectContainer,
    children: [
      // 项目层级-工程概况
      {
        path: 'projectProfile',
        name: 'ProjectBusinessEconomicProjectProfile',
        component: () => import('@/views/project/business/economic/projectProfile/ProjectProfile.vue'),
        meta: {
          title: '工程概况'
        }
      },
      // 项目层级-计划实际
      {
        path: 'planComparison',
        name: 'ProjectBusinessEconomicPlanDiffList',
        component: () => import('@/views/project/business/economic/planComparison/PlanComparison.vue'),
        meta: {
          title: '计划实际'
        }
      },
      // 项目层级-甲方合同
      {
        path: 'partyContract',
        name: 'ProjectBusinessEarningPartyContract',
        component: () => import('@/views/project/business/earning/partyContract/PartyContract.vue'),
        meta: {
          title: '甲方合同'
        }
      },
      // 项目层级-新增甲方合同
      {
        path: 'addContract',
        name: 'ProjectBusinessEarningAddContract',
        component: () => import('@/views/project/business/earning/partyContract/AddContract.vue'),
        meta: {
          title: '新增甲方合同'
        }
      },
      // 项目层级-甲方合同详情
      {
        path: 'contractDetail',
        name: 'ProjectBusinessEarningContractDetail',
        component: () => import('@/views/project/business/earning/partyContract/ContractDetail.vue'),
        meta: {
          title: '甲方合同详情'
        }
      },
      // 项目层级-签证变更
      {
        path: 'visaChange',
        name: 'ProjectBusinessEarningVisaList',
        component: () => import('@/views/project/business/earning/visaChange/VisaChange.vue'),
        meta: {
          title: '签证变更'
        }
      },
      // 项目层级-新增签证变更
      {
        path: 'addVisaChange',
        name: 'ProjectBusinessEarningAddVisaChange',
        component: () => import('@/views/project/business/earning/visaChange/AddVisaChange.vue'),
        meta: {
          title: '新增签证变更'
        }
      },
      // 项目层级-签证变更详情
      {
        path: 'projectDetail',
        name: 'ProjectBusinessEarningProjectDetail',
        component: () => import('@/views/project/business/earning/visaChange/ProjectDetail.vue'),
        meta: {
          title: '签证变更详情'
        }
      },
      // 项目层级-报甲方量
      {
        path: 'amountNumber',
        name: 'ProjectBusinessEarningAmountNumber',
        component: () => import('@/views/project/business/earning/amountNumber/AmountNumber.vue'),
        meta: {
          title: '报甲方量'
        }
      },
      // 项目层级-新增报甲方量
      {
        path: 'addReportedVolume',
        name: 'ProjectBusinessEarningAddReportedVolume',
        component: () => import('@/views/project/business/earning/amountNumber/AddReportedVolume.vue'),
        meta: {
          title: '新增报甲方量'
        }
      },
      // 项目层级-报甲方量详情
      {
        path: 'reportedVolumeDetails',
        name: 'ProjectBusinessEarningReportedVolumeDetails',
        component: () => import('@/views/project/business/earning/amountNumber/ReportedVolumeDetails.vue'),
        meta: {
          title: '报甲方量详情'
        }
      },
      // 项目层级-收款台账
      {
        path: 'accountProceeds',
        name: 'ProjectBusinessEarningProceedsList',
        component: () => import('@/views/project/business/earning/accountProceeds/AccountProceeds.vue'),
        meta: {
          title: '收款台账'
        }
      },
      // 项目层级-收款台账-详情
      {
        path: 'accountProceedsDetail',
        name: 'ProjectBusinessEarningProceedsDetail',
        component: () => import('@/views/project/business/earning/accountProceeds/AccountProceedsDetail.vue'),
        meta: {
          title: '收款台账详情'
        }
      },
      // 项目层级-开具发票
      {
        path: 'invoiceList',
        name: 'ProjectBusinessEarningReceiptInvoice',
        component: () => import('@/views/project/business/earning/invoice/InvoiceList.vue'),
        meta: {
          title: '开具发票'
        }
      },
      // 项目层级-开具发票-详情
      {
        path: 'invoiceDetail',
        name: 'ProjectBusinessEarningReceiptInvoiceDetail',
        component: () => import('@/views/project/business/earning/invoice/InvoiceDetail.vue'),
        meta: {
          title: '开具发票详情'
        }
      },
      // 项目层级-项目决算
      {
        path: 'accountingList',
        name: 'ProjectBusinessEarningAccountingList',
        component: () => import('@/views/project/business/earning/accounting/AccountingList.vue'),
        meta: {
          title: '项目决算'
        }
      },
      // 项目层级-措施费
      {
        path: 'measureCost',
        name: 'ProjectBusinessExpenditureConstructionExpenses',
        component: () => import('@/views/project/business/expenditure/measureCost/MeasureCost.vue'),
        meta: {
          title: '措施费'
        }
      },
      // 项目层级-直接工程费
      {
        path: 'engineeringCost',
        name: 'ProjectBusinessExpenditureProjectExpenses',
        component: () => import('@/views/project/business/expenditure/engineeringCost/EngineeringCost.vue'),
        meta: {
          title: '直接工程费'
        }
      },
      // 项目层级-现场管理费
      {
        path: 'sceneAdministrativeFee',
        name: 'ProjectBusinessExpenditureSceneExpenses',
        component: () => import('@/views/project/business/expenditure/sceneAdministrativeFee/SceneAdministrativeFee.vue'),
        meta: {
          title: '现场管理费'
        }
      },
      // 项目层级-其他费用
      {
        path: 'otherExpenses',
        name: 'ProjectBusinessExpenditureOtherExpenses',
        component: () => import('@/views/project/business/expenditure/otherExpenses/OtherExpenses.vue'),
        meta: {
          title: '其他费用'
        }
      },
      // 项目层级-总包服务费
      {
        path: 'lumpSumServiceCharge',
        name: 'ProjectBusinessExpenditureTotalServiceExpenses',
        component: () => import('@/views/project/business/expenditure/lumpSumServiceCharge/LumpSumServiceCharge.vue'),
        meta: {
          title: '总包服务费'
        }
      },
      // 项目层级-税金
      {
        path: 'expenditureTaxes',
        name: 'ProjectBusinessExpenditureTaxesExpenses',
        component: () => import('@/views/project/business/expenditure/taxes/ExpenditureTaxes.vue'),
        meta: {
          title: '税金'
        }
      },
      // 项目层级-总计划成本
      {
        path: 'planCost',
        name: 'ProjectBusinessCostPlanCost',
        component: () => import('@/views/project/business/const/planCost/planCost.vue'),
        meta: {
          title: '总计划成本'
        }
      },
      // 项目层级-项目成本管理
      {
        path: 'projectCostManagement',
        name: 'ProjectBusinessCostCostManage',
        component: () => import('@/views/project/business/const/projectCostManagement/ProjectCostManagement.vue'),
        meta: {
          title: '项目成本管理'
        }
      }
    ]
  }
]

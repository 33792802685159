<template>
  <div>
    <h1>不小心出错了，请重新打开小程序试一下！</h1>
  </div>
</template>

<script>
export default {
}
</script>

import OSS from 'ali-oss'
import { MaliUtils } from 'mali-h5-ui'
import { getApifileFilesgetOssfsToken } from '@/api/file'

/**
 * 阿里云上传附件统一方法
 * @param {*} file
 * @param {*} params
 */
export async function uploadAliFile (file, params) {
  const res1 = await getApifileFilesgetOssfsToken(params)
  const ossToken = res1.datas
  const client = new OSS({
    region: ossToken.region,
    accessKeyId: ossToken.accessKeyId,
    accessKeySecret: ossToken.accessKeySecret,
    bucket: ossToken.bucketName,
    stsToken: ossToken.securityToken
  })
  return client.put(`${ossToken.uploadPath}${MaliUtils.getFileSuffix(file.name)}`, file).then(res => {
    return {
      url: res.name
    }
  })
}

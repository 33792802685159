import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress'

// 白名单路由（游客）
const publicRouteList = ['PrivacyPolicy', 'TermsService']

// 用户白名单路由（登陆）
const userWhiteRouteList = []

// 公司白名单路由（登陆）
const companyWhiteRouteList = []

// 项目白名单路由（登陆）
const projectWhiteRouteList = []

const handlePermission = (to, from, next) => {
  const { selectBranchId, selectProjectId } = store.getters

  console.log(`路由跳转：name=${to.name}`)

  // 如果是用户白名单路由
  if (userWhiteRouteList.includes(to.name)) {
    next()
    NProgress.done()
    return
  }

  if (/^\/project\//.test(to.path)) {
    // 如果项目层级
    if (!selectProjectId) {
      next({
        name: 'PageError403',
        replace: true
      })
      NProgress.done()
      return
    }
    store.dispatch('updateProjectMenu').then(() => {
      // 如果是项目白名单路由
      if (projectWhiteRouteList.includes(to.name)) {
        store.dispatch('updateSelectProjectInfo').then(() => {
          next()
          NProgress.done()
        })
        return
      }

      store.dispatch('updateSelectProjectInfo').then(() => {
        next()
        NProgress.done()
      })
    })
  } else if (/^\/company\//.test(to.path)) {
    // 如果是公司层级
    if (!selectBranchId) {
      next({
        name: 'PageError403',
        replace: true
      })
      NProgress.done()
    }
    store.dispatch('updateOrganizationMenu').then(() => {
      // 如果是公司白名单路由
      if (companyWhiteRouteList.includes(to.name)) {
        next()
        NProgress.done()
        return
      }

      next()
      NProgress.done()
    })
  } else {
    next()
    NProgress.done()
  }
}

router.beforeEach((to, from, next) => {
  if (publicRouteList.includes(to.name)) {
    next()
    return
  }
  NProgress.start()
  // 判断是否已登录
  if (!store.getters.token) {
    next()
    NProgress.done()
    return
  }
  if (store.getters.userInfo) {
    handlePermission(to, from, next)
  } else {
    store.dispatch('initLogin').then(() => {
      handlePermission(to, from, next)
    }).catch(() => {
      // 如果未登陆登录
      next({
        // 企微/钉钉
        name: store.getters.isQWH5 || store.getters.isDDH5 ? 'PageErrorQwdd' : 'UserLogin'
      })
      NProgress.done()
    })
  }
})

router.afterEach((to) => {
  document.title = to.meta?.title || process.env.VUE_APP_TITLE
  NProgress.done()
})

import ProjectContainer from '@/views/layout/platform/ProjectContainer.vue'

/**
 * 专业分包
 */
export default [
  // 项目层级
  {
    path: '/project/subpackage',
    component: ProjectContainer,
    children: [
      {
        path: 'contractList',
        name: 'ProjectSubpackageContractList',
        component: () => import('@/views/project/subpackage/contract/ContractList.vue'),
        meta: {
          title: '合同列表'
        }
      }
    ]
  }
]


import store from '@/store'
import XEUtils from 'xe-utils'

const Toast = {
  success (option = {}) {
    const opts = XEUtils.isString(option) ? { content: option } : { ...option }
    return store.dispatch('openToast', {
      content: opts.content || opts.title || '操作成功',
      type: 'success',
      duration: opts.duration
    })
  },
  error (option = {}) {
    const opts = XEUtils.isString(option) ? { content: option } : { ...option }
    return store.dispatch('openToast', {
      content: opts.content || opts.title || '操作失败',
      type: 'error',
      duration: opts.duration
    })
  },
  warning (option = {}) {
    const opts = XEUtils.isString(option) ? { content: option } : { ...option }
    return store.dispatch('openToast', {
      content: opts.content || opts.title,
      type: 'warning',
      duration: opts.duration
    })
  },
  info (option = {}) {
    const opts = XEUtils.isString(option) ? { content: option } : { ...option }
    return store.dispatch('openToast', {
      content: opts.content || opts.title,
      type: 'info',
      duration: opts.duration
    })
  },
  show (option = {}) {
    const opts = XEUtils.isString(option) ? { content: option } : { ...option }
    return store.dispatch('openToast', {
      content: opts.content || opts.title,
      type: opts.type || '',
      duration: opts.duration
    })
  },
  hide () {
    return store.dispatch('closeToast')
  }
}

export default Toast

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 安装插件
import './plugins'

// 路由权限
import './router/permission'

// 全局样式/主题
import './style/layout.scss'

Vue.config.productionTip = false

// 仅用于开发环境调试
if (process.env.NODE_ENV === 'development') {
  window.router = router
  window.store = store
}
// 仅用于开发环境调试

// 主页面 loading
setTimeout(() => {
  const loadingEl = document.getElementById('loadingWrp')
  if (loadingEl) {
    loadingEl.style.display = 'none'
  }
}, 600)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import { MaliStorage } from 'mali-h5-ui'
import { getApiuserOrganizationCompanyGetComboOrderInfo, getApiuserApiV1ManageCompanyUserInfo, getApiuserApiV1ThirdPartyUserUserInfoCorpId } from '@/api/user'
import { removeUserToken } from '@/api/login'
import XEUtils from 'xe-utils'

const locat = XEUtils.parseUrl(location.href)

const user = {
  state: {
    token: locat.searchQuery.token || MaliStorage.getItem('TOKEN'),
    userInfo: null,
    companyOrderInfo: null,
    companyList: []
  },
  getters: {
    token: (state) => state.token,
    companyOrderInfo: (state) => state.companyOrderInfo,
    userInfo: (state) => state.userInfo,
    companyList: (state) => state.companyList
  },
  mutations: {
    setToken (state, { access_token: accessToken, expires_in: expiresIn }) {
      MaliStorage.setItem('TOKEN', accessToken, { expires: Date.now() + (XEUtils.toNumber(expiresIn) * 1000) })
      state.token = accessToken
    },
    clearToken (state) {
      MaliStorage.removeItem('TOKEN')
      state.token = ''
    },
    setCompanyList: (state, companyList) => {
      state.companyList = companyList
    },
    setCompanyOrderInfo (state, data) {
      state.companyOrderInfo = data
    },
    setUserInfo: (state, obj) => {
      state.userInfo = obj
    }
  },
  actions: {
    logoutSystem ({ commit, state }) {
      return new Promise(resolve => {
        commit('clearToken')
        removeUserToken({
          access_token: state.token
        }).catch(() => {
          resolve()
        })
      })
    },
    initLogin ({ dispatch, commit, rootGetters }) {
      return dispatch('initCurrentUserInfo')
    },
    updateCompanyOrderInfo ({ dispatch, commit, rootGetters }) {
      if (!rootGetters.companyId) {
        return Promise.resolve()
      }
      return getApiuserOrganizationCompanyGetComboOrderInfo({ companyId: rootGetters.companyId }).then(res => {
        commit('setCompanyOrderInfo', res.datas)
      })
    },
    getCompanyUserInfo ({ state, commit, dispatch, rootGetters }) {
      if (rootGetters.isDDH5 || rootGetters.isQWH5) {
        if (rootGetters.corpId) {
          return getApiuserApiV1ThirdPartyUserUserInfoCorpId({ corpId: rootGetters.corpId }).then(res => {
            const companyList = res.datas.companyList || []
            return {
              ...res.datas,
              companyList
            }
          })
        }
        return Promise.resolve({})
      }
      return getApiuserApiV1ManageCompanyUserInfo().then(res => {
        const companyList = res.datas.companyList || []
        return {
          ...res.datas,
          companyList
        }
      })
    },
    // 更新用户信息
    updateCurrentUserInfo ({ dispatch, commit, rootGetters }) {
      return dispatch('getCompanyUserInfo').then(data => {
        console.log('getCompanyUserInfo', data)
        const companyList = data.companyList || []
        companyList.forEach(companyInfo => {
          if (!companyInfo.branchCompanyList) {
            companyInfo.branchCompanyList = []
          }
          companyInfo.branchCompanyList.forEach(branchInfo => {
            branchInfo.companyId = companyInfo.companyId
            branchInfo.companyName = companyInfo.companyName
          })
        })
        commit('setUserInfo', data)
        commit('setCompanyList', companyList)
        // 如果是超级管理员
        if (rootGetters.isAdminUser) {
          dispatch('updateViewMenu', data.menus)
        }
      })
    },
    // 初始化用户信息
    initCurrentUserInfo ({ dispatch, commit, rootGetters }) {
      return dispatch('getCompanyUserInfo').then(data => {
        const result = {
          datas: data
        }
        const companyList = data.companyList || []
        companyList.forEach(companyInfo => {
          if (!companyInfo.branchCompanyList) {
            companyInfo.branchCompanyList = []
          }
          companyInfo.branchCompanyList.forEach(branchInfo => {
            branchInfo.companyId = companyInfo.companyId
            branchInfo.companyName = companyInfo.companyName
          })
        })
        let currCompany
        let currOrg

        if (rootGetters.selectCompanyId) {
          // 如果选择了公司
          currCompany = companyList.find(item => item.userType !== 3 && item.companyId === rootGetters.selectCompanyId)
          if (currCompany) {
            if (rootGetters.branchId) {
              currOrg = currCompany.branchCompanyList.find(item => item.branchId === rootGetters.branchId)
            }
          }
        }
        // debugger
        if (!currCompany) {
          // 如果没选择公司，默认选择第一个有权限的公司
          currCompany = companyList.find(item => item.userType !== 3 && item.branchCompanyList && item.branchCompanyList.some(item => item.type === 1))
        }
        if (currCompany) {
          if (!currOrg) {
            currOrg = currCompany.branchCompanyList.find(item => item.type === 1)
          }
          // 当前选择公司已过期，跳转至选择公司界面
          if (currCompany.orderState === 2) {
            dispatch('clearPageView')
            return
          }
        }
        commit('setUserInfo', data)
        commit('setCompanyList', companyList)

        // #ifdef H5
        // 用于线上调试，判断指定名字
        const nameList = ['%E5%BE%90%E8%89%AF%E5%B1%95', '%E9%BB%84%E6%99%BA%E8%83%BD', '%E5%91%A8%E9%B9%8F%E7%A8%8B']
        if (nameList.includes(decodeURIComponent(data.nickname))) {
          const VConsole = require('vconsole')
          const vConsole = new VConsole()
          console.log(vConsole)
        }
        // #endif

        if (currOrg) {
          commit('setSelectOrganizationInfo', currOrg)
          if (rootGetters.selectProjectId) {
            commit('setSelectPageView', {
              companyId: currOrg.companyId,
              branchId: currOrg.branchId,
              projectId: rootGetters.selectProjectId
            })
            dispatch('updateCompanyOrderInfo')
            return dispatch('updateSelectProjectInfo').then(() => {
              return result
            })
          } else {
            commit('setSelectPageView', {
              companyId: currOrg.companyId,
              branchId: currOrg.branchId
            })
            dispatch('updateCompanyOrderInfo')
          }
        } else if (currCompany) {
          commit('setSelectPageView', {
            companyId: currCompany.companyId
          })
          dispatch('updateCompanyOrderInfo')
        } else {
          console.log(9999900000)
          dispatch('clearPageView')
        }

        return result
      })
    }
  }
}

export default user

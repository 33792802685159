import { getApiuserApiV1ManageRightListV1, getApiuserCompanyprojectId } from '@/api/user'

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    getProjectListByOrganization ({ commit }, { companyId, branchId }) {
      return getApiuserApiV1ManageRightListV1({
        companyId,
        branchId,
        page: 1,
        limit: 10
      }).then(res => {
        return res.datas
      }).catch(e => {
        return []
      })
    },
    getCurrentProjectList ({ commit }, params) {
      return getApiuserApiV1ManageRightListV1(params).catch(e => {
        return { datas: [] }
      })
    },
    updateSelectProjectInfo ({ commit, state, rootGetters }) {
      return new Promise((resolve) => {
        const { setSelectProjectInfo } = rootGetters
        if (setSelectProjectInfo && setSelectProjectInfo.id) {
          resolve(setSelectProjectInfo)
        } else {
          getApiuserCompanyprojectId({ id: rootGetters.projectId }).then(res => {
            commit('setSelectProjectInfo', res.datas)
            resolve()
          }).catch(e => {
            resolve()
          })
        }
      })
    }
  }
}

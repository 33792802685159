import CompanyContainer from '@/views/layout/platform/CompanyContainer.vue'
import ProjectContainer from '@/views/layout/platform/ProjectContainer.vue'

/**
 * 资料模块
 */
export default [
  // 公司层级
  {
    path: '/company/dataManagement',
    component: CompanyContainer,
    children: [
      // 对内资料-公司层级列表
      {
        path: 'internalData',
        name: 'CompanyDataManagementInternalData',
        component: () => import('@/views/company/dataManagement/internal/InternalData.vue'),
        meta: {
          title: '对内资料'
        }
      },
      // 对内资料-项目层级列表
      {
        path: 'projectInternalData',
        name: 'CompanyDataManagementProjectInternalData',
        component: () => import('@/views/company/dataManagement/internal/ProjectInternalData.vue'),
        meta: {
          title: '对内资料'
        }
      },
      {
        path: 'internalDetailList',
        name: 'CompanyDataManagementInternalDetailList',
        component: () => import('@/views/company/dataManagement/internal/InternalDetailList.vue'),
        meta: {
          title: '对内资料'
        }
      },
      // 对外资料-公司层级列表
      {
        path: 'externalData',
        name: 'CompanyDataManagementExternalData',
        component: () => import('@/views/company/dataManagement/external/ExternalData.vue'),
        meta: {
          title: '对外资料'
        }
      },
      // 对外资料-项目层级列表
      {
        path: 'projectExternalData',
        name: 'CompanyDataManagementProjectExternalData',
        component: () => import('@/views/company/dataManagement/external/ProjectExternalData.vue'),
        meta: {
          title: '对外资料'
        }
      },
      {
        path: 'externalDetailList',
        name: 'CompanyDataManagementExternalDetailList',
        component: () => import('@/views/company/dataManagement/external/ExternalDetailList.vue'),
        meta: {
          title: '对外资料'
        }
      }
    ]
  },
  // 项目层级
  {
    path: '/project/dataManagement',
    component: ProjectContainer,
    children: [
      {
        path: 'internalData',
        name: 'ProjectDataManagementInternalData',
        component: () => import('@/views/project/dataManagement/internal/InternalData.vue'),
        meta: {
          title: '对内资料'
        }
      },
      {
        path: 'newInternalData',
        name: 'ProjectDataManagementNewInternalData',
        component: () => import('@/views/project/dataManagement/internal/NewInternalData.vue'),
        meta: {
          title: ''
        }
      },
      {
        path: 'internalDataDetail',
        name: 'ProjectDataManagementInternalDataDetail',
        component: () => import('@/views/project/dataManagement/internal/InternalDataDetail.vue'),
        meta: {
          title: ''
        }
      },
      {
        path: 'ExternalData',
        name: 'ProjectDataManagementExternalData',
        component: () => import('@/views/project/dataManagement/external/ExternalData.vue'),
        meta: {
          title: '对外资料'
        }
      },
      {
        path: 'newExternalData',
        name: 'ProjectDataManagementNewExternalData',
        component: () => import('@/views/project/dataManagement/external/NewExternalData.vue'),
        meta: {
          title: ''
        }
      },
      {
        path: 'externalDataDetail',
        name: 'ProjectDataManagementExternalDataDetail',
        component: () => import('@/views/project/dataManagement/external/ExternalDataDetail.vue'),
        meta: {
          title: ''
        }
      }
    ]
  }
]
